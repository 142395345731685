// @flow

import React, { PureComponent } from "react";

import { connectData } from "../../redux";

import {
  CarpoolHeader,
  PBText,
  PBModal,
  UploadComponent,
  View,
} from "../../components";
import { commonStyles } from "../../theme";
import { CommonService, AppData } from "../../services";
import { IonContent, IonPage } from "@ionic/react";
import ReactCrop from "react-image-crop";
import getIcons from "../../themes/functions/getIcons/getIcons";

class UploadPicScreen extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      avatarSource: null,
      crop: {
        unit: "%",
        width: 100,
        aspect: 1 / 1,
      },
    };
    let registerData = sessionStorage.getItem("registerData");
    if (registerData) {
      this.registerData = JSON.parse(registerData);
    }
  }

  onSubmit = () => {
    try {
      if (this.state.avatarSource || this.registerData.oAuthPic) {
        //   CommonService.goToScreen(
        //     this.props.componentId,
        //     PREFERENCES_SCREEN,
        //     "",
        //     {
        //       registerData: this.props.registerData,
        //       photo: this.state.photo,
        //       oAuthLogin: this.props.oAuthLogin,
        //     },
        //     true,
        //     false,
        //     true
        //   );
        // this.props.history.push('/preferences')
        if (this.state.photo) {
          this.registerWithUpload(
            this.registerData.registerData,
            this.state.blob
          );
        } else {
          this.register(this.registerData.registerData);
        }
      } else {
        this.selectPhotoTapped();
      }
    } catch (e) {
      console.log(e);
    }
  };

  selectPhotoTapped = (event) => {
    try {
      this.setState({
        photo: event.target.files[0],
      });
      if (event.target.files[0]) {
        var reader = new FileReader();
        var url = reader.readAsDataURL(event.target.files[0]);

        reader.onloadend = function (e) {
          this.setState({
            rawImage: [reader.result],
          });
        }.bind(this);
      }
    } catch (e) {
      console.log(e);
    }
  };

  uploadPic = (response) => {
    this.props.uploadPic({
      callback: (result) => {
        if (result.data) {
        }
      },
      data: CommonService.createUploadFormData(response, {}),
    });
  };

  renderSteps = (number, text) => {
    return (
      <View style={styles.stepContainer}>
        <View style={styles.number}>
          <PBText style={{ color: "#fff", fontSize: 12 }}>{number}</PBText>
        </View>
        <PBText dark={true}>{text}</PBText>
      </View>
    );
  };

  skip = () => {
    // CommonService.goToScreen(
    //   this.props.componentId,
    //   PREFERENCES_SCREEN,
    //   "",
    //   {
    //     registerData: this.props.registerData,
    //     oAuthLogin: this.props.oAuthLogin,
    //   },
    //   true,
    //   false,
    //   true
    // );
    try {
      let registerFormData = {
        registerData: this.registerData ? this.registerData.registerData : null,
        oAuthLogin: this.registerData ? this.registerData.oAuthLogin : false,
      };
      sessionStorage.setItem(
        "registerFormData",
        JSON.stringify(registerFormData)
      );
      if (this.state.photo) {
        this.registerWithUpload(
          this.registerData.registerData,
          this.state.photo
        );
      } else {
        this.register(this.registerData.registerData);
      }
    } catch (e) {
      console.log(e);
    }
    // this.props.history.push('/preferences')
  };

  register = (data) => {
    console.log("Data for Registration", data)
    this.props.register({
      callback: (res) => this.onResponse(res, data),
      data: data,
    });
  };

  onResponse = (result, data) => {
    console.log(result);
    try {
      if (
        result.success &&
        ((result.data && result.data.error) || result.error)
      ) {
        this.setState({
          showModal: true,
          modalTitle: "fracaso",
          modalBody: result.error,
          button1Text: "OK",
          // modalIcon: "/assets/icons/failure.png",
          modalIcon: getIcons('failure')
        });
        return;
      } else if (result.data && result.data.id) {
        console.log(result.data);
        if (result.data.auth_token) {
          CommonService.storeLoginDetails(result.data, this.props.loginSuccess);
        }
        this.props.history.push("/register/status/success");
      } else {
        this.setState({
          showModal: true,
          modalTitle: "fracaso",
          modalBody: "",
          button1Text: "OK",
          // modalIcon: "/assets/icons/failure.png",
          modalIcon: getIcons('failure')
        });
        return;
      }
    } catch (e) {
      console.log(e);
    }
  };

  registerWithUpload = (data, photo) => {
    console.log("Data for Registration with photo", data);
    const document = {
      uri: photo.uri,
      type: photo.type,
      name: photo.name,
    };
    let form;
    try {
      console.log(data);
      form = form = new window.FormData();
      form.append("document", photo, photo.name);
      form.append("name", data.name);
      form.append("last_name", data.last_name);
      form.append("email", data.email);
      form.append("country_code", data.country_code);
      form.append("phone", data.phone);
      form.append("password", data.password);
      form.append("identity_type", data.identity_type);
      form.append("identity_val", data.identity_val);
      form.append("referrer_code", data.referrer_code);
      // form.append("gender", data.gender);
      form.append("amenities", data.amenities);
      form.append("device_details", JSON.stringify(data.device_details));

      form.append("oauth_account", data.oauth_account);
      form.append("oauth_token", data.oauth_token);
      form.append("oauth_type", data.oauth_type);
      form.append("auth_info", JSON.stringify(data.auth_info));
      if (CommonService.shouldPassOperatorId(this.props.data.operator)) {
        form.append("operator_id",  CommonService.getOperatorId(this.props.data.operator));
      }
    } catch (e) {
      console.log(e);
    }
    console.log(form);
    this.props.showLoader();

    fetch(AppData.BASE_URL + "api/customer/register?", {
      body: form,
      method: "POST",
      headers: {
        "x-jwt-token": CommonService.jwt_key
      },
    })
      .then((response) => {
        return response.json();
      })
      .catch((error) => {
        this.props.hideLoader();
        console.log("ERROR ", error);
      })
      .then((responseData) => {
        this.props.hideLoader();
        console.log("Success", responseData);
        try {
          if (responseData && responseData.error) {
            this.setState({
              showModal: true,
              modalTitle: "fracaso",
              modalBody: responseData.error,
              button1Text: "OK",
              // modalIcon: "/assets/icons/failure.png",
              modalIcon: getIcons('failure')
            });
            return;
          } else if (responseData && responseData.id) {
            if (responseData.auth_token) {
              CommonService.storeLoginDetails(
                responseData,
                this.props.loginSuccess
              );
            }
            this.props.history.push("/register/status/success");
          } else {
            this.setState({
              showModal: true,
              modalTitle: "fracaso",
              modalBody: "",
              button1Text: "OK",
              // modalIcon: "/assets/icons/failure.png",
              modalIcon: getIcons('failure')
            });
          }
        } catch (e) {
          console.log(e);
        }
      });
    //   .done();
  };


  // If you setState the crop in here you should return false.
  onImageLoaded = (image) => {
    this.imageRef = image;
  };

  onCropComplete = (crop) => {
    this.makeClientCrop(crop);
  };

  onCropChange = (crop, percentCrop) => {
    // You could also use percentCrop:
    // this.setState({ crop: percentCrop });
    this.setState({ crop });
  };

  async makeClientCrop(crop) {
    if (this.imageRef && crop.width && crop.height) {
      const avatarSource = await this.getCroppedImg(
        this.imageRef,
        crop,
        new Date().getTime() + ".jpeg"
      );
      this.setState({ avatarSource });
    }
  }

  getCroppedImg(image, crop, fileName) {
    const canvas = document.createElement("canvas");
    const scaleX = image.naturalWidth / image.width;
    const scaleY = image.naturalHeight / image.height;
    canvas.width = crop.width;
    canvas.height = crop.height;
    const ctx = canvas.getContext("2d");

    ctx.drawImage(
      image,
      crop.x * scaleX,
      crop.y * scaleY,
      crop.width * scaleX,
      crop.height * scaleY,
      0,
      0,
      crop.width,
      crop.height
    );

    return new Promise((resolve, reject) => {
      canvas.toBlob((blob) => {
        if (!blob) {
          //reject(new Error('Canvas is empty'));
          console.error("Canvas is empty");
          return;
        }
        this.setState({ blob: blob });
        blob.name = fileName;
        window.URL.revokeObjectURL(this.fileUrl);
        this.fileUrl = window.URL.createObjectURL(blob);
        resolve(this.fileUrl);
      }, "image/jpeg");
    });
  }

  render() {
    const operator = JSON.parse(localStorage.getItem("op"));
    return (
      <IonPage>
        <IonContent>
          <View style={styles.flex}>
            <CarpoolHeader
              history={this.props.history}
              backIcon={true}
              componentId={this.props.componentId}
              label=" "
              labelDark={true}
            />
            <View style={{ flex: 1 }}>
              <UploadComponent
                icon={
                  this.registerData.oAuthPic
                    ? this.registerData.oAuthPic
                    : this.state.avatarSource
                    ? this.state.avatarSource:
                    // : "/assets/carpool/upload-pic-circle.png"
                   getIcons('userIcon')
                }
                title="Sube tu foto"
                body="Para utilizar algunos de nuestros servicios debes subir tu foto actualizada."
                step1="Sube una foto nítida y bien iluminada."
                step2="Mira a la cámara y no utilices lentes."
                onPhotoClick={this.selectPhotoTapped}
                buttonLabel={
                  this.state.avatarSource || this.registerData.oAuthPic
                    ? "CONTINUAR"
                    : "ABRIR CÁMARA"
                }
                hideButtonFileUploader={
                  this.state.avatarSource || this.registerData.oAuthPic
                }
                onSubmit={this.onSubmit}
              />
              <View
                style={{
                  alignItems: "center",
                  display: "flex",
                  flexDirection:"column",
                  justifyContent: "center",
                  alignItems:"center"
                }}
              >
                <PBText>Quiero subir foto luego.</PBText>
                <a onClick={this.skip}>
                  <PBText style={{color:operator.color_codes.primary_color}}>Omitir este paso.</PBText>
                </a>
              </View>
            </View>
            <PBModal
              showModal={this.state.showModal}
              titleText={this.state.modalTitle}
              bodyText={this.state.modalBody}
              type="alert"
              showButton1={true}
              button1Text={
                "OK, CONTINUAR"
              }
              button1Press={
                () => this.setState({showModal:false,modalTitle:null,modalBody:null})
              }
              icon={this.state.modalIcon}
            >
              {/* {this.state.showRegisterLink && <View style={styles.registerLinkContainer}>
                    <TouchableOpacity onPress={this.register}>
                        <PBText primary={true}>Regístrate aquí</PBText>
                    </TouchableOpacity>
                </View>} */}
            </PBModal>
            {/* <Toast ref="toast" /> */}
          </View>
          {this.state.rawImage && (
            <div className="pic-cropper-div">
              <ReactCrop
                src={this.state.rawImage}
                crop={this.state.crop}
                ruleOfThirds
                onImageLoaded={this.onImageLoaded}
                onComplete={this.onCropComplete}
                onChange={this.onCropChange}
              />
              <div className="pic-cropper-buttons">
                <div onClick={() => this.setState({rawImage:null,photo:null,avatarSource:null,blob:null})}>Cancel</div>
                <div onClick={() => {this.setState({rawImage:null})}}>OK</div>
              </div>
            </div>
          )}
        </IonContent>
      </IonPage>
    );
  }
}

const styles = {
  flex: {
    flex: 1,
  },
  fontSize: {
    fontSize: 12,
  },
  buttonContainer: {
    marginTop: 10,
  },
  stepContainer: {
    flexDirection: "row",
    marginBottom: 10,
    alignItems: "center",
  },
  number: {
    width: 20,
    height: 20,
    marginRight: 15,
    backgroundColor: "#FF5A5E",
    alignItems: "center",
    justifyContent: "center",
    borderRadius: 10,
  },
};

export default connectData()(UploadPicScreen);
