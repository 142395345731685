import { IonContent, IonPage } from "@ionic/react";

import { CommonService } from "../../../services";
import Header from "../../common/header/Header";
import HeroSection from "../../common/HeroSection/HeroSection";
import IvergramaContactForm from "../common/IvergramaContactForm";
import IvergramaFooter from "../common/IvergramaFooter";
import IvergramaTitle from "../common/IvergramaTitle";
import React from "react";
import { connectData } from "../../../redux";
import getIcons from "../../functions/getIcons/getIcons";
import Header6 from "../../common/header/Header6";

const IvergramaContactUsScreen = props => {
	const operator = CommonService.getLocal("op") || props.data.operator;
	return (
		<IonPage>
			<IonContent>
				{operator && operator.operator_name === 'basetorres' ? <Header6/> : <Header />}

				<HeroSection
					heroImage={getIcons("contactUsBanner", operator)}
					title="Contacto"
					description="Home / Contacto"
					height={"45vh"}
					operator={operator}
				/>

				<IvergramaTitle
					operator={operator}
					title="Contacto"
					className={
						operator && operator.operator_name === "busnorte"
							? "new-bold-font"
							: ""
					}
				/>

				<IvergramaContactForm contact />

				<IvergramaFooter />
			</IonContent>
		</IonPage>
	);
};

export default connectData()(IvergramaContactUsScreen);
