import React, { PureComponent } from "react";

import { connectData } from "../../redux";
import { dimensions, commonStyles, colors } from "../../theme";
import { CommonService } from "../../services";
import {
  BGCarpoolInnerBottom,
  CarpoolHeader,
  PBCard,
  PBText,
  PBModal,
  PBTouchable,
  PButton,
  PBCarpoolBG,
  PBInput,
  PBTabs,
  CarpoolSuccessFailureModal,
  View,
} from "../../components";
import RecipientInfo from "../../components/RecipientInfo";
import { IonPage, IonContent, IonToast } from "@ionic/react";
import getIcons from "../../themes/functions/getIcons/getIcons";
// import {
//   LOGIN_OPTIONS_SCREEN,
//   NEW_SCAN_QR_SCREEN,
//   TRANSFER_RESULT_SCREEN,
//   CARPOOL_CREATE_GROUP_SCREEN,
// } from "../../../navigation";
// import Toast from "react-native-easy-toast";

class NewTransferRechargeScreen extends PureComponent {
  idCardTypes = [];
  constructor(props) {
    super(props);
    for (let c in this.props.data.metaData.id_card_types) {
      this.idCardTypes.push({
        value: this.props.data.metaData.id_card_types[c],
        id: this.props.data.metaData.id_card_types[c],
        label: c,
        name: c,
        selected:
          this.props.data.metaData.id_card_types[c] ==
          this.props.data.loginData.identity_type,
      });
    }
    this.state = {
      rut: this.props.recipient
        ? this.props.recipient.recipient_identity_val
        : null,
      rutError: null,
      amount: null,
      amountError: null,
      recipient: this.props.recipient ? this.props.recipient : null,
      favourites: null,
      favAdd: [],
      favRemove: [],
      selectedTab: 0,
      walletChecked: true,
    };
    const operator =
      JSON.parse(localStorage.getItem("op")) || this.props.data.operator;
    let query;
    if (CommonService.shouldPassOperatorId(operator)) {
      query = "operator_id=" + CommonService.getOperatorId(operator)
    }
    this.props.getCustomerProfile({
      callback: (result) => this.onProfileLoad(result),
      operator_id: query
    });
    // if(this.props.recipient && this.props.recipient.recipient_identity_val){
    //   this.props.getRecipientInfo({callback:(result) => this.onResponse(result),rut:this.props.recipient.recipient_identity_val})
    // }
  }

  componentDidMount() {
    // BackHandler.addEventListener("hardwareBackPress", this.handleBackPress);
  }

  componentWillUnmount() {
    // BackHandler.removeEventListener("hardwareBackPress", this.handleBackPress);
  }

  // handleBackPress = () => {
  //   if (this.state.recipient) {
  //     this.setState({ recipient: null })
  //   } else {
  //     CommonService.goBack(this.props.componentId)
  //   }
  //   return true;
  // };

  onProfileLoad = (result) => {
    if (result.statusCode == 200) {
      CommonService.storeLoginDetails(result.data, this.props.loginSuccess);
      this.setState({
        favourites: this.props.data.loginData.favourites,
      });
    } else if (result.statusCode == 401 && result.data.error) {
      this.setState({
        showAuthFailedModal: true,
        modalTitle: result.data.error,
        modalIcon: null,
        modalButtonText: "Iniciar Sesión",
        authFailed: true,
        modalButtonTap: this.goToLogin,
        // modalBackButtonPress: this.goToHome,
      });
    } else if (result.data && result.data.error) {
      this.setState({
        showAuthFailedModal: true,
        modalTitle: "¡Lo Sentimos!",
        modalBody: result.data.error,
        modalIcon: "/assets/icons/cross-grey.png",
        button1Text: "OK",
        modalButtonTap: this.closeModal,
        modalBackButtonPress: this.closeModal,
      });
    }
  };

  // goToHome = () => {
  //   this.closeModal();
  //   CommonService.goToHome(this.props.componentId);
  // };

  closeModal = () => {
    this.setState({
      showModal: false,
      modalTitle: "",
      modalBody: "",
      authFailed: false,
      button1Text: "",
      modalButtonTap: null,
      modalBackButtonPress: null,
      optionalMessage: null,
    });
  };

  goToLogin = () => {
    let authFailed = this.state.authFailed;
    this.closeModal();
    if (authFailed) {
      CommonService.storeLoginDetails(null, this.props.loginSuccess);
      // CommonService.goToHome(this.props.componentId);
      setTimeout(() => {
        this.props.history.push("/login-options");
      }, 150);
    }
  };

  onBlur = (text) => {
    if (!text) {
      this.setState({
        rutError: {
          message: "Ingresa tu RUT",
        },
      });
    } else {
      let rut = CommonService.formatRut(text);
      this.setState({
        rut: rut,
      });
      if (!CommonService.isRutValid(rut)) {
        this.setState({
          rutError: {
            message: "Ingresa un RUT válido",
          },
        });
      }
    }
  };

  onChange = (text) => {
    this.setState({
      rutError: null,
    });
    if (text) {
      let rut = text;
      this.setState({
        rut: rut,
      });
    } else {
      this.setState({
        rut: text,
      });
    }
  };

  isValid = () => {
    let errorCount = 0;

    if (!this.state.rut) {
      this.setState({
        rutError: {
          message: "Ingresa un RUT válido",
        },
      });
      errorCount++;
    } else if (!CommonService.isRutValid(this.state.rut)) {
      this.setState({
        rutError: {
          message: "Ingresa un RUT válido",
        },
      });
      errorCount++;
    }
    if (errorCount <= 0) {
      return true;
    }
    return false;
  };

  // onContactSelect = (contact) => {
  //   console.log("onContactSelect", contact);

  //   this.onSubmit(contact);
  // if (
  //   contact.phoneNumbers &&
  //   contact.phoneNumbers[0] &&
  //   contact.phoneNumbers[0].number
  // ) {
  //   let number =
  //     CommonService.onlyNumbers(contact.phoneNumbers[0].number).length > 10
  //       ? CommonService.onlyNumbers(contact.phoneNumbers[0].number).substr(2)
  //       : CommonService.onlyNumbers(contact.phoneNumbers[0].number);
  //   let code =
  //     CommonService.onlyNumbers(contact.phoneNumbers[0].number).length > 10
  //       ? CommonService.onlyNumbers(contact.phoneNumbers[0].number).substr(
  //           0,
  //           2
  //         )
  //       : CommonService.onlyNumbers(contact.phoneNumbers[0].number);
  //   this.onSubmit(number, code);
  // }
  // };

  onNumberChange = (number) => {
    let rut = CommonService.formatRut(number);
    console.log("Rut created is", rut);
    this.setState({ phoneNumber: rut });
  };

  searchUser = () => {
    let rut = CommonService.formatRut(this.state.phoneNumber);
    console.log("Rut created is", rut);
    this.onSubmit(this.state.phoneNumber);
    // this.onSubmit(this.state.phoneNumber);
    // this.onContactSelect(this.state.phoneNumber);
  };

  onSubmit = (number, code) => {
    // Keyboard.dismiss();
    const operator = JSON.parse(localStorage.getItem('op'))
    if (!this.isValid() && !number && !code) {
      return;
    }

    // console.log("Profile data", this.props.data.loginData.profileData);

    let rut = CommonService.formatRut(this.state.rut);
    this.setState({
      rut: rut,
      rutError: null,
      number: number,
      countryCode: code,
    });

    this.props.getRecipientInfo({
      callback: (result) => this.onResponse(result),
      rut: number ? number : rut,
      country_code: code,
      operator_id: operator && operator.operator_id || null,
    });
  };

  onResponse = (result) => {
    console.log("onResponse method", result);
    if (result.success && result.data && !result.data.recipient_id) {
      if (result.data.status == 404) {
        this.setState({
          rutError: {
            message: result.data.message,
          },
        });
      } else {
        this.setState({
          rutError: {
            message: result.data.message,
          },
        });
      }
      // this.setState({
      //     showModal:true,
      //     modalTitle:'¡Lo Sentimos!',
      //     button1Text:'VOLVER',
      //     modalIcon:require('../../../assets/icons/failure.png'),
      //     showSuccessBody:true
      // })
      return;
    } else if (result.data && result.data.recipient_id) {
      this.setState({
        recipient: result.data,
      });
    } else if (result.data && result.data.message) {
      this.setState({
        rutError: {
          message: result.data.message,
        },
      });
    }
  };

  //favourites ---------

  isFavourite = () => {
    if (this.state.favourites) {
      return this.state.favourites.find((it) => {
        return this.state.recipient.recipient_id == it.id;
      });
    }
    return null;
  };

  addRemoveFavourite = (cust) => {
    console.log("Fav item is", cust);
    let isFav = this.state.favourites.find((it) => {
      return it.identity_val == cust.recipient_identity_val;
    });
    let isFavList = this.state.favRemove.find((it) => {
      return it == cust.recipient_id;
    });
    if (isFavList) {
      let favRemove = this.state.favRemove.filter((it) => {
        return it != cust.recipient_id;
      });
      this.setState({
        favRemove: favRemove,
      });
    }
    let isFavListAdd = this.state.favAdd.find((it) => {
      return it == cust.recipient_id;
    });
    if (isFavListAdd) {
      let favAdd = this.state.favAdd.filter((it) => {
        return it != cust.recipient_id;
      });
      this.setState({
        favAdd: favAdd,
      });
    }
    if (!isFav) {
      if (this.state.favourites.length > 5) {
        this.refs.toast.show("Máximo 5 favoritos permitidos por cuenta.");
        return;
      }
      let favAdd = CommonService.copyObject(this.state.favAdd);
      favAdd.push(cust.recipient_id);
      let favourites = CommonService.copyObject(this.state.favourites);
      favourites.push({
        id: cust.recipient_id,
        name: cust.recipient_name,
        last_name: cust.last_name,
        email: cust.recipient_email,
        identity_val: cust.recipient_identity_val,
      });
      this.setState({
        favourites: favourites,
        favAdd: favAdd,
      });
    } else {
      let favRemove = CommonService.copyObject(this.state.favRemove);
      favRemove.push(cust.recipient_id);
      let favourites = this.state.favourites.filter((it) => {
        return it.identity_val != cust.recipient_identity_val;
      });
      this.setState({
        favourites: favourites,
        favRemove: favRemove,
      });
    }
  };

  addRemoveFavouriteList = (cust) => {
    console.log("Fav list is ", cust);
    if (this.isFavouriteList(cust)) {
      // this.state.favAdd.push(cust.id);
      let favRemove = this.state.favRemove.filter((it) => {
        return it != cust.id;
      });

      let favourites = this.state.favourites.filter((it) => {
        return it.identity_val != cust.identity_val;
      });
      this.setState({
        favRemove: favRemove,
        favourites: favourites,
      });
    } else {
      let favRemove = CommonService.copyObject(this.state.favRemove);
      favRemove.push(cust.id);

      let favourites = this.state.favourites.filter((it) => {
        return it.identity_val != cust.identity_val;
      });

      this.setState({
        favRemove: favRemove,
        favourites: favourites,
      });
    }
  };

  isFavouriteList = (fav) => {
    if (this.state.favourites) {
      return this.state.favRemove.find((it) => {
        return fav.id == it;
      });
    }
    return null;
  };

  selectfav = (fav) => {
    console.log("Select fav method selected");
    let customerInfo = {};
    customerInfo.recipient_id = fav.id;
    customerInfo.recipient_name = fav.name;
    customerInfo.last_name = fav.last_name;
    customerInfo.recipient_email = fav.email;
    customerInfo.phone = fav.phone;
    customerInfo.last_name = fav.last_name;
    customerInfo.image_link = fav.image_link;
    customerInfo.recipient_identity_val = fav.identity_val;
    customerInfo.identity_type_label = fav.identity_type;
    this.setState({
      recipient: customerInfo,
      rut: fav.phone,
      rutError: null,
    });

    console.log("Recipient Selected", customerInfo, this.state.selectedTab);
  };

  componentWillUnmount() {
    let body = {
      add: this.state.favAdd,
      remove: this.state.favRemove,
    };
    console.log(body);
    this.props.updateFavourites({ data: body });
  }

  onChangeAmount = (text) => {
    if (
      +text >
      +this.props.data.loginData.wallet_moneys.amount
        // .split(".")
        // .join("")
        // .split(" ")[1]
    ) {
      this.setState({
        amount: text,
        amountError:
          this.state.selectedTab !== 1
            ? {
                message: "El saldo disponible es insuficiente",
              }
            : null,
      });
    } else {
      this.setState({
        // amount:text,
        amountError: null,
      });

      if (!CommonService.onlyNumericValues(text)) {
        this.setState({
          amountError: {
            message: "El monto solo debe contener dígitos.",
          },
        });
      }
      this.setState({ amount: text });
      // }

      // let amountToSend = CommonService.copyObject(this.state.amount);
      // if (amountToSend) {
      //     text = CommonService.onlyNumbers(text);
      //     amountToSend = text;
      //     this.setState({ amount: text });
      // }
    }
  };

  onSubmitAmount = () => {
    if (!this.state.amount) {
      this.setState({
        amountError: {
          message: "Ingresa el monto a transferir",
        },
      });
      return;
    } else if (
      +this.state.amount >
        +this.props.data.loginData.wallet_moneys.amount
          // .split(".")
          // .join("")
          // .split(" ")[1] 
          &&
      this.state.selectedTab !== 1
    ) {
      this.setState({
        amountError: {
          message: "El saldo disponible es insuficiente",
        },
      });
      return;
    } else if (this.state.amount == 0) {
      this.setState({
        amountError: {
          message: "Ingresa el monto a transferir",
        },
      });
      return;
    } else if (this.state.amountError) {
      this.setState({
        amountError: {
          message: "El monto solo debe contener dígitos.",
        },
      });
      return;
    } else if (!this.state.walletChecked) {
      // this.refs.toast.show("Selecciona tu método de pago");
      this.setState({
        toastMsg: "Selecciona tu método de pago",
        showToast: true,
      });
      return;
    }
    // Keyboard.dismiss();
    this.setState({
      showModal: true,
      modalIcon: null,
      modalTitle:
        this.state.selectedTab !== 1
          ? "¿Estás seguro de enviar \nsaldo a la cuenta seleccionada?"
          : "¿Estás seguro de pedir \nsaldo a la cuenta seleccionada?",
      button1Text: "NO",
      showButton2: true,
      button2Text: "SI",
      button2Press:
        this.state.selectedTab !== 1 ? this.transferAmount : this.requestAmount,
      showConfirmAmountBody: true,
    });
  };

  transferAmount = () => {
    const operator = JSON.parse(localStorage.getItem('op'))
    let body = {
      recipient_id: this.state.recipient.recipient_id,
      recipient_identity_val: this.state.number
        ? this.state.number
        : this.state.recipient.recipient_identity_val,
      recipient_email: this.state.recipient.recipient_email,
      transfer_amount: this.state.amount,
      comments: this.state.optionalMessage,
      is_kupos: true,
      operator_id: operator && operator.operator_id || null,
    };
    this.props.transferMoney({
      data: body,
      callback: (result) =>
        this.onTranseferMoneyResponse(result, this.state.amount),
    });
  };

  onTranseferMoneyResponse = (result, amount, msg) => {
    console.log("Transfer Money response", result);
    if (!result.data.status || result.data.status == 200) {
      this.setState({
        modalTitle: null,
        showButton2: false,
        button2Text: null,
        button2Press: null,
        showConfirmAmountBody: false,
        showModal: false,
        amount: null,
        recipient: null,
        rut: null,

        showResultModal: true,
        showResultModalStatus: true,
        showResultModalBodyText:
          msg || "El saldo ha sido enviado al usuario seleccionado",
      });
      const operator =
        JSON.parse(localStorage.getItem("op")) || this.props.data.operator;
      let query;
      if (CommonService.shouldPassOperatorId(operator)) {
        query = "operator_id=" + CommonService.getOperatorId(operator)
      }
      this.props.getCustomerProfile({
        callback: (result) => this.onProfileLoad(result),
        operator_id: query,
      });
      // this.gotoResultScreen(1);
    } else {
      this.setState({
        modalTitle: null,
        showButton2: false,
        button2Text: null,
        button2Press: null,
        showConfirmAmountBody: false,
        showModal: false,
        amount: null,
        recipient: null,
        rut: null,
        selectedTab: 0,

        showResultModal: true,
        showResultModalStatus: false,
        showResultModalBodyText: null,
      });
      // this.gotoResultScreen(0);
    }
  };

  requestAmount = () => {
    let body = {
      sender_id: this.state.recipient.recipient_id,
      sender_identity_val: this.state.recipient.recipient_identity_val,
      sender_email: this.state.recipient.recipient_email,
      request_amount: this.state.amount,
      comments: this.state.optionalMessage,
    };

    console.log("Request Body", body);

    this.props.requestMoney({
      data: body,
      callback: (result) =>
        this.onTranseferMoneyResponse(
          result,
          this.state.amount,
          "Tu solicitud de saldo ha sido enviada correctamente al usuario seleccionado. "
        ),
    });
  };

  gotoResultScreen = (status) => {
    this.props.history.push(`/recharge-transfer/status/:${status}`);
  };

  // openContacts = () => {
  //   CommonService.goToScreenHideTopBar(this.props.componentId, CARPOOL_CREATE_GROUP_SCREEN, { label: 'Selecciona', labelBold: 'tu destinatario', fromRechargeScreen: true, onContactSelect: this.onContactSelect })
  // };

  renderRecipientInfo = (hideButtons = false) => {
    console.log("Render recipient info");
    return (
      <RecipientInfo
        recipient={this.state.recipient}
        hideButtons={hideButtons}
        addRemoveFavourite={this.addRemoveFavourite}
        removeRecipient={() => {
          this.setState({ recipient: null, rut: null, amount: null });
        }}
        isFavourite={this.isFavourite()}
      />
    );
  };

  renderFavouriteList = () => {
    return (
      <View style={styles.favouritesContainer}>
        <View className="new-transfer-screen-h-line">
          <PBText bold={true}> Mis Favoritos </PBText>
        </View>
        <View style={styles.favouriteListContainer}>
          {this.state.favourites.map((item, key) => {
            return (
              <View className="new-transfer-screen-fav-item" key={key}>
                <PBTouchable
                  dontDisableOnClick={true}
                  onPress={() => this.selectfav(item)}
                >
                  <View
                    style={{
                      flex: 1,
                      flexDirection: "row",
                      alignItems: "flex-end",
                    }}
                  >
                    <View style={{ flex: 1 }}>
                      <PBText>
                        {item.name} {item.last_name}
                      </PBText>
                    </View>
                    {/* <PBText> {item.identity_val} </PBText> */}
                  </View>
                </PBTouchable>
                <PBTouchable
                  dontDisableOnClick={true}
                  onPress={() => this.addRemoveFavouriteList(item)}
                >
                  <img
                    style={styles.recipientIcon}
                    height={16}
                    src={
                      !this.isFavouriteList(item)?
                        // ? "/assets/icons/heart.png"
                        // : "/assets/icons/heart-empty.png"
                        getIcons('heartOn'):
                        getIcons('heartOff')
                    }
                    alt=''
                  />
                </PBTouchable>
              </View>
            );
          })}
        </View>
      </View>
    );
  };

  renderAccountCardInfo = (profileData) => {
    const operator = JSON.parse(localStorage.getItem("op"));
    return (
      <PBCard
        padding={20}
        position={"relative"}
        style={{
          marginTop: 20,
          marginHorizontal: 20,
          width: 100,
        }}
      >
        <View style={styles.dpContainer}>
          <View>
            <PBText bold={true}>
              {profileData && CommonService.capitalize(profileData.name)}{" "}
              {profileData && profileData.last_name
                ? CommonService.capitalize(profileData.last_name)
                : ""}
            </PBText>
            <PBText>
              {this.idCardTypes && this.idCardTypes.find((it) => it.selected)
                ? this.idCardTypes.find((it) => it.selected).name
                : ""}
              : {profileData && profileData.identity_val}
            </PBText>
            <PBText>Email : {profileData && profileData.email} </PBText>
          </View>
        </View>
        <View className="new-transfer-screen-h-line" />
        {operator && operator.operator_name == "cormarbus" && (
          <View
            style={{
              alignItems: "center",
              justifyContent: "space-between",
              flexDirection: "row",
              display: "flex",
            }}
          >
            <View style={styles.flex}>
              <PBText bold={true} dark={true} style={{ fontSize: 13 }}>
                Saldo disponible
              </PBText>
            </View>
            <View style={{ flexDirection: "row", alignItems: "flex-end" }}>
              <PBText bold={true} style={{ fontSize: 13 }}>
                {this.props.data.loginData &&
                  this.props.data.loginData.wallet_moneys &&
                  this.props.data.loginData.wallet_moneys.currency &&
                  this.props.data.loginData.wallet_moneys.currency.toUpperCase()}{" "}
                {CommonService.currency(
                  this.props.data.loginData &&
                    this.props.data.loginData.wallet_moneys &&
                    this.props.data.loginData.wallet_moneys.amount
                )}
              </PBText>
            </View>
          </View>
        )}
      </PBCard>
    );
  };

  renderSendMoneyView = () => {
    console.log("Rec data", this.state.recipient);
    const operator = JSON.parse(localStorage.getItem("op"));
    return (
      <View>
        {this.state.recipient ? (
          <View style={(styles.titleContainer, { marginTop: 25 })}>
            <PBText center={true} bold={true} style={{ fontSize: 15 }}>
              ¿A quién compartes saldo?
            </PBText>
            <PBText center={true}>
              La solicitud será enviada de manera inmediata
            </PBText>
          </View>
        ) : (
          <View style={styles.titleContainer}>
            <PBText center={true}>Envía saldo a cualquier destinatario</PBText>
          </View>
        )}

        <View
          className="kupos-card"
          style={{
            paddingTop: 30,
            paddingBottom: 30,
            marginTop: 20,
            marginBottom: 20,
            position: "relative",
          }}
        >
          {/* {!this.state.recipient ? (
            <View>
              <PBInput
                label="RUT Destinatario"
                placeholder="Ingresa el RUT de tu destinatario"
                value={this.state.rut}
                onTextChange={(text) => this.onChange(text)}
                onBlur={(text) => this.onBlur(text)}
                error={this.state.rutError ? true : false}
                errorMessage={
                  this.state.rutError ? this.state.rutError.message : ""
                }
                iconRight={require("../../../assets/carpool/search.png")}
                onRightIconPress={this.openContacts}
                disable={this.state.recipient ? true : false}
              />
              {this.state.favourites && this.state.favourites.length
                ? this.renderFavouriteList()
                : null}
            </View>
          ) : null} */}
          {!this.state.recipient ? (
            <View>
              <View style={styles.openContacts}>
                <View style={styles.openContactsLabel}>
                  <PBText bold={true}>Selecciona tu destinatario</PBText>
                </View>

                <PBInput
                  placeholder="Ingresa RUT del destinatario"
                  type=""
                  value={this.state.phoneNumber}
                  onTextChange={(text) => this.onNumberChange(text)}
                  iconRight={"/assets/carpool/search.png"}
                  onRightIconClick={() => this.searchUser()}
                  error={this.state.rutError ? true : false}
                  errorMessage={
                    this.state.rutError ? this.state.rutError.message : ""
                  }
                />
                {/* <View className="open-contacts-inout">
                  <PBTouchable
                  // onPress={this.openContacts}
                  >
                    <View className="open-contacts-inout-inner">
                      <View style={styles.flex}>
                        <PBText style={{ color: "#999" }}>
                          Busca entre tus destinatario
                        </PBText>
                      </View>
                      <img
                        style={{ tintColor: "#777" }}
                        height={24}
                        src={"/assets/carpool/search.png"}
                      />
                    </View>
                  </PBTouchable>
                </View> */}
              </View>
              {this.state.favourites && this.state.favourites.length
                ? this.renderFavouriteList()
                : null}
            </View>
          ) : null}

          {this.state.recipient ? (
            <View>
              {console.log("Hi there rec data", this.state.recipient)}
              {this.renderRecipientInfo()}
              <div style={{ marginTop: 10 }}>
                {/* <PBText>jgshvjcsjcvhv</PBText> */}
                <PBInput
                  label="Monto a enviar"
                  placeholder="CLP $15.000"
                  type="number"
                  value={this.state.amount}
                  onTextChange={(text) => this.onChangeAmount(text)}
                  // onBlur={(text) => {}}
                  error={this.state.amountError ? true : false}
                  errorMessage={
                    this.state.amountError ? this.state.amountError.message : ""
                  }
                />
              </div>
              <PBTouchable
                dontDisableOnClick={true}
                style={styles.paymentMethhodBorderLess}
                onPress={() =>
                  this.setState({ walletChecked: !this.state.walletChecked })
                }
              >
                <View
                  style={
                    (styles.paymentMethhodInner,
                    {
                      display: "flex",
                      justifyContent: "space-between",
                      marginTop: 10,
                      paddingVertical: 10,
                      alignItems: "center",
                    })
                  }
                >
                  <View style={styles.kuposPayCheck}>
                    <View className="transfer-money-payment-radio-button">
                      {this.state.walletChecked && (
                        <View style={styles.paymentRadioButtonInner} />
                      )}
                    </View>
                    <img
                      height="20"
                      width="120"
                      // src={"/assets/images/cormarpay-logo.jpg"}
                      // src={getIcons('operatorPay' )}
                      src={operator && operator.wallet_logo}
                      alt=""
                    />
                  </View>
                  <View>
                    <PBText bold={true} style={{ textAlign: "right" }}>
                      {this.props.data.loginData
                        ? CommonService.currency(
                            // this.props.data.loginData &&
                            //   this.props.data.loginData.wallet_moneys
                            //   ? this.props.data.loginData.wallet_moneys.amount
                            this.props.data.loginData &&
                              this.props.data.loginData.wallet_moneys &&
                              this.props.data.loginData.wallet_moneys.amount
                          )
                        : "$0"}
                    </PBText>
                  </View>
                </View>
              </PBTouchable>
            </View>
          ) : null}

          {this.state.recipient ? (
            <View style={styles.buttonContainer}>
              <PButton
                onPress={
                  !this.state.recipient ? this.onSubmit : this.onSubmitAmount
                }
                style={{ borderRadius: 10 }}
                noGradient={true}
                title={!this.state.recipient ? "BUSCAR" : "ENVIAR SALDO"}
              />
            </View>
          ) : null}
        </View>
      </View>
    );
  };

  renderRequestMoneyView = () => {
    return (
      <View>
        {this.state.recipient ? (
          <View style={(styles.titleContainer, { marginTop: 25 })}>
            <PBText center={true} bold={true} style={{ fontSize: 15 }}>
              Pide saldo a tu cantacto
            </PBText>
            <PBText center={true}>
              La solicitud será enviada de manera inmediata
            </PBText>
          </View>
        ) : (
          <View style={styles.titleContainer}>
            <PBText center={true}>Pide saldo a cualquier destinatario.</PBText>
          </View>
        )}

        <View
          className="kupos-card"
          style={{
            paddingTop: 30,
            paddingBottom: 30,
            marginTop: 20,
            marginBottom: 20,
            position: "relative",
          }}
        >
          {/* {!this.state.recipient ? (
            <View>
              <PBInput
                label="RUT Destinatario"
                placeholder="Ingresa el RUT de tu destinatario"
                value={this.state.rut}
                onTextChange={(text) => this.onChange(text)}
                onBlur={(text) => this.onBlur(text)}
                error={this.state.rutError ? true : false}
                errorMessage={
                  this.state.rutError ? this.state.rutError.message : ""
                }
                iconRight={require("../../../assets/carpool/search.png")}
                onRightIconPress={this.openContacts}
                disable={this.state.recipient ? true : false}
              />
              {this.state.favourites && this.state.favourites.length
                ? this.renderFavouriteList()
                : null}
            </View>
          ) : null} */}
          {!this.state.recipient ? (
            <View>
              <View style={styles.openContacts}>
                <View style={styles.openContactsLabel}>
                  <PBText bold={true}>Selecciona tu destinatario</PBText>
                </View>
                <View className="open-contacts-inout">
                  <PBInput
                    placeholder="Ingresa RUT del destinatario"
                    type="text"
                    value={this.state.phoneNumber}
                    onTextChange={(text) => this.onNumberChange(text)}
                    iconRight={"/assets/carpool/search.png"}
                    onRightIconClick={() => this.searchUser()}
                    error={this.state.rutError ? true : false}
                    errorMessage={
                      this.state.rutError ? this.state.rutError.message : ""
                    }
                  />
                </View>
              </View>
              {this.state.favourites && this.state.favourites.length
                ? this.renderFavouriteList()
                : null}
            </View>
          ) : null}

          {this.state.recipient ? (
            <View>
              {this.renderRecipientInfo()}
              <View style={{ marginTop: 10 }}>
                <PBInput
                  label="Monto a solicitar"
                  placeholder="CLP $15.000"
                  type="number"
                  value={this.state.amount ? this.state.amount : null}
                  onTextChange={(text) => this.onChangeAmount(text)}
                  // onBlur={(text) => {}}
                  error={this.state.amountError ? true : false}
                  errorMessage={
                    this.state.amountError ? this.state.amountError.message : ""
                  }
                />
              </View>
            </View>
          ) : null}

          {this.state.recipient ? (
            <View style={styles.buttonContainer}>
              <PButton
                onPress={
                  !this.state.recipient ? this.onSubmit : this.onSubmitAmount
                }
                style={{ borderRadius: 10 }}
                noGradient={true}
                title={!this.state.recipient ? "BUSCAR" : "PEDIR SALDO"}
              />
            </View>
          ) : null}
        </View>
      </View>
    );
  };

  onTabChange = (i) => {
    this.setState({ selectedTab: i, amount: null, recipient: null, rut: null });
  };

  render() {
    const loginData = JSON.parse(localStorage.getItem("loginData"));
    return (
      <IonPage>
        <IonContent>
          <View style={styles.flex}>
            {/* <PBCarpoolBG short={true} /> */}
            <CarpoolHeader
              backIcon={true}
              // whiteBackIcon={true}
              componentId={this.props.componentId}
              label="Comparte"
              labelBold="saldo"
              labelDark={true}
              // rightIcon={"/assets/carpool/scan.png"}
              history={this.props.history}
              // onRightIconPress={this.gotoScan}
            />
            {this.renderAccountCardInfo(this.props.data.loginData)}
            <View
              style={{ flex: 1, marginTop: 10 }}
              // keyboardShouldPersistTaps="handled"
            >
              {!this.state.recipient ? (
                <View style={styles.tabsContainer}>
                  <PBTabs
                    height={30}
                    // tabsArray={["Enviar saldo", "Pedir saldo"]}
                    tabsArray={["Enviar saldo"]}
                    onTabSelect={this.onTabChange}
                    activeTabColor={colors.secondaryBG}
                    selectedTab={this.state.selectedTab}
                    noCapitiliaze={true}
                  />
                </View>
              ) : null}
              {
                // this.state.selectedTab !== 1
                //   ?
                this.renderSendMoneyView()
                // : this.renderRequestMoneyView()
              }
            </View>
            <PBModal
              showModal={this.state.showModal}
              titleText={this.state.modalTitle}
              bodyText={this.state.modalBody}
              type="alert"
              showButton1={false}
              button1Text={this.state.button1Text}
              button1Press={
                this.state.modalButtonTap
                  ? this.state.modalButtonTap
                  : this.closeModal
              }
              showButton2={false}
              button2Text={this.state.button2Text}
              button2Press={this.state.button2Press}
              onBackButtonPress={
                this.state.modalBackButtonPress
                  ? this.state.modalBackButtonPress
                  : this.closeModal
              }
              icon={this.state.modalIcon}
              onModalIconPress={
                this.state.modalButtonTap
                  ? this.state.modalButtonTap
                  : this.closeModal
              }
              // cardStyle={{ marginTop: -dimensions.vh * 35 }}
            >
              {this.state.showModal ? (
                <View style={{ marginTop: 15 }}>
                  {this.renderRecipientInfo(true)}
                  <View
                    className="new-transfer-screen-h-line"
                    style={{
                      marginVertical: 0,
                      marginTop: 15,
                      marginBottom: 15,
                    }}
                  />
                  <View
                    style={{
                      display: "flex",
                      width: "100%",
                      marginBottom: 20,
                    }}
                  >
                    <View style={{ flex: 1 }}>
                      <PBText bold={true}>
                        {" "}
                        Monto a{" "}
                        {this.state.selectedTab !== 1
                          ? "transferir"
                          : "solicitar"}
                        :{" "}
                      </PBText>
                    </View>
                    <View>
                      <PBText bold={true}>
                        {" "}
                        CLP {CommonService.currency(this.state.amount)}{" "}
                      </PBText>
                    </View>
                  </View>
                  <View style={{ marginBottom: 20 }}>
                    <PBInput
                      label="¿Quieres agregar un mensaje?"
                      placeholder="Opcional"
                      type="text"
                      value={this.state.optionalMessage}
                      onTextChange={(text) =>
                        this.setState({ optionalMessage: text })
                      }
                      // onBlur={(text) =>
                      //   this.setState({ optionalMessage: text })
                      // }
                    />
                  </View>
                </View>
              ) : null}

              <View style={styles.buttonsContainer}>
                <View style={styles.buttonContainer}>
                  <PButton
                    onPress={
                      this.state.selectedTab !== 1
                        ? this.transferAmount
                        : this.requestAmount
                    }
                    style={{ borderRadius: 10 }}
                    noGradient={true}
                    title={
                      this.state.selectedTab !== 1
                        ? "SÍ, ENVIAR SALDO"
                        : "SÍ, PEDIR SALDO"
                    }
                  />
                </View>
                <View style={styles.buttonContainer}>
                  <PButton
                    onPress={this.closeModal}
                    style={{ borderRadius: 10 }}
                    secondaryButton={true}
                    title={"NO, VOLVER"}
                  />
                </View>
              </View>
            </PBModal>
            {/* <Toast ref="toast" /> */}
            <PBModal
              showModal={this.state.showAuthFailedModal}
              titleText={this.state.modalTitle}
              bodyText={this.state.modalBody}
              type="alert"
              showButton1={true}
              button1Text={this.state.modalButtonText}
              button1Press={
                this.state.modalButtonTap
                  ? this.state.modalButtonTap
                  : this.closeModal
              }
              icon={this.state.modalIcon}
              onBackButtonPress={
                this.state.modalBackButtonPress
                  ? this.state.modalBackButtonPress
                  : this.goBack
              }
            />

            <CarpoolSuccessFailureModal
              smallIcon={true}
              showModal={this.state.showResultModal}
              success={this.state.showResultModalStatus}
              bodyText={this.state.showResultModalBodyText}
              // onBackButtonPress={() => {
              //   this.setState({
              //     showResultModal: false,
              //     showResultModalStatus: null,
              //     showResultModalBodyText: null,
              //     recipient: null,
              //     optionalMessage: null,
              //   });
              // }}
              onButtonPress={() => {
                this.setState({
                  showResultModal: false,
                  showResultModalStatus: null,
                  showResultModalBodyText: null,
                  recipient: null,
                  optionalMessage: null,
                });
              }}
            />
          </View>

          <IonToast
            isOpen={this.state.showToast}
            onDidDismiss={() => this.setState({ showToast: false })}
            message={this.state.toastMsg}
            duration={2000}
          />
        </IonContent>
      </IonPage>
    );
  }
}

const styles = {
  flex: {
    flex: 1,
  },
  dpContainer: {
    // marginBottom:10,
  },
  hLine: {
    borderBottomWidth: 1,
    borderBottomColor: "#ccc",
    marginTop: 10,
    marginBottom: 10,
    width: "100%",
  },
  titleContainer: {
    alignItems: "center",
    marginTop: 10,
    marginBottom: 0,
    paddingLeft: 20,
    paddingRight: 20,
  },
  failureModalBody: {
    marginHorizontal: 15,
  },
  text1Container: {
    marginBottom: 15,
  },

  recipientContainer: {
    flexDirection: "row",
    alignItems: "flex-start",
  },
  dpContainer: {
    marginTop: 5,
  },
  imgCircle: {
    width: 15,
    height: 15,
    borderRadius: 7,
    overflow: "hidden",
    borderColor: "#fff",
    borderWidth: 2,
    alignItems: "center",
    justifyContent: "center",
  },

  recipientIconContainer: {
    position: "absolute",
    top: 0,
    right: 0,
    flexDirection: "row",
    width: 100,
    justifyContent: "flex-end",
  },
  recipientInfoContainer: {
    paddingVertical: 15,
    paddingTop: 0,
    marginLeft: 10,
  },
  recipientIcon: {
    marginLeft: 10,
  },

  favouritesContainer: {
    // marginBottom: 20,
    marginTop: 15,
  },
  favouriteListContainer: {
    paddingVertical: 5,
  },
  buttonContainer: {
    // marginBottom: -10,
    marginTop: 10,
  },
  tabsContainer: {
    width: "60%",
    alignItems: "center",
    justifyContent: "center",
    marginLeft: "20%",
    marginTop: 20,
  },
  paymentRadioButton: {
    borderRadius: 10,
    width: 16,
    height: 16,
    alignItems: "center",
    justifyContent: "center",
    borderWidth: 2,
    borderColor: "#FF5C60",
    marginRight: 10,
  },
  paymentRadioButtonInner: {
    borderRadius: 8,
    width: 8,
    height: 8,
    backgroundColor: "#FF5C60",
    marginTop: 2,
    marginLeft: 2,
  },
  kuposPayCheck: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
  },
  openContacts: {
    width: "100%",
    marginBottom: 15,
  },
  openContactsInput: {
    width: "100%",
    marginTop: 3,
  },
  openContactsInputInner: {
    flex: 1,
    height: 50,
    alignItems: "center",
    display: "flex",
    flexDirection: "row",
    borderRadius: 10,
    borderWidth: 1,
    borderColor: "#ccc",
    paddingHorizontal: 10,
  },
};

export default connectData()(NewTransferRechargeScreen);
