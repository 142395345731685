const Banner = '/assets/santarosa/background-image.webp';
const backimage = '/assets/santarosa/background-image.webp';
const HeaderLogo = '/assets/kennybus/general/kennybus-header-logo.png';
const FooterLogo = '/assets/kennybus/general/kennybus-footer-logo-white.png';
const BookingInActive = '/assets/kennybus/general/booking_white.svg';
const BookingActive = '/assets/kennybus/general/booking.svg';
const CancelInActive = '/assets/kennybus/general/cancel_white.svg';
const CancelActive = '/assets/kennybus/general/cancel.svg';

const Whatsapp = '/assets/kennybus/icons/whatsapp.svg';
const Email = '/assets/santarosa/icons/email.svg';
const HeadPhone = '/assets/santarosa/icons/contact.svg';
const YellowLine = '/assets/santarosa/icons/red-line.png';

const OriginIcon = '/assets/santarosa/icons/origin.svg';
const DestinationIcon = '/assets/santarosa/icons/destination.svg';
const CalendarIcon = '/assets/kennybus/icons/calendar.svg';

const pnr = '/assets/santarosa/santarosa-pnr.svg';
const PopupIcon = '/assets/kennybus/general/Pop-up.jpg';

//  Common Assets as we have in tepual
const animation =
	'/assets/santarosa/icons/animation.gif';
const BottomFooterKuposLogo = '/assets/tepual/Footer-kuposLogo.svg';

const BG = '/assets/santarosa/background-image.webp';
const checkboxIcon = '/assets/tepual/checkbox.png';
const close = '/assets/kennybus/icons/close.svg';

// const BG = '/assets/tepual/home-banners/BusesTepual-Banner-MobileVersion-1.jpg';

const fb = '/assets/santarosa/icons/facebook-icon.svg';
const tw = '/assets/santarosa/icons/twitter-icon.svg';
const insta = '/assets/santarosa/icons/instagram-icon.svg';


// accounts section
const pay = '/assets/blue/Andipay.png';


const tarjetas = '/assets/santarosa/icons/card.png';;
const viajes = '/assets/santarosa/icons/mytrips.png';
const movimientos = '/assets/santarosa/icons/rechargewallet.png';
const wallet = '/assets/santarosa/icons/rechargewallet.png';
const shareMoney = '/assets/santarosa/icons/transfer-money.png';
const camera = '/assets/santarosa/icons/camera.png';
const checkmark = '/assets/orange/IllustratedIcons-CheckMark-Blue.png';
const tick = '/assets/orange/IllustratedIcons-Tick-Blue.png';
const downArrow = '/assets/orange/IllustratedIcons-DropDown-Blue.png';
const loginblue = '/assets/santarosa/icons/user-circular.png';
const recoverpswd = '../../assets/carpool/forgot-circle.png';
const success = '/assets/santarosa/icons/success.png';;
const failure = '/assets/santarosa/icons/failure.png';
const warning = '/assets/santarosa/icons/important.png';
const userIcon = '/assets/santarosa/icons/user-circular.png';
const heartOn = '../assets/icons/heart.png';
const heartOff = '../assets/icons/heart-empty.png';
const registrationSuccess = '/assets/carpool/registration-done-circle.png';
const operatorPay = '/assets/santarosa/icons/Santa-Rosa-pay.png'

const santarosaIcons = [
	{ icon: operatorPay, name: 'operatorPay' },
	{ icon: Banner, name: 'Banner' },
	{ icon: HeaderLogo, name: 'headerLogo' },
	{ icon: FooterLogo, name: 'footerLogo' },
	{ icon: OriginIcon, name: 'Origin' },
	{ icon: OriginIcon, name: 'originoIcon' },
	{ icon: DestinationIcon, name: 'Destination' },
	{ icon: DestinationIcon, name: 'destinoIcon' },
	{ icon: CalendarIcon, name: 'Calendar' },
	{ icon: BookingInActive, name: 'BIA' },
	{ icon: BookingActive, name: 'BA' },
	{ icon: CancelInActive, name: 'CIA' },
	{ icon: CancelActive, name: 'CA' },
	{ icon: BottomFooterKuposLogo, name: 'BottomFooterKuposLogo' },
	{ icon: PopupIcon, name: 'Popup' },
	{ icon: Whatsapp, name: 'Whatsapp' },
	{ icon: Email, name: 'Email' },
	{ icon: HeadPhone, name: 'Headphone' },
	{ icon: YellowLine, name: 'Line' },
	{ icon: BG, name: 'BG' },

	//   common assets
	{ icon: checkboxIcon, name: 'Checkbox' },
	{ icon: animation, name: 'animation' },
	{ icon: pnr, name: 'pnr' },
	{ icon: close, name: 'close' },


	// social media
	{ icon: fb, name: 'facebook' },
	{ icon: tw, name: 'twitter' },
	{ icon: insta, name: 'instagram' },

	// accounts section
	{ icon: pay, name: 'pay' },
	{ icon: backimage, name: 'backimage' },
	{ icon: tarjetas, name: 'tarjetas' },
	{ icon: viajes, name: 'viajes' },
	{ icon: movimientos, name: 'movimientos' },
	{ icon: wallet, name: 'wallet' },
	{ icon: shareMoney, name: 'shareMoney' },
	{ icon: camera, name: 'camera' },
	{ icon: checkmark, name: 'checkmark' },
	{ icon: tick, name: 'tick' },
	{ icon: downArrow, name: 'downArrow' },


	{ icon: loginblue, name: 'loginblue' },
	{ icon: recoverpswd, name: 'recoverpswd' },
	{ icon: success, name: 'success' },
	{ icon: failure, name: 'failure' },
	{ icon: heartOn, name: 'heartOn' },
	{ icon: heartOff, name: 'heartOff' },
	{ icon: registrationSuccess, name: 'registrationSuccess' },
	{ icon: userIcon, name: 'userIcon' },
	{ icon: warning, name: 'warning' },
];

export default santarosaIcons;
