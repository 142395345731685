const email = '/assets/ivergrama/General/Ivergrama-Website-Email-Icon.svg';

const menuDot = '/assets/torres/Home/three-dots.png';

const BookingActive =
	'/assets/ivergrama/Home/Ivergrama-Website-Home-SearchBar-BuyTicket.svg';
const CancelActive =
	'/assets/ivergrama/Home/Ivergrama-Website-Home-SearchBar-CancelTicket.svg';
const OriginIcon =
	'/assets/torres/Home/origin.svg';
const DestinationIcon =
	'/assets/torres/Home/destination.svg';
const CalendarIcon =
	'/assets/torres/Home/calender.png';

const titleUnderline = '/assets/torres/aboutUs/line.svg';

const BG =
	'/assets/torres/Home/banner.webp';

const sendBtn = '/assets/ivergrama/Contact/send.svg';
const contactFormImg =
	'/assets/ivergrama/Home/Ivergrama-Website-Home-BannerSpecialServices.jpg';

const fb =
	'/assets/torres/Contact/facebook.svg';
const fbHover =
	'/assets/torres/Contact/facebook.svg';
const insta =
	'/assets/torres/Contact/instagram.svg';
const whatsapp=
      'assets/torres/Home/whatsapp.png';

const LocationIcon = '/assets/ivergrama/General/LocationIcon.svg';
const PhoneIcon = '/assets/ivergrama/General/PhoneIcon.svg';
const HeaderLogo = '/assets/ivergrama/General/Header-BusesTepualLogo.svg';
const FooterLogo = '/assets/ivergrama/General/Footer-BusesTepualLogo.svg';
const checkboxIcon = '/assets/tepual/checkbox.png';

const BottomFooterKuposLogo = '/assets/tepual/Footer-kuposLogo.svg';
const animation =
	'/assets/torres/General/animation.gif';
const pnr =
	'/assets/torres/General/pnr.svg';

const contactMail =
	'/assets/torres/Contact/contact-email.svg';
const contactAddress =
	'/assets/torres/Contact/contact-phone.svg';
const contactfb =
	'/assets/torres/Contact/contact-fb.svg';
const contactinsta =
	'/assets/torres/Contact/contact-inst.svg';

const aboutUsImage =
	'/assets/torres/aboutUs/about-image.webp';
// banners
const faqBanner = '/assets/torres/faq/faq-banner.webp';
const serviceBanner =
	'/assets/torres/Services/service-banner.webp';
const contactUsBanner =
	'/assets/torres/Contact/contact-banner.webp';
const aboutUsBanner =
	'/assets/torres/aboutUs/about-banner.webp';
const tncBanner =
	'/assets/torres/tnc/terms-banner.webp';


const downArrow = '/assets/torres/tnc/down-arrow.svg';


// accounts section
const backimage = '/assets/blue/Andimar-BackgroundMobile.png';

const tarjetas = '/assets/torres/General/card.png';
const viajes = '/assets/torres/General/mytrips.png';
const movimientos = '/assets/torres/General/rechargewallet.png';
const wallet = '/assets/torres/General/rechargewallet.png';
const shareMoney = '/assets/torres/General/transfer-money.png';
const camera = '/assets/torres/General/user-circular.png';
const checkmark = '/assets/orange/IllustratedIcons-CheckMark-Blue.png';
const tick = '/assets/orange/IllustratedIcons-Tick-Blue.png';
const loginblue = '/assets/torres/General/user-circular.png';
const recoverpswd = "/assets/torres/General/login.png";
const success = '/assets/torres/General/success.png';
const failure = '/assets/torres/General/failure.png';
const warning = '/assets/torres/General/important.png';;
const userIcon = '/assets/torres/General/user-circular.png';
const heartOn = '../assets/icons/heart.png';
const heartOff = '../assets/icons/heart-empty.png';
const registrationSuccess = '/assets/torres/General/registration.png';

//  pet seat 
const petseatref = '/assets/torres/pet/pet-icon.png';
const petseatModal = '/assets/ivergrama/pet/PopUp.png';
const petfilteroff = '/assets/ivergrama/pet/Ivergrama-ResultPage-PetsOnBoard-Button-Filter_off.svg';
const petfilteron = '/assets/ivergrama/pet/Ivergrama-ResultPage-PetsOnBoard-Button-Filter_On.svg';

const operatorPay = '/assets/torres/pay.png'

const torresIcons = [
	{ icon: menuDot, name: 'menuDot' },
	{ icon: PhoneIcon, name: 'phone' },
	{ icon: email, name: 'email' },
	{ icon: LocationIcon, name: 'location' },
	{ icon: HeaderLogo, name: 'headerLogo' },
	{ icon: FooterLogo, name: 'footerLogo' },
	{ icon: contactFormImg, name: 'contactFormImg' },
	{ icon: titleUnderline, name: 'titleUnderline' },

	{ icon: sendBtn, name: 'sendBtn' },
	{ icon: fb, name: 'fb' },
	{ icon: fbHover, name: 'fbHover' },
	{ icon: insta, name: 'twitter' },
	{ icon: insta, name: 'twitterHover' },
	{ icon: BG, name: 'BG' },
	{icon: contactfb, name: 'contactfb'},
	{icon: contactinsta, name: 'contactinsta'},

	{ icon: BookingActive, name: 'BA' },
	{ icon: CancelActive, name: 'CA' },
	{ icon: OriginIcon, name: 'Origin' },
	{ icon: DestinationIcon, name: 'Destination' },
	{ icon: CalendarIcon, name: 'Calendar' },
	// { icon: SearchIcon, name: 'Search' },
	// { icon: PopupIcon, name: 'Popup' },
	{ icon: checkboxIcon, name: 'Checkbox' },
	{ icon: BottomFooterKuposLogo, name: 'BottomFooterKuposLogo' },
	{ icon: animation, name: 'animation' },
	{ icon: pnr, name: 'pnr' },

	{ icon: contactMail, name: 'contactMail' },
	{ icon: contactAddress, name: 'contactAddress' },
	{ icon: faqBanner, name: 'faqBanner' },
	{ icon: serviceBanner, name: 'serviceBanner' },
	{ icon: contactUsBanner, name: 'contactUsBanner' },
	{ icon: aboutUsImage, name: 'aboutUsImage' },
	{ icon: aboutUsBanner, name: 'aboutUsBanner' },
	{ icon: tncBanner, name: 'tncBanner' },
	{ icon: downArrow, name: 'downArrow' },

	// accounts section
	{ icon: backimage, name: 'backimage' },
	{ icon: tarjetas, name: 'tarjetas' },
	{ icon: viajes, name: 'viajes' },
	{ icon: movimientos, name: 'movimientos' },
	{ icon: wallet, name: 'wallet' },
	{ icon: shareMoney, name: 'shareMoney' },
	{ icon: camera, name: 'camera' },
	{ icon: checkmark, name: 'checkmark' },
	{ icon: tick, name: 'tick' },
	{ icon: loginblue, name: 'loginblue' },
	{ icon: recoverpswd, name: 'recoverpswd' },
	{ icon: success, name: 'success' },
	{ icon: failure, name: 'failure' },
	{ icon: heartOn, name: 'heartOn' },
	{ icon: heartOff, name: 'heartOff' },
	{ icon: registrationSuccess, name: 'registrationSuccess' },
	{ icon: userIcon, name: 'userIcon' },
	{ icon: warning, name: 'warning' },
	{icon: whatsapp, name: 'whatsapp' },

	//  pet seat 
	{ icon: petseatref, name: 'petseatref' },
	{ icon: petseatModal, name: 'petseatModal' },
	{ icon: petfilteron, name: 'petfilteron' },
	{ icon: petfilteroff, name: 'petfilteroff' },

	{ icon: operatorPay, name: 'operatorPay' },

];

export default torresIcons;
