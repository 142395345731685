// @flow

import React, { PureComponent } from "react";
import { connectData } from "../../redux";
import {
  CarpoolHeader,
  PBCard,
  PBText,
  PBModal,
  PBTouchable,
  PButton,
  PBInput,
  PBOTPInput,
  CarpoolSuccessFailureModal,
  View,
} from "../../components";
import { commonStyles, dimensions } from "../../theme";
import { CommonService, AppData } from "../../services";
import { IonContent, IonPage, IonToast } from "@ionic/react";
import { get } from "http";
import getIcons from "../../themes/functions/getIcons/getIcons";

class PreferencesScreen extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      otp: null,
      otpError: null,
      prefIcons: {},
      amenites:
        this.props.data.metaData.carpool &&
        this.props.data.metaData.carpool.amenites.split(","),
    };
    let registerFormData = sessionStorage.getItem("registerFormData");
    if (registerFormData) {
      this.registerFormData = JSON.parse(registerFormData);
    }
  }

  renderPrefItem = (label, icon, onPress, index) => {
    return (
      <View style={styles.iconItem}>
        <PBTouchable onPress={onPress}>
          <View style={styles.iconItemInner}>
            <img
              style={styles.prefIcon}
              src={
                this.state.prefIcons[index]
                  ? CommonService.prefIcons[icon].icon
                  : CommonService.prefIconsGrey[icon].icon
              }
            />
            <PBText style={{ textAlign: "center", fontSize: 13 }}>
              {label}
            </PBText>
          </View>
        </PBTouchable>
        {/* {this.state.prefIcons[index] ? <Img source={require('../../../assets/carpool/tick.png')} style={styles.tick} /> : null}  */}
      </View>
    );
  };

  prefPressed = (i) => {
    try {
      let prefIcons = CommonService.copyObject(this.state.prefIcons);
      prefIcons[i] = !prefIcons[i];
      this.setState({
        prefIcons: prefIcons,
      });
    } catch (e) {
      console.log(e);
    }
  };
  skip = () => {
    if (CommonService.uploadPhoto) {
      this.registerWithUpload(this.registerFormData.registerData, CommonService.uploadPhoto);
    } else {
      this.register(this.registerFormData.registerData);
    }
  };

  onSubmit = () => {
    if (!this.props.fromMyAccount) {
      try {
        if (
          !this.state.prefIcons ||
          !Object.keys(this.state.prefIcons).length
        ) {
          this.setState({
            showToast:true,
            toastMessage:"Select Preferences"
          })
          return;
        }
        let data = this.registerFormData.registerData;
        let pref = [];
        for (let i in this.state.prefIcons) {
          pref.push(this.state.amenites[i]);
        }
        data["amenities"] = pref.join(",");
        data.is_kupos = true;
        if (CommonService.uploadPhoto) {
          this.registerWithUpload(data, CommonService.uploadPhoto);
        } else {
          this.register(data);
        }
      } catch (e) {
        console.log(e);
      }
    } else {
      this.setState({
        showSuccessFailureModal: true,
        showSuccessFailureModalStatus: true,
        showSuccessFailureModalBodyText:
          "Tus datos fueron actualizados correctamente.",
      });
    }
  };

  register = (data) => {
    this.props.register({
      callback: (res) => this.onResponse(res, data),
      data: data,
    });
  };

  onResponse = (result, data) => {
    console.log(result);
    try {
      if (
        result.success &&
        ((result.data && result.data.error) || result.error)
      ) {
        this.setState({
          showModal: true,
          modalTitle: "fracaso",
          modalBody: result.error,
          button1Text: "OK",
          // modalIcon: "/assets/icons/failure.png",
          modal:getIcons('failure')
        });
        return;
      } else if (result.data && result.data.id) {
        console.log(result.data);
        if (result.data.auth_token) {
          CommonService.storeLoginDetails(result.data, this.props.loginSuccess);
        }
        this.props.history.push("/register/status/success");
      } else {
        this.setState({
          showModal: true,
          modalTitle: "fracaso",
          modalBody: "",
          button1Text: "OK",
          // modalIcon: "/assets/icons/failure.png",
          modal:getIcons('failure')
        });
        return;
      }
    } catch (e) {
      console.log(e);
    }
  };

  registerWithUpload = (data, photo) => {
    const document = {
      uri: photo.uri,
      type: photo.type,
      name: photo.name,
    };
    let form;
    try {
      console.log(data);
      form = form = new window.FormData();
      form.append("document", photo, photo.name);
      form.append("name", data.name);
      form.append("email", data.email);
      form.append("country_code", data.country_code);
      form.append("phone", data.phone);
      form.append("password", data.password);
      form.append("identity_type", data.identity_type);
      form.append("identity_val", data.identity_val);
      form.append("referrer_code", data.referrer_code);
      // form.append("gender", data.gender);
      form.append("amenities", data.amenities);
      form.append("device_details", JSON.stringify(data.device_details));

      form.append("oauth_account", data.oauth_account);
      form.append("oauth_token", data.oauth_token);
      form.append("oauth_type", data.oauth_type);
      form.append("auth_info", JSON.stringify(data.auth_info));
      if (CommonService.shouldPassOperatorId(this.props.data.operator)) {
        form.append("operator_id",  CommonService.getOperatorId(this.props.data.operator));
      }
    } catch (e) {
      console.log(e);
    }
    console.log(form);
    this.props.showLoader();

    fetch(AppData.BASE_URL + "api/customer/register?", {
      body: form,
      method: "POST",
      headers: {
        "x-jwt-token": CommonService.jwt_key
      },
    })
      .then((response) => {
        return response.json();
      })
      .catch((error) => {
        this.props.hideLoader();
        console.log("ERROR ", error);
      })
      .then((responseData) => {
        this.props.hideLoader();
        console.log("Success", responseData);
        try {
          if (responseData && responseData.error) {
            this.setState({
              showModal: true,
              modalTitle: "fracaso",
              modalBody: responseData.error,
              button1Text: "OK",
              modalIcon: "/assets/icons/failure.png",
            });
            return;
          } else if (responseData && responseData.id) {
            if (responseData.auth_token) {
              CommonService.storeLoginDetails(
                responseData,
                this.props.loginSuccess
              );
            }
            this.props.history.push("/register/status/success");
          } else {
            this.setState({
              showModal: true,
              modalTitle: "fracaso",
              modalBody: "",
              button1Text: "OK",
              modalIcon: "/assets/icons/failure.png",
            });
          }
        } catch (e) {
          console.log(e);
        }
      })
    //   .done();
  };

  //   registerWithUpload = (data,photo) => {
  //     const document = {
  //       uri: photo.uri,
  //       type: photo.type,
  //       name: photo.fileName
  //     };
  //     const form = new FormData();
  //     form.append("document", document);
  //     form.append('email', data.email);
  //     form.append('password', data.password);
  //     form.append('id', data.id);

  //     this.props.showLoader();
  //     fetch(AppData.BASE_URL + 'api/customer/update_pa', {
  //       body: form,
  //       method: "POST",
  //       headers: {
  //         "Content-Type": "multipart/form-data"
  //       }
  //     })
  //       .then(response => {
  //           return response.json()
  //       })
  //       .catch(error => {
  //         this.props.hideLoader();
  //         console.log("ERROR ", error);
  //       })
  //       .then(responseData => {
  //         this.props.hideLoader();
  //         console.log("Success", responseData);
  //         if(responseData){
  //             CommonService.goToScreen(this.props.componentId,REGISTER_DONE_SCREEN,'',{},true,false,true);
  //         }else{
  //             this.setState({
  //                 showModal:true,
  //                 modalTitle:'fracaso',
  //                 modalBody:'',
  //                 button1Text:'OK',
  //                 modalIcon:require('../../../assets/icons/failure.png'),
  //             })
  //         }
  //       })
  //       .done();
  //   }

  render() {
    return (
      <IonPage>
        <IonContent>
          <View>
            <CarpoolHeader
              history={this.props.history}
              backIcon={true}
              label=" "
              labelDark={true}
            />
            <View className="preference-scren">
              <View style={styles.mainView}>
                <View style={{ alignItems: "center" }}>
                  <PBText
                    dark={true}
                    bold={true}
                    style={{ textAlign: "center", fontSize: 15 }}
                  >
                    ¿Cómo quieres viajar?
                  </PBText>
                </View>
                <View
                  style={{
                    display:"flex",
                    justifyContent:'center',
                    alignItems: "center",
                    marginTop: 10,
                    marginBottom: 25,
                  }}
                >
                  <PBText style={{ textAlign: "center", fontSize: 14 }}>
                    Al seleccionar tus preferencias de viaje nos ayudas a
                    encontrar la mejor opción para ti.
                  </PBText>
                </View>
                <View style={styles.prefsContainer}>
                  {this.state.amenites &&
                    this.state.amenites.map((val, k) => {
                      return k >= 0 && k < 3
                        ? this.renderPrefItem(
                            CommonService.prefIcons[val.trim()].name,
                            val.trim(),
                            () => this.prefPressed(k),
                            k
                          )
                        : null;
                    })}
                  {/* {this.renderPrefItem('Fumador',require('../../../assets/carpool/cigerette-circle.png'),() => this.prefPressed(0),0)}
                    {this.renderPrefItem('Música',require('../../../assets/carpool/music-circle.png'),() => this.prefPressed(1),1)}
                    {this.renderPrefItem('Solo mujeres',require('../../../assets/carpool/girl-circle.png'),() => this.prefPressed(2),2)}
                    {this.renderPrefItem('A / C',require('../../../assets/carpool/ac-circle.png'),() => this.prefPressed(3),3)}
                    {this.renderPrefItem('Equipaje',require('../../../assets/carpool/luggage-circle.png'),() => this.prefPressed(4),4)}
                    {this.renderPrefItem('Conversación',require('../../../assets/carpool/chat-circle.png'),() => this.prefPressed(5),5)}
                    {this.renderPrefItem('Asiento\ndelantero',require('../../../assets/carpool/seat-forward-circle.png'),() => this.prefPressed(6),6)}
                    {this.renderPrefItem('Asiento\nbebés',require('../../../assets/carpool/seat-babies-circle.png'),() => this.prefPressed(7),7)}
                    {this.renderPrefItem('Pet\nfriendly',require('../../../assets/carpool/paw-circle.png'),() => this.prefPressed(8),8)} */}
                </View>
                <View style={styles.prefsContainer}>
                  {this.state.amenites &&
                    this.state.amenites.map((val, k) => {
                      return k > 2 && k < 6
                        ? this.renderPrefItem(
                            CommonService.prefIcons[val.trim()].name,
                            val.trim(),
                            () => this.prefPressed(k),
                            k
                          )
                        : null;
                    })}
                </View>
                <View style={styles.prefsContainer}>
                  {this.state.amenites &&
                    this.state.amenites.map((val, k) => {
                      return k > 5 && k < 9
                        ? this.renderPrefItem(
                            CommonService.prefIcons[val.trim()].name,
                            val.trim(),
                            () => this.prefPressed(k),
                            k
                          )
                        : null;
                    })}
                </View>

                <View style={styles.buttonContainer}>
                  <PButton
                    onPress={this.onSubmit}
                    style={{ borderRadius: 10 }}
                    noGradient={true}
                    title={"GUARDAR"}
                  />
                </View>
                {!this.props.fromMyAccount ? (
                  <View style={{ alignItems: "center", display:'flex', justifyContent:'center', marginTop:15 }}>
                    <PBText>Quiero seleccionar luego.</PBText>
                    <a onClick={this.skip}>
                      <PBText primary={true}>Omitir este paso.</PBText>
                    </a>
                  </View>
                ) : null}
              </View>
            </View>
            <PBModal
              showModal={this.state.showModal}
              titleText={this.state.modalTitle}
              bodyText={this.state.modalBody}
              type="alert"
              showButton1={true}
              button1Text={
                !this.state.showRegisterLink
                  ? "OK, CONTINUAR"
                  : "OK, INTENTAR DE NUEVA"
              }
              button1Press={() => this.setState({ showModal: false })}
              icon={this.state.modalIcon}
            >
              {/* {this.state.showRegisterLink && <View style={styles.registerLinkContainer}>
                    <TouchableOpacity onPress={this.register}>
                        <PBText primary={true}>Regístrate aquí</PBText>
                    </TouchableOpacity>
                </View>} */}
            </PBModal>
            <CarpoolSuccessFailureModal
              showModal={this.state.showSuccessFailureModal}
              success={this.state.showSuccessFailureModalStatus}
              smallIcon={true}
              bodyText={this.state.showSuccessFailureModalBodyText}
              buttonText={"OK, CONTINUAR"}
              onButtonPress={() => {
                this.setState({
                  showSuccessFailureModal: false,
                  showSuccessFailureModalBodyText: null,
                });
                CommonService.goBack(this.props.componentId);
              }}
            />
            {/* <Toast ref="toast" /> */}
          </View>
          <IonToast
            isOpen={this.state.showToast}
            onDidDismiss={this.setState({ showToast: false })}
            message={this.state.toastMessage}
            duration={2000}
          />
        </IonContent>
      </IonPage>
    );
  }
}

const styles = {
  flex: {
    flex: 1,
  },
  mainView: {
    width: dimensions.vw * 100 - 50,
    marginHorizontal: 25,
  },
  fontSize: {
    fontSize: 12,
  },
  buttonContainer: {
    marginTop: 20,
    paddingHorizontal: 15,
  },
  prefsContainer: {
    width: "100%",
    marginTop: 10,
    display:"flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
    // flexWrap:'wrap'
  },
  iconItem: {
    width: "calc(33.33vw - 26px)",
    // height:(dimensions.vw*100)/4,
    display:"flex",
    justifyContent:'center',
    alignItems: "center",
    marginBottom: 15,
  },
  prefIcon: {
    marginBottom: 10,
    width:'calc(22.22vw - 22px)'
  },
  iconItemInner: {
    width: "100%",
    display:"flex",
    flexDirection:'column',
    alignItems: "center",
    paddingVertical: 5,
  },
  tick: {
    width: 20,
    height: 20,
    tintColor: "#464647",
    resizeMode: "stretch",
    position: "absolute",
    top: 6,
    right: 14,
  },
};

export default connectData()(PreferencesScreen);
