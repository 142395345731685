import { IonContent, IonPage } from '@ionic/react';
import React, { useState } from 'react';

import { CommonService } from '../../../services';
import Header from '../../common/header/Header';
import HeroSection from '../../common/HeroSection/HeroSection';
import IvergramaFooter from '../common/IvergramaFooter';
import IvergramaTitle from '../common/IvergramaTitle';
import { connectData } from '../../../redux';
import getIcons from '../../functions/getIcons/getIcons';
import Header6 from '../../common/header/Header6';

// const romanToNumericMap = {
// 	'I': 1,
// 	'II': 2,
// 	'III': 3,
// 	'IV': 4,
// 	'V': 5,
// 	'VI': 6,
// };

// const replaceRomanNumerals = (text) => {
// 	return text.replace(/\b(I{1,3}|IV|V|VI{0,3}|IX|X|XI{0,3}|XIV|XV|XVI{0,3}|XIX|XX)\. /g, (match) => {
// 		const romanNumeral = match.trim().slice(0, -1);
// 		const numericValue = romanToNumericMap[romanNumeral];
// 		return numericValue !== undefined ? numericValue + '. ' : match;
// 	}).replace(/\{C\}/g, '');
// };

const IvergramaTnCScreen = (props) => {
	const operator = CommonService.getLocal('op') || props.home.operator;

	let data = [];
	if (operator && operator.operator_name === 'aeroquinta' && operator.terms) {
		const sections = operator.terms.split('<strong>').slice(1);

		data = sections.map((section, index) => {
			let heading = section.split('</strong>')[0].trim();
			// heading = heading.replace(/^\w+\.\s*/, '');

			let content = section.split('</strong>')[1].trim();
			content = content.replace(/\{C\}<\!--[^>]*-->/g, '');

			return {
				id: index + 1,
				heading: heading,
				content: content,
				isOpen: false,
			};
		});
	} else {
		data = [
			{
				id: 1,
				heading: 'Términos y condiciones',
				content: operator && operator.terms ? operator.terms : '',
				isOpen: false,
			},
			{
				id: 2,
				heading: 'Políticas de privacidad',
				content: operator && operator.privacy_policy ? operator.privacy_policy : '',
				isOpen: false,
			},
		];
	}

	const [accordionData, setAccordionData] = useState(data);

	const toggleAccordion = id => {
		setAccordionData(prevAccordionData =>
			prevAccordionData.map(accordion => ({
				...accordion,
				isOpen: accordion.id === id ? !accordion.isOpen : accordion.isOpen,
			})),
		);
	};

	return (
		<IonPage>
			<IonContent>
				{operator && operator.operator_name === 'basetorres' ? <Header6/> : <Header />}
				<HeroSection
					title={operator.operator_name === 'arzola' || operator.operator_name === "basetorres" ? 'Términos de uso' : 'Términos y condiciones'}
					height={'45vh'}
					description={operator.operator_name === 'arzola' || operator.operator_name === "basetorres" ? 'Home / Términos de uso ' : 'Home / Términos y condiciones'}
					heroImage={getIcons('tncBanner', operator)}
				/>

<IvergramaTitle 
    operator={operator} 
    title={operator.operator_name === 'arzola' ? <strong>Términos de uso</strong> : 'Términos y condiciones'} 
/>


				{/* <div className='new-container mb10 mt10'>
					<div
						dangerouslySetInnerHTML={createMarkup(
							operator.terms ? operator.terms : ''
						)}
						className='lh-1-3 justify-text'
					/>
				</div> */}
				<div className="new-container  mb10 mt10">
					{accordionData.map((accordion, index) => (
						<div key={accordion.id} style={{ marginBottom: '10px' }}>
							<span
								onClick={() => toggleAccordion(accordion.id)}
								className="accordion-toggle"
								style={{
									color: '#464647',
									// color: `${operator && operator.operator_name === 'aeroquinta' ? "" : "rgb(1, 82, 166)"}`,
									display: 'flex',
									alignItems: 'center',
									padding: '12px',
									borderRadius: '5px',
									boxShadow: 'rgba(99, 99, 99, 0.2) 0px 2px 8px 0px',
								}}
							>
								<div
									style={{
										display: 'flex',
										alignItems: 'center',
										// color: `${operator && operator.operator_name === 'aeroquinta' ? "" : "rgb(1, 82, 166)"}`,

										fontWeight: `${operator && operator.operator_name === 'aeroquinta' ? "500" : "bolder"}`,
									}}
								>
									<p style={{ marginBottom: '5px' }}>{index + 1}.</p>
									<p
										style={{ marginBottom: '5px', marginLeft: '10px' }}
										dangerouslySetInnerHTML={{ __html: accordion.heading }}
									/>
								</div>
                            { operator.operator_name === "arzola" || operator.operator_name === "basetorres" ? (
								<div style={{ marginLeft: '10px' }}>
									{accordion.isOpen ? (
										<img
											alt="white-arrow"
											src={getIcons('downArrow', operator)}
											style={{ width: '15px', transform: 'rotate(180deg)', marginBottom: '5px' }}
										/>
									) : (
										<img
											alt="white-arrow"
											src={getIcons('downArrow', operator)}
											style={{ width: '15px', marginBottom: '5px' }}
										/>
									)}
								</div>
							): null }
							</span>
							{accordion.isOpen && (
								<div
									dangerouslySetInnerHTML={{ __html: accordion.content }}
									className="justify-text"
									style={{ fontSize: '14px', marginTop: '20px', lineHeight: '18.2px' }}
								/>
							)}
						</div>
					))}
				</div>
				<IvergramaFooter />
			</IonContent>
		</IonPage>
	);
};

export default connectData()(IvergramaTnCScreen);
