import './ThemeThreeFooter.css';

import { CommonService } from '../../../services';
import React from 'react';
import { connectData } from '../../../redux';
import getIcons from '../../functions/getIcons/getIcons';

const ThemeThreeFooter = (props) => {
	const openWhatsapp = () => {
		window.open(`//api.whatsapp.com/send?phone=${operator.phone_number}`, "_blank");
	};
	const operator = CommonService.getLocal('op') || props.data.operator;

	const textColor =
		operator &&
		operator.color_codes &&
		operator.color_codes.secondary_text_color &&
		operator.color_codes.secondary_text_color;
	return (
		operator && (
			<div className='themes-theme-three-footer'>
				{operator && operator.operator_name === "ivergrama" ?
					(

						<div
							style={{
								position: "fixed",
								bottom: "24px",
								right: "24px",
								zIndex: 1000,
								display: "flex",
								alignItems: "center",
								justifyContent: "center",
								cursor: "pointer",
							}}
							onClick={openWhatsapp}
						>
							<img
								src="/assets/ivergrama/Home/whtsapp.svg"
								style={{ marginRight: "10px" }}
								height={50}
								width={50}
							/>
						</div>
					) : (null)
				}
				<div
					style={{
						color: textColor,
					}}
				>
					<div
						className='d-flex-c p-4'
						style={{
							background:
								operator &&
								operator.color_codes &&
								operator.color_codes.footer_color &&
								operator.color_codes.footer_color,
						}}
					>
						<div className='footer-center'>
							<div className='logo'>
								<a href='/'>
									<img
										// src={getIcons('footerLogo', operator)}
										src={
											operator && operator.footer_logo && operator.footer_logo
										}
										// alt={this.props.t('TRANSLATIONS.VIATUR_LOGO')}
										style={{
											marginTop: '0',
											width:
												operator &&
												operator.footer_logo_width &&
												operator.footer_logo_width,
											// height: 50,
										}}
										alt=''
									/>
								</a>
							</div>

							<style>
								.fb{'{'}
								background-image: url({getIcons('fb')}); {'}'}
								.fb:hover{'{'}
								background-image: url({getIcons('fbHover')}); {'}'}
								.twitter{'{'}
								background-image: url({getIcons('twitter')}); {'}'}
								.twitter:hover{'{'}
								background-image: url({getIcons('twitterHover')}); {'}'}
							</style>



							{operator && operator.operator_name === "busnorte" ? (
								<div className='contact-links '>
									<a href='https://www.facebook.com/busnorteoficial' target='_blank'>
										<span className='fb social'></span>
									</a>
									<a href='https://www.instagram.com/busnorteoficial/' target='_blank'>
										<span className='twitter social'></span>
									</a>
								</div>
							) : operator && operator.operator_name === "ivergrama" ? (
								<div className='contact-links'>
									<a href='https://www.facebook.com/Buses-Ivergrama-931335030348861/' target='_blank'>
										<span className='fb social'></span>
									</a>
									<a href='https://twitter.com/busesiver' target='_blank'>
										<span className='twitter social'></span>
									</a>
								</div>
							) : operator && operator.operator_name === "aeroquinta" ? (
								<div className='contact-links' style={{ display: 'flex', alignItems: 'center' }}>
									<a href='https://www.facebook.com' target='_blank'>
										{/* <span className='fb social'></span> */}
										<span className="d-flex-c email" style={{ color: 'white', display: 'flex', alignItems: 'center' }}>
											<img src={getIcons('fb', operator)} alt="DIS" className="top_icon" style={{ height: "30px", width: "30px", }} />
										</span>
									</a>
									<a href='https://www.instagram.com' target='_blank' style={{ marginLeft: '0px' }}>
										{/* <span className='twitter social'></span> */}
										<span className="d-flex-c email" style={{ color: 'white', display: 'flex', alignItems: 'center' }}>
											<img src={getIcons('instagram', operator)} alt="DIS" className="top_icon" style={{ height: "30px", width: "30px" }} />
										</span>
									</a>
									<div>
										{operator && operator.operator_name === "aeroquinta" && operator.email ? (
											<span className="d-flex-c email" style={{ color: 'white', display: 'flex', alignItems: 'center' }}>
												<img src={getIcons('email', operator)} alt="DIS" className="top_icon" style={{ height: "24px", width: "30px", paddingRight: "5px" }} />
												{operator.email}
											</span>
										) : null}
									</div>
								</div>
							): operator && operator.operator_name === "basetorres" ? (
								<div className='contact-links'>
									<a href='https://www.facebook.com/people/Buses-Base-Torres/100064765328472/?_rdr' target='_blank'>
										<span className='fb social'></span>
									</a>
									<a href='https://www.instagram.com/busesbasetorres/?hl=es-la' target='_blank'>
										<span className='twitter social'></span>
									</a>
								</div>
							)  : (
								<div className='contact-links'>
									<a href='#' target='_blank'>
										<span className='fb social'></span>
									</a>
									<a href='#' target='_blank'>
										<span className='twitter social'></span>
									</a>


								</div>
							)}


						</div>
					</div>

					{operator && operator.show_bottom_footer ? (
						<ShowBottomFooter operator={operator} color={textColor} />
					) : null}
				</div>
			</div>
		)
	);
};
const ShowBottomFooter = ({ operator, color }) => {
	return (
		<div
			className={operator && operator.operator_name === "busnorte" || operator && operator.operator_name === "aeroquinta" ? "bottom-footer new-bold-font" : ""}
			style={{
				background:
					operator && operator.color_codes
						? operator.color_codes.sub_footer_color
						: '',
			}}
		>
			{operator && operator.operator_name !== "basetorres" ?
				(<div
					style={{
						color,
					}}
				>
					<span>
						{operator.operator_name}® {new Date().getFullYear()}
					</span>
					<span>
						- Todos los derechos reservados © |
						<span className='less-bold-text'> Mapa del sitio </span> | Powered by
					</span>
					<a href='https://kupos.cl' target='_blank' style={{ color: '#fff' }}>
						<span className='less-bold-text'> kupos.cl</span>
					</a>
				</div>)
				:
				(<div
					style={{
						color,
						display: 'flex',
						flexDirection: 'column',
						justifyContent: 'center',
						alignItems: 'center',
						textAlign: 'center',
					}}
				>
					<span>
						{operator.name} {new Date().getFullYear()}
					</span>
					<span>
						Todos los derechos reservados © |
						{/* <span className='less-bold-text'> Mapa del sitio </span> | */}
						Powered by
						<a href='https://kupos.cl' target='_blank' style={{ color: '#fff' }}>
							<span className='less-bold-text'> kupos.cl</span>
						</a>
					</span>
				</div>)}
		</div>
	);
};
export default connectData()(ThemeThreeFooter);
