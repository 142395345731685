import { IonContent, IonPage } from "@ionic/react";

import Header from "../common/header/Header";
import HeroSection from "../common/HeroSection/HeroSection";
import React from "react";
import SantarosaFooter from "./common/santarosafooter";
import ThemeTwoContactForm from "./common/ThemeTwoContactForm";
import ThemeTwoFooter from "./common/ThemeTwoFooter";
import ThemeTwoHeader from "./common/ThemeTwoHeader";
import ThemeTwoTitle from "./common/ThemeTwoTitle";
import { connectData } from "../../redux";
import getIcons from "../functions/getIcons/getIcons";
import { CommonService } from "../../services";

const ThemeTwoPrivacyPolicyScreen = (props) => {
	const operator = CommonService.getLocal('op') || props.data.operator;
	const createMarkup = (data) => {
		return { __html: data };
	};
	return (
		<IonPage>
			<IonContent>
				<ThemeTwoHeader />
				{operator && operator.operator_name === 'buseslafit' ? (null) : (<HeroSection
					height={"30vh"}
					heroImage={getIcons("Banner")}
					title={"Política de privacidad"}
					description={"Home / Política de privacidad"}
				/>)}

				<div style={{ paddingLeft: 20 }}>
					<ThemeTwoTitle operator={props.data.operator} title='Política de privacidad' />
				</div>
				<div style={{ width: "90%", marginLeft: "5%", textAlign: "justify" }}>
					<div
						dangerouslySetInnerHTML={createMarkup(
							props.data &&
								props.data.operator &&
								props.data.operator.privacy_policy
								? props.data.operator.privacy_policy
								: ""
						)}
						className="lh-1-3"
						style={{ fontSize: "13px" }}
					/>
				</div>

				{operator.operator_name === "santarosa" || operator.operator_name === "buseslafit"?
					
					<SantarosaFooter operator={operator} />
				   :<ThemeTwoFooter operator={operator} />}
			</IonContent>
		</IonPage>
	);
};

export default connectData()(ThemeTwoPrivacyPolicyScreen);
