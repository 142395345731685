import React from "react";
import { connectData } from "../../redux";
import ThemeTwoPrivacyPolicyScreen from "../ThemeTwo/ThemeTwoPrivacyPolicyScreen";
import ThemeFourPrivacyPolicyScreen from "../ThemeFour/ThemeFourPages/ThemeFourPrivacyPolicyScreen";

const CommonPrivacyPolicyPage = (props) => {
  const getPage = () => {
    const operator = props.data && props.data.operator && props.data.operator;
    if (operator.theme_type === 2 && operator.operator_name === "cormarbus") {
      return <ThemeTwoPrivacyPolicyScreen />;
    }
    if (operator.theme_type === 4 && operator.operator_name === "losnavegadores") {
      return <ThemeFourPrivacyPolicyScreen />;
    }

  };

  return <React.Fragment>{getPage()}</React.Fragment>;
};

export default connectData()(CommonPrivacyPolicyPage);
