import { IonContent, IonPage } from '@ionic/react';

import { CommonService } from '../../../services';
import Header from '../../common/header/Header';
import HeroSection from '../../common/HeroSection/HeroSection';
import React from 'react';
import ThemeFourFooter from '../common/ThemeFourFooter';
import { connectData } from '../../../redux';
import getIcons from '../../functions/getIcons/getIcons';

const ThemeFourPrivacyPolicyScreen = (props) => {
	const operator = CommonService.getLocal('op') || props.data.operator;

	const createMarkup = (data) => {
		return { __html: data };
	};

	return (
		<IonPage>
			<IonContent>
				<Header />
				<HeroSection
					title={'Políticas de privacidad'}
					{...operator.operator_name !== "losnavegadores" ? { description: 'Home / Políticas de privacidad' } : {}}
					heroImage={getIcons('tncBanner', operator)}
				/>

				<div className='new-container mb10 mt10'>
					<div
						dangerouslySetInnerHTML={createMarkup(
							operator.privacy_policy ? operator.privacy_policy : ''
						)}
						className={operator && operator.operator_name === 'chilebus' ? ' justify-text' : ' font11 justify-text'}
					/>
				</div>
				<ThemeFourFooter />
			</IonContent>
		</IonPage>
	);
};

export default connectData()(ThemeFourPrivacyPolicyScreen);
