import React from "react";
// import { View } from 'react-native';
// import Image from 'react-native-scalable-image';
// import EStyleSheet from 'react-native-extended-stylesheet';

import PBText from "./PBText";
import PButton from "./PButton";
import PBTouchable from "./PBTouchable";
import { dimensions, commonStyles } from "../theme";

const UploadComponent = ({
  icon,
  title,
  body,
  step1,
  step2,
  onPhotoClick,
  buttonLabel,
  onSubmit,
  hideButtonFileUploader
}) => {
  let renderSteps = (number, text) => {
    const operator = JSON.parse(localStorage.getItem("op"));
    return (
      <div style={styles.stepContainer}>
        <div style={{...styles.number,backgroundColor:operator.color_codes.secondary_color}} >
          <PBText style={{ color: "#fff", fontSize: 12 }}>{number}</PBText>
        </div>
        <PBText dark={true}>{text}</PBText>
      </div>
    );
  };
  return (
    <div className="upload-component">
      <div
        style={{
          marginTop: 20,
          marginBottom: 20,
          marginRight: '36vw',
          marginLeft: '36vw',
          borderRadius: '20vw',
          height: '28vw',
          overflow: "hidden",
          position:'relative'
        }}
      >
        <div className="upload-image-div" style={{width:'28vw',height:'28vw',backgroundSize: 'cover',backgroundPosition: 'center',backgroundImage: `url(${icon})`}}></div>
        <input type="file" onChange={onPhotoClick} style={{position:'absolute',zIndex:9,left:0,right:0,top:0,bottom:0,opacity:0}} />
      </div>
      <div style={{ alignItems: "center",display:'flex',justifyContent:'center' }}>
        <PBText
          dark={true}
          bold={true}
          style={{ textAlign: "center", fontSize: 15 }}
        >
          {title}
        </PBText>
      </div>
      <div
        style={{
          alignItems: "center",
          padding: '0 15vw',
          marginBottom: 20,
          marginTop:10
        }}
      >
        <PBText style={{ textAlign: "center", fontSize: 14 }}>{body}</PBText>
      </div>
      <div
      className="kupos-card"
        style={{
          paddingVertical: 30, marginTop: 25, marginBottom: 20 ,
        }}
      >
        {renderSteps(1, step1)}
        {renderSteps(2, step2)}
        <div style={styles.buttonContainer}>
          <PButton
            onPress={onSubmit}
            style={{ borderRadius: 10 }}
            noGradient={true}
            title={buttonLabel}
          />
          {!hideButtonFileUploader ? <input type="file" onChange={onPhotoClick}  style={{position:'absolute',zIndex:9,left:0,right:0,top:0,bottom:0,opacity:0}}  /> : null}
        </div>
      </div>
    </div>
  );
};

const styles = {
  buttonContainer: {
    marginTop: 10,
    position:'relative'
  },
  stepContainer: {
    display:'flex',
    flexDirection: "row",
    marginBottom: 22,
    alignItems: "center",
    textAlign:"justify"
    // paddingRight: '10vw',
  },
  number: {
    display:'flex',
    width: 20,
    height: 20,
    marginRight: 15,
    backgroundColor: "#FF5A5E",
    alignItems: "center",
    justifyContent: "center",
    borderRadius: 10,
  },
};

export default UploadComponent;
