import { IonContent, IonPage } from "@ionic/react";

import Header from "../common/header/Header";
import HeroSection from "../common/HeroSection/HeroSection";
import KuposHeader from "../../components/KuposHeader";
import React from "react";
import SantarosaFooter from "./common/santarosafooter";
import ThemeTwoContactForm from "./common/ThemeTwoContactForm";
import ThemeTwoFooter from "./common/ThemeTwoFooter";
import { connectData } from "../../redux";
import getIcons from "../functions/getIcons/getIcons";

const ThemeTwoContactUsScreen = (props) => {
	const operator = JSON.parse(localStorage.getItem('op'));

	return (
		<IonPage>
			<IonContent>
				{!(operator && operator.operator_name === "tarapaca") && <Header />}
				{operator && operator.operator_name === "tarapaca" && (
					<KuposHeader
						// boldTitle={"Contacto"} 
						handleHomeSubmit={() => props.history.push("/")}
						handleBackSubmit={() => props.history.goBack()}
						logo={operator && operator.header_logo}
					/>
				)}
				{operator && operator.operator_name === "buseslafit" ? (null) : (<HeroSection
					height={"30vh"}
					heroImage={getIcons("Banner")}
					title={"Contacto"}
					description={"Home / Contacto"}
				/>)}


				<ThemeTwoContactForm history={props.history} />
				{operator && operator.operator_name === 'buseslafit' ? (<SantarosaFooter operator={props.data.operator} />) : (<ThemeTwoFooter operator={props.data.operator} />)
				}

			</IonContent>
		</IonPage>
	);
};

export default connectData()(ThemeTwoContactUsScreen);