import {
	AppData,
	CommonService,
	GlobalService,
	storageService,
} from "../services";
import {
	IonContent,
	IonHeader,
	IonIcon,
	IonItem,
	IonLabel,
	IonList,
	IonMenu,
	IonMenuToggle,
	IonTitle,
	IonToolbar,
} from "@ionic/react";
import { Link, RouteComponentProps, withRouter } from "react-router-dom";
import React, { useState } from "react";

import { AppPage } from "../declarations";
import PBContactModal from "./PBContactModal";
import { PBText } from "../components";
import { connectData } from "../redux";
import getIcons from "../themes/functions/getIcons/getIcons";
import { menuController } from "@ionic/core";
import { get } from "http";

const loginMenuItems = [
	// {
	//   id: '1',
	//   name: 'Mi código QR',
	//   src: '/assets/icons/menu/icon_QR_code.png',
	//   icon: 'icon_QR_code',
	//   // "screen":MY_ACCOUNT_SCREEN,
	//   title: 'Mi código QR',
	//   url: '/my-account/qr',
	// },
	{
			id: "2",
			name: "Mi cuenta",
			src: "/assets/icons/menu/icon_avatar_circular.png",
			icon: getIcons("phone"),
			// "screen":RECHARGE_WALLET_SCREEN,
			title: "Mi cuenta",
			url: "/my-account",
	},
	{
			id: "13",
			name: "Mis viajes",
			src: "/assets/icons/menu/icon_my_trips.png",
			icon: "icon_my_trips",
			// "screen":MY_QR_CODE_SCREEN,
			title: "Mis viajes",
			url: "/my-account/my-trips",
	},
	{
			id: "17",
			name: "Mis movimientos",
			src: "/assets/carpool/sidemenu/movements.png",
			icon: "movements",
			title: "Mis movimientos",
			url: "transactions",
	},
];

var global: any = window;
const themeSevenHeaderLinks = [
	// {
	//   label: 'Servico',
	//   url: '',
	// },
	{ label: "Compra tu pasaje", url: "/" },

	{
			label: "Traslado de pasajeros",
			url: "/special-service",
	},
	{
			label: "Servicios de carga",
			url: "/cargo",
	},
	{
			label: "Precios y servicios",
			url: "/prices-and-services",
	},
	// {
	//   label: 'Destino',
	//   active: false,
	//   url: '/',
	// },

	{
			label: "¿Quiénes somos?",
			url: "/about-us",
	},
	{ label: "Venta de pasajes presencial", url: "/ticket-sales" },

	// {
	//   label: 'Visión',
	//   url: '/about-us',
	// },
	// {
	//   label: 'Misión',
	//   url: '/about-us',
	// },
	// {
	//   label: 'Historia',
	//   url: '/about-us',
	// },
	// {
	//   label: 'Nuestro equipo',
	//   url: '/about-us',
	// },
	// {
	//   label: 'Viajero frecuente',
	//   url: '',
	//   active: false,
	// },
	{
			label: "Tarjeta de viajero frecuente",
			url: "/frequent-card",
	},
	{
			label: "Términos y condiciones",
			url: "/terms-conditions",
	},
	{
			label: "Galería",
			active: false,

			url: "/gallery",
	},
	{
			label: "Nuestras empresas",
			active: false,

			url: "/fernandez-company",
	},
	{
			label: "Contacto",
			active: false,
			url: "/contact",
	},
];

const themeElevenHeaderLinks = [
	{
			label: "Rutas",
			active: false,
			icon: "/assets/centropuerto/Home/side-menu-rutas.svg",
			downarrow: "/assets/centropuerto/Home/dropdownarrow.png",
			dropdown: [
					{ label: " Los Héroes - Aeropuerto", icon: "", url: "/losheros" },
					{ label: "Pajaritos - Aeropuerto", icon: "", url: "/rutapajaritos" },
					{ label: "Terminal Sur - Aeropuerto", icon: "", url: "/terminalsur" },
					{ label: "Maipú - Aeropuerto", icon: "", url: "/rutasmaipu" },
			],
	},
	{
			label: "Tarifas",
			icon: "/assets/centropuerto/Home/sidemenutarifas.svg",
			url: "tariff",
	},
	{
			label: "Empresa",
			icon: "/assets/centropuerto/Home/sidemenuempresa.svg",
			url: "/company",
	},
	{
			label: "Preguntas frecuentes",
			icon: "/assets/centropuerto/Home/sidemenupreguntasfrecuentes.svg",
			url: "/faq",
	},
	{
			label: "Contacto",
			icon: "/assets/centropuerto/Home/side-menu-contacto.svg",
			url: "/contact",
	},
];

const WelcomeContainer = () => {
	return (
			<div className="welcome-container font10">
					{/* // CommonService.isLoggedIn(props.loginData)? */}
					<div className="welcome-image-container">
							{/* <img
									className="welcome-image-logged-in"
									src={"/assets/carpool/camera-add-circle.png"}
							/> */}
							<div className="camera-add-circle home-icon-circular-sprite size55"></div>
							<div className="user-name-container">
									<div className="display-flex">
											<PBText
													fontColor={"dark-font"}
													fontStyle={"bold-font"}
													fontSize={"font19"}
											>
													¡Hola
											</PBText>
											<PBText
													fontColor={"dark-font"}
													fontStyle={"bold-font"}
													fontSize={"font16"}
											>
													!
											</PBText>
									</div>

									{/* {this.props.data.loginData ? */}
									<div className="display-flex">
											<PBText fontSize={"font14"}>Modifica tu perfil</PBText>
											<img
													height={16}
													width={16}
													style={{ marginLeft: 6 }}
													src={"/assets/carpool/sidemenu/pencil.png"}
													alt=""
											/>
									</div>
									{/* :null} */}
							</div>
					</div>

					{/* { 
															CommonService.isLoggedIn(props.loginData) 
															?<View style={styles.welcomeTextContainer}><PBText style={styles.welcomeText}>{props.welcomeText}</PBText></View>
															:null
											} */}
			</div>
	);
};

interface MenuProps extends RouteComponentProps {
	appPages: AppPage[];
	setCitiesUnique: Function;
	setCitiesUniqueTrain: Function;
	loginSuccess: Function;
	getMetaDataSuccess: Function;
	getCitiesUnique: Function;
	getCitiesUniqueTrain: Function;
	getMetaData: Function;
	getOperatorSite: Function;
	getOperatorData:Function;
	data: any;
	showHideContactModal: any;
	setThirdPartyApp: Function;
	getATTerminals: Function;
	setATTerminals: Function;
	getSeatTypes: Function;
	setSeatTypes: Function;
	getATBlockTime: Function;
	setATBlockTime: Function;
	getSideMenuLinks: Function;
	setOperatorSite: Function;
	setOperatorData:Function;
}

class Menu extends React.Component<MenuProps> {
	state: any;

	constructor(props: any) {
			super(props);
			this.state = {
					showContactModal: false,
					showDropDown: false,
			};
			let referalApp = CommonService.idThirdPartyApp();
			if (referalApp) {
					this.props.setThirdPartyApp(referalApp);
			} else {
					if (window.location.href.indexOf("kupas.cl") <= -1) {
							// window.location.href = "https://www.pasajebus.com"
					}
			}
	}

	componentDidMount() {
			CommonService.windowHeight = window.innerHeight;
			this.checkLogin();
	}

	checkLogin = () => {
			let citiesUnique = localStorage.getItem("citiesUnique");
			// let citiesUniqueTrain = localStorage.getItem("citiesUnique");
			if (citiesUnique) {
					let citiesUniqueJ = JSON.parse(citiesUnique);
					this.props.setCitiesUnique(citiesUniqueJ);
			}
			let citiesUniqueTrain = localStorage.getItem("citiesUniqueTrain");
			if (citiesUniqueTrain) {
					let citiesUniqueTrainJ = JSON.parse(citiesUniqueTrain);
					this.props.setCitiesUniqueTrain(citiesUniqueTrainJ);
			}

			let metaData = storageService.getItem("metaData");

			if (metaData) {
					const data = JSON.parse(metaData);
					this.props.getMetaDataSuccess(data);
			}

			let loginData = storageService.getItem("loginData");
			if (loginData && Object.keys(loginData).length) {
					let loginJson = JSON.parse(loginData);
					// this.props.loginSuccess(loginJson);
					// // GlobalService.setGlobal('token',loginData.auth_token);
					global.token = loginJson.auth_token;
					CommonService.storeLoginDetails(loginJson, this.props.loginSuccess);
			} else {
					// GlobalService.setGlobal('token',null);
					global.token = null;
			}
			const { getMetaData, getOperatorSite, getCitiesUnique,getOperatorData } = this.props;
			getMetaData({
					callback: (result: any) => {
							if (result && result.data) {
									localStorage.setItem("jwt_key", result?.data?.jwt_secret);
									sessionStorage.setItem("x_auth_key", result?.data?.x_auth_key);
									CommonService.metaSecret(result?.data?.jwt_secret);
									CommonService.akd(result?.data?.x_auth_key);
							}
							setTimeout(() => {
									localStorage.setItem(
											"metaData",
											JSON.stringify(this.props.data.metaData),
									);
									if (this.props.data.metaData && this.props.data.metaData.carpool) {
											AppData.CARPOOL_URL = this.props.data.metaData.carpool.url;
											// GlobalService.setGlobal(
											//   "xkey",
											//   this.props.data.metaData.carpool.key
											// );
											global.xkey = this.props.data.metaData.carpool.key;
											// GlobalService.setGlobal(
											//   "xtoken",
											//   this.props.data.metaData.carpool.token
											// );
											global.xtoken = this.props.data.metaData.carpool.token;
									} else {
											AppData.CARPOOL_URL = "http://34.235.163.236";
											// global.xkey = "kgim1416737390";
											GlobalService.setGlobal("xkey", "kgim1416737390");
											// global.xtoken =
											// "7813126af6eedc003da23625e80c2b35082571d3b7b710e858d4a95163c828f5";
											GlobalService.setGlobal(
													"xtoken",
													"7813126af6eedc003da23625e80c2b35082571d3b7b710e858d4a95163c828f5",
											);
									}
									getOperatorSite({
											callback: (res: any) => {
													console.log("res-----------", res);
													if (
															res &&
															res.success &&
															res.data.data &&
															res.data.data.operator
													) {
															this.props.setOperatorSite(res.data.data.operator);
															localStorage.setItem(
																	"op",
																	JSON.stringify(res.data.data.operator),
															);


															getOperatorData({
																	callback: (res: any) => {
																			console.log("res-----------", res);
																					this.props.setOperatorData(res.data.data);
																					localStorage.setItem("operatorData", JSON.stringify(res.data.data));
																	} 
															}); 
															
																					

															getCitiesUnique({
																	callback: (result: any) => {
																			this.props.setCitiesUnique(result.cities);
																			// console.log("result.cities", result.cities);
																			setTimeout(() => {
																					localStorage.setItem(
																							"citiesUnique",
																							JSON.stringify(result.cities),
																					);
																			}, 300);
																	},
																	cities: citiesUnique,
																	operator_id: res.data.data.operator.operator_id,
															});
													} else {
															localStorage.removeItem("op");
															// localStorage.setItem("op", JSON.stringify(null));
													}
											},
									});
							}, 400);
					},
					metaData: metaData,
			});

			// getCitiesUniqueTrain({
			//   callback: (result: any) => {
			//     this.props.setCitiesUniqueTrain(result.cities);
			//     setTimeout(() => {
			//       storageService.setItem(
			//         "citiesUniqueTrain",
			//         JSON.stringify(result.cities)
			//       );
			//     }, 300);
			//   },
			//   cities: citiesUniqueTrain,
			// });

			// getATTerminals({
			//   callback: (result: any) => {
			//     // console.log("Result from AT terminals is----", result)
			//     if (result && result.data && result.data.success && result.data.data)
			//       this.props.setATTerminals(result.data.data)
			//   }
			// })

			// getSeatTypes({
			//   callback: (result: any) => {
			//     if (result && result.data && result.data.success && result.data.data)
			//       this.props.setSeatTypes(result.data.data)
			//   }
			// })

			// getATBlockTime({
			//   callback: (result: any) => {
			//     if (result && result.data && result.data.success && result.data.data)
			//       this.props.setATBlockTime(result.data.data)
			//   }
			// })
	};

	editProfile = () => {
			this.props.history.push("/modify-profile");
			menuController.close();
	};
	goToLogin = () => {
			this.props.history.push("/login-options");
			menuController.close();
	};

	// handleDropdownClick = (index: any) => {
	//   this.setState({}(index === activeDropdown ? null : index);
	// };

	handleDropdownClick = (index: any) => {
			this.setState({
					showDropDown: index === this.state.showDropDown ? null : index,
			});
	};

	getSideMenuLinks = (operator: any) => {
			const themeType = operator && operator.theme_type;
			let sideMenuLinks: any = [];
			if (themeType === 2 || themeType === 8 || themeType === 9) {
					if (operator.operator_name === "cormarbus") {
							sideMenuLinks = [
									{ label: "Compra tu pasaje", url: "/" },
									{ label: "Políticas de privacidad", url: "/privacy-policy" },
									{ label: "Contacto", url: "/contact" },
							];
					}
					if (operator.operator_name === "lineaazul") {
							sideMenuLinks.push(
									// { label: "Compra tu pasaje", url: "/" },
									// { label: "Quiénes somos", url: "/about us" },
									// { label: "Oficinas", url: "/office" },
									// { label: "Galería", url: "/gallery" },
									// { label: "Términos y condiciones", url: "/terms" },
									{ label: "Contacto", url: "/contact" },
							);
							return sideMenuLinks;
					}
					if (operator.operator_name === "santarosa") {
						sideMenuLinks.push(
							    { label: "Mi cuenta", url: "/my-account" },
                                { label: "Servicos especiales", url: "/santarosa-special-services" },
								{ label: "Trabaja con nosotros", url: "/santarosa-work-with-us" },
								{ label: "Términos y condiciones", url: "/terms" },
								{ label: "Políticas de privacidad", url: "/sanatarosa-privacy-policy" },
								{ label: "Contacto", url: "/santarosa-contact" },
						);
						return sideMenuLinks;
				}
				if (operator.operator_name === "buseslafit") {
					sideMenuLinks.push(
										// { label: "Mi cuenta", url: "/my-account" },
										// { label: "La empresa", url: "/about-us" },
							{ label: "Términos y condiciones", url: "/terms" ,src: "/assets/icons/menu/icon_info.png"},
							{ label: "Políticas de privacidad", url: "/buseslafit-privacy-policy", src:"/assets/icons/menu/icon_privacy.png" },
							{ label: "Contacto", url: "/buseslafit-contact",src:"/assets/icons/menu/icon_contact.png" },
					);
					return sideMenuLinks;
			}
					if (operator.operator_name === "biaggini") {
							sideMenuLinks.push(
									// { label: "Compra tu pasaje", url: "/" },
									{ label: "Quiénes somos", url: "/about us" },
									{ label: "Oficinas", url: "/office" },
									{ label: "Galería", url: "/gallery" },
									{ label: "Términos y condiciones", url: "/terms" },
									{ label: "Contacto", url: "/contact" },
							);
							return sideMenuLinks;
					} else {
							// sideMenuLinks = [
							// 		{ label: "Compra tu pasaje", url: "/" },
							// ];
							if (operator.operator_name !== "tarapaca") {
									sideMenuLinks.push({ label: "Compra tu pasaje", url: "/" });
							}

							if (operator.operator_name === "tarapaca") {
									sideMenuLinks.push(
											{ label: "Servicios", url: "/tarapaca-services" },
											{ label: "Nuestra empresa", url: "/about-us" },
											{ label: "Oficinas", url: "/office" },
											{ label: "Galería", url: "/gallery" },
											{ label: "Contacto", url: "/contact" },
									);
									return sideMenuLinks;
							} else {
									sideMenuLinks.push({ label: "Contacto", url: "/contact" });
							}
					}
			} else if (themeType === 7) {
					return themeSevenHeaderLinks;
			} else if (themeType === 6) {
					sideMenuLinks = [
							{ label: "Compra tu pasaje", url: "/" },
							{ label: "Nuestra flota", url: "/fleet" },
							{ label: "Quiénes somos", url: "/sanandres-about" },
							{ label: "Seguridad a bordo", url: "/safety" },

							{
									label: "Servicios",
									url: "/inter-regional-travel",
							},
							{
									label: "Contacto",
									url: "/contact",
							},
					];
			} else if (operator.operator_name === "busnorte") {
					sideMenuLinks = [
							{ label: "Nuestra flota", url: "/norte-flota" },
							{ label: "Quiénes somos", url: "/about-us" },
							{ label: "Oficinas", url: "/norte-office" },
							{
									label: "Contacto",
									url: "/contact",
							},
					];
					return sideMenuLinks;
			} else if (operator.operator_name === "arzola") {
					sideMenuLinks.push(
							{ label: "Compra tu pasaje", url: "/" },
							{ label: "La Empressa", url: "/la-empresa" },
							{
									label: "Contacto",
									url: "/arzola-contact",
							},
							{ label: "Términos y condiciones", url: "/arzola-terms" },
					);
					return sideMenuLinks;
			}
			// else if (operator.operator_name === "ivergrama") {
			//   sideMenuLinks = [
			//     { label: "Nuestra flota", url: "/norte-flota" },
			//     { label: "Quiénes somos", url: "/about-ivergrama" },
			//     { label: "Oficinas", url: "/norte-office" },
			//     {
			//       label: "Contacto",
			//       url: "/ivergrama-contact",
			//     },
			//   ];
			//   return sideMenuLinks;
			// }
		else if (operator.operator_name === "losnavegadores") {
				sideMenuLinks.push(
						{ label: "Términos y condiciones", url: "/terms" ,src: "/assets/icons/menu/icon_info.png"},
						{ label: "Políticas de privacidad", url: "/privacy-policy", src:"/assets/icons/menu/icon_privacy.png" },
						{ label: "Contacto", url: "/contact",src:"/assets/icons/menu/icon_contact.png" },
				);
				return sideMenuLinks;
		}

		else if ( operator.operator_name === "basetorres") {
			sideMenuLinks.push(
				    {label: "Compra tu pasaje", url: "/", src: "/assets/torres/General/buy-icon.png"},
					{label: "Quiénes somos", url: "/about-us", src: "/assets/torres/General/about-icon.png"},
					{label: "Servicios", url: "/torres-services", src: "/assets/torres/General/service-icon.png"},
					{ label: "Preguntas frecuentes", url: "/torres-faq", src: "/assets/torres/General/faq-icon.png" },
					{ label: "Términos y condiciones", url: "/torres-terms" ,src: "/assets/icons/menu/icon_info.png"},
					{ label: "Contacto", url: "/torres-contact",src:"/assets/icons/menu/icon_contact.png" },
			);
			return sideMenuLinks;

		}
			else {
					sideMenuLinks =
							operator &&
							operator.header_links.length > 0 &&
							operator.header_links.map((val: any, index: any) => {
									return {
											label: Object.keys(val),
											url: Object.values(val)[0],
									};
							});
					return sideMenuLinks;
			}
	};

	render() {
			const { appPages, showHideContactModal, data } = this.props;

			console.log("Menu Items", this.props);
			return data.operator &&
					data.operator.operator_name === "pullmansanandres" ? null : (
					<IonMenu
							disabled={this.props.data.thirdPartyApp}
							contentId="main"
							type="overlay"
							className="split-menu-custom"
					>
							<IonContent>
									<div className="menu-container-div">
											{this.props.data.operator.operator_name === "centropuerto" && (
													<a href="/">
															<img
																	style={{
																			paddingLeft: "20px",
																			paddingTop: "20px",
																			paddingBottom: "30px",
																	}}
																	src={getIcons("HeaderLogowhite")}
																	alt=""
																	id="logo"
															/>
													</a>
											)}
											<div className="menu-item-list ">
													{this.props.data.operator.operator_name === "centropuerto" &&
															themeElevenHeaderLinks.map((item, index) => {
																	return (
																			<div className="side-menu-item font16 pb-20">
																					<div className="menu-centro">
																							<div
																									className="pl-10"
																									onClick={() => this.handleDropdownClick(index)}
																							>
																									<a href={item.url}>
																											{/* <img className="welcome-image" src={item.icon} />
																									<span>{item.label}</span> */}
																											<div className="d-flex align-center">
																													<div>
																															<img
																																	className="welcome-image"
																																	src={item.icon}
																															/>
																													</div>
																													<div className="pl-5">{item.label}</div>
																											</div>
																									</a>
																									{item.downarrow && (
																											<img
																													className="welcome-image-dropdown"
																													src={item.downarrow}
																											/>
																									)}
																							</div>
																					</div>
																					<div className="dropdown-content">
																							{this.state.showDropDown === index &&
																									item.dropdown?.map(item => {
																											console.log("ajnsjandjadn", item);
																											return (
																													<div className="drop-menu active">
																															<span>
																																	<a href={item.url}>{item.label}</a>
																															</span>
																													</div>
																											);
																									})}
																					</div>
																			</div>
																	);
															})}
											</div>

											{this.props.data.operator &&
													this.props.data.operator.has_user_account && (
															<div
																	className="welcome-container font10"
																	style={{ marginTop: 20 }}
															>
																	{CommonService.isLoggedIn(this.props.data.loginData) ? (
																			<div className="welcome-image-container">
																					{this.props.data.loginData.image_link ? (
																							<div
																									className="welcome-image-circle"
																									style={{
																											backgroundImage: `url(${
																													this.props.data.loginData.image_link ||
																													// "/assets/carpool/camera-add-circle.png"
																													getIcons("camera")
																											})`,
																									}}
																							></div>
																					) : (
																							<img
																									src={getIcons("camera")}
																									alt=""
																									className="size55"
																									style={{ width: "55px", height: "55px" }}
																							/>
																							// <div className="camera-add-circle home-icon-circular-sprite size55"></div>
																					)}
																					<div className="user-name-container">
																							<div className="display-flex">
																									<PBText
																											fontColor={"dark-font"}
																											fontStyle={
																													this.props.data.operator &&
																													this.props.data.operator.operator_name ===
																															"busnorte"
																															? " new-bold-font"
																															: "bold-font"
																											}
																											fontSize={"font16"}
																									>
																											¡Hola&nbsp;
																									</PBText>
																									<PBText dark={true} bold={true} fontSize={"font16"}>
																											{this.props.data.loginData.name}!
																									</PBText>
																							</div>

																							{this.props.data.loginData ? (
																									<div
																											className="display-flex"
																											onClick={this.editProfile}
																									>
																											<PBText
																													fontSize={"font13"}
																													fontStyle={
																															this.props.data.operator &&
																															this.props.data.operator.operator_name ===
																																	"busnorte"
																																	? " new-bold-font"
																																	: "bold-font"
																													}
																											>
																													Modifica tu perfil
																											</PBText>
																											<img
																													height={16}
																													width={16}
																													style={{ marginLeft: 6 }}
																													src={"/assets/carpool/sidemenu/pencil.png"}
																													alt=""
																											/>
																									</div>
																							) : null}
																					</div>
																			</div>
																	) : (
																			<div className="welcome-image-container">
																					{this.props.data.operator &&
																					this.props.data.operator.operator_name === "busnorte" ? (
																							<div
																									onClick={this.goToLogin}
																									// className="camera-add-circle home-icon-circular-sprite size55"
																							>
																									<img
																											src={getIcons("nortecamera")}
																											alt=""
																											className="size55"
																											style={{ width: "55px", height: "50px" }}
																									/>
																							</div>
																					) : (
																							<div
																									onClick={this.goToLogin}
																									// className="camera-add-circle home-icon-circular-sprite size55"
																							>
																									<img
																											src={getIcons("camera")}
																											alt=""
																											className="size55"
																											style={{ width: "55px", height: "55px" }}
																									/>
																							</div>
																					)}
																					<div className="user-name-container">
																							<div className="display-flex">
																									<PBText
																											fontColor={"dark-font"}
																											fontStyle={"bold-font"}
																											fontSize={"font16"}
																									>
																											¡Hola!
																									</PBText>
																							</div>
																					</div>
																			</div>
																	)}

																	{/* { 
															CommonService.isLoggedIn(props.loginData) 
															?<View style={styles.welcomeTextContainer}><PBText style={styles.welcomeText}>{props.welcomeText}</PBText></View>
															:null
											} */}
															</div>
													)}

											{/* <div>
															{
																	<div className="welcome-image-container">
																			<img className="welcome-image" height={100} src={'/assets/images/pb_logo_circle.png'} />
																	</div>
															}
													</div> 
											*/}
											{/* 
											<div
													className='pink-box'
													style={{
															backgroundColor:
																	this.props.data.operator &&
																	this.props.data.operator.color_codes.primary_color,
													}}
													onClick={this.goToLogin}
											></div> */}
											{this.props.data.operator &&
													this.props.data.operator.has_user_account && this.props.data.operator.operator_name !== "santarosa"  &&
													// CommonService.isLoggedIn(this.props.data.loginData) && 
													(
															// {this.props.data.operator &&
															//   this.props.data.operator.has_user_account && (
															<IonList style={{ marginBottom: "-3px" }}>
																	{loginMenuItems.map((appPage: any, index: any) => {
																			return (
																					<IonMenuToggle key={index} autoHide={false}>
																							<IonItem
																									lines="none"
																									className="side-menu-item font16"
																									detail={false}
																									routerLink={
																											appPage.id == 2
																													? CommonService.isLoggedIn(
																																	this.props.data.loginData,
																															)
																															? appPage.url
																															: "/login-options"
																													: appPage.url
																									}
																									routerDirection="none"
																									// onClick={() => {
																									//   this.props.data.operator ?
																									//     window.open(`${ this.props.data.operator.domain_url }#contact`, '_parent') :
																									//     showHideContactModal(true)
																									// }}
																							>
																									<IonLabel
																											className={`side-menu-label font16 ${
																													this.props.data.operator.operator_name ===
																													"busnorte"
																															? "side-menu-new-bold-font"
																															: this.props.data.operator.operator_name ===
																																			"aeroquinta" ||
																																	this.props.data.operator.operator_name ===
																																			"chilebus" ||
																																	this.props.data.operator.operator_name ===
																																			"tarapaca" ||
																																	this.props.data.operator.operator_name ===
																																			"jeldres" ||
																																	this.props.data.operator.operator_name ===
																																			"biaggini" ||
																																	this.props.data.operator.operator_name ===
																																			"lineaazul" ||
																																	this.props.data.operator.operator_name === 
																																	"buseslafit"  ||
																																	this.props.data.operator.operator_name ===
																																	"losnavegadores" ||
																																	this.props.data.operator.operator_name ===
																																	"basetorres"
																															? "newdisplay"
																															: ""
																											}`}
																									>

																										{/* Render the icon for each menu item */}
                                                                                                         {appPage.src &&  this.props.data.operator.operator_name === "buseslafit" || this.props.data.operator.operator_name === "losnavegadores" || this.props.data.operator.operator_name === "basetorres" ?(


                                                                                                                <img
                                                                                                                 src={appPage.src}
                                                                                                                 alt={`${appPage.name} icon`}
                                                                                                                //  className="menu-item-icon"
																												 style={{width: "20px", height: "20px"}}
                                                                                                                 />
                                                                                                            ):null}
																											{ this.props.data.operator.operator_name === "buseslafit" || this.props.data.operator.operator_name === "losnavegadores" || this.props.data.operator.operator_name === "basetorres" ?
																											(<span style={{ marginRight: "auto" ,marginLeft:10}}>
																												{appPage.name}
																											</span>):(
																												<span>
																													{appPage.name}
																												</span>
																											)}
																											{this.props.data.operator.operator_name ===
																											"aeroquinta" || this.props.data.operator.operator_name ===
																											"buseslafit" || this.props.data.operator.operator_name === "losnavegadores" ||
																											this.props.data.operator.operator_name === "basetorres" ? (
																													<img
																															src="/assets/aeroquinta/arrow-side-menu.svg"
																															className="newimg"
																													></img>
																											) : this.props.data.operator.operator_name ===
																													"chilebus" ? (
																													<img src="/assets/chilebus/arrow.svg"></img>
																											) : this.props.data.operator.operator_name ===
																													"tarapaca" ? (
																													<img src="/assets/chilebus/arrow.svg"></img>
																											) : this.props.data.operator.operator_name ===
																											"jeldres" ? (
																											<img src="/assets/chilebus/arrow.svg"></img>
																									) : this.props.data.operator.operator_name ===
																													"biaggini" ? (
																													<img src="/assets/chilebus/arrow.svg"></img>
																											) : this.props.data.operator.operator_name ===
																													"lineaazul" ? (
																													<img src="/assets/lineaazul/general/next-arrow.svg"></img>
																											) : (
																													""
																											)}
																									</IonLabel>
																							</IonItem>
																					</IonMenuToggle>
																			);
																	})}
															</IonList>
													)}

											<IonList 
											style={{
											marginTop: !data.operator.has_user_account  ? 80 : 0
										}}
											>
													{this.getSideMenuLinks(data.operator) &&
															this.getSideMenuLinks(data.operator).length > 0 &&
															this.getSideMenuLinks(data.operator).map(
																	(appPage: any, index: any) => {
																			return (
																					<IonMenuToggle key={index} autoHide={false}>
																							{/* {appPage.id == 4 ?
																					<IonItem
																							lines="none"
																							className="side-menu-item font16"
																							routerLink={appPage.url}
																							routerDirection="none"
																					>
																							{/* <div className={"home-icon-sprite " + appPage.icon}></div> */}
																							{/* <IonLabel className="side-menu-label font15">
																									{appPage.title}
																							</IonLabel>
																					</IonItem> : null */}
																							{/* // } */}

																							{/* {appPage.id == 9 ? */}
																							<IonItem
																									lines="none"
																									className="side-menu-item font16"
																									routerLink={appPage.url}
																									routerDirection="none"
																									detail={false}
																									// onClick={() => {
																									//   this.props.data.operator ?
																									//     window.open(`${ this.props.data.operator.domain_url }#contact`, '_parent') :
																									//     showHideContactModal(true)
																									// }}
																							>
																									{/* <div className={"home-icon-sprite " + appPage.icon}></div> */}
																									<IonLabel
																											className={`side-menu-label font16 ${
																													this.props.data.operator.operator_name ===
																													"busnorte"
																															? "side-menu-new-bold-font"
																															: this.props.data.operator.operator_name ===
																																			"aeroquinta" ||
																																	this.props.data.operator.operator_name ===
																																			"chilebus" ||
																																	this.props.data.operator.operator_name ===
																																			"tarapaca" ||
																																	this.props.data.operator.operator_name ===
																																			"jeldres" ||
																																	this.props.data.operator.operator_name ===
																																			"biaggini" ||
																																	this.props.data.operator.operator_name ===
																																			"lineaazul" ||
																																			this.props.data.operator.operator_name ===
																																			"buseslafit"  ||
																																	this.props.data.operator.operator_name ===
																																			"losnavegadores" ||
																																	this.props.data.operator.operator_name ===
																																			"basetorres"
																															? "newdisplay"
																															: ""
																											}`}
																									>
																											{/* Render the icon for each menu item */}
																											{appPage.src &&  this.props.data.operator.operator_name === "buseslafit" || this.props.data.operator.operator_name === "losnavegadores" || this.props.data.operator.operator_name === "basetorres" ?(

																												<img
																												 src={appPage.src}
																												 alt={`${appPage.name} icon`}
																												//  className="menu-item-icon"
																												 style={{width: "20px", height: appPage.label === "Compra tu pasaje"?"12px": "20px"}}
																												 />
																											):null}
																											{ this.props.data.operator.operator_name === "buseslafit" || this.props.data.operator.operator_name === "losnavegadores" || this.props.data.operator.operator_name === "basetorres"?

																											(<span style={{ marginRight: "auto" ,marginLeft:10}}>
																												{appPage.label}
																											</span>):(
																												<span>
																													{appPage.label}
																												</span>
																											)}
																												
																											{this.props.data.operator.operator_name ===
																											"aeroquinta" || this.props.data.operator.operator_name ===
																											"buseslafit" || this.props.data.operator.operator_name === "losnavegadores" ||  this.props.data.operator.operator_name === "basetorres" ? (
																													<img
																															src="/assets/aeroquinta/arrow-side-menu.svg"
																															className="newimg"
																													></img>
																											) : this.props.data.operator.operator_name ===
																											"jeldres" ? (
																											<img src="/assets/aeroquinta/arrow-side-menu.svg">
																											</img>
																									): this.props.data.operator.operator_name ===
																													"chilebus" ? (
																													<img src="/assets/chilebus/arrow.svg"></img>
																											) : this.props.data.operator.operator_name ===
																													"tarapaca" ? (
																													<img src="/assets/chilebus/arrow.svg"></img>
																											) : this.props.data.operator.operator_name ===
																													"biaggini" ? (
																													<img src="/assets/chilebus/arrow.svg"></img>
																											) : this.props.data.operator.operator_name ===
																													"lineaazul" ? (
																													<img src="/assets/lineaazul/general/next-arrow.svg"></img>
																											) :  (
																													""
																											)}
																									</IonLabel>
																							</IonItem>
																							{/* : null */}
																							{/* } */}
																							{/* {appPage.id != 9 ? (
																					(appPage.id == 1 ||
																							appPage.id == 13 ||
																							appPage.id == 5 ||
																							appPage.id == 2 ||
																							appPage.id == 12) &&
																							!this.props.data.loginData ? (
																							<IonItem
																									lines="none"
																									className="side-menu-item font16"
																									routerLink="/login-options"
																									routerDirection="none"
																							> */}
																							{/* <img src={appPage.src} alt="" /> */}
																							{/* <div className={"home-icon-sprite " + appPage.icon}></div>
																									<IonLabel className="side-menu-label font15">
																											{appPage.title}
																									</IonLabel>
																							</IonItem>
																					) : appPage.id != 1 ||
																							!this.props.data.loginData ||
																							this.props.data.loginData.identity_type == 7 ? (
																							<IonItem
																									lines="none"
																									className="side-menu-item font16"
																									routerLink={appPage.url}
																									routerDirection="none"
																							> */}
																							{/* <img src={appPage.src} alt="" /> */}
																							{/* <div className={"home-icon-sprite " + appPage.icon}></div>
																									<IonLabel className="side-menu-label font15">
																											{appPage.title}
																									</IonLabel>
																							</IonItem>
																					) : null
																			) : appPage.id == 9 ? (
																					<IonItem
																							lines="none"
																							className="side-menu-item font16"
																							onClick={() => showHideContactModal(true)}
																					> */}
																							{/* <img src={appPage.src} alt="" /> */}
																							{/* <div className={"home-icon-sprite " + appPage.icon}></div>
																							<IonLabel className="side-menu-label font15">
																									{appPage.title}
																							</IonLabel>
																					</IonItem>
																			) : null} */}
																					</IonMenuToggle>
																			);
																	},
															)}
											</IonList>
									</div>
							</IonContent>
					</IonMenu>
			);
	}
}

export default connectData()(withRouter(Menu));