import React, { PureComponent } from "react";
// import {
//   View,
// } from 'react-native';
import { connectData } from "../../redux";
// import EStyleSheet from 'react-native-extended-stylesheet';
import DateService from "../../services/dateService";
import {
  ServiceListItemContainer,
  TravelDetailsHeader,
  SortStrip,
  BusLoader,
  PBText,
  PBModal,
} from "../../components";
// import { SERVICE_DETAILS_SCREEN } from '../../navigation/Screens';
import { CommonService, DateSerivce, storageService } from "../../services";
import {
  IonPage,
  IonContent,
  withIonLifeCycle,
  IonRefresher,
  IonRefresherContent,
  IonToast,
} from "@ionic/react";
import TopStripBackHome from "../../components/TopStripBackHome";
import { Link } from "react-router-dom";
import { setupConfig } from "@ionic/react";
import KuposHeader from "../../components/KuposHeader";

// const operator = JSON.parse(localStorage.getItem('op'));

class ServiceListScreenV2 extends PureComponent {
  orderByField;
  order;
  orderBy;
  routesFull;
  firstLoad = true;
  capsuleMenuIcons = [
    {
      icon: "/assets/icons/service-sort/icon-clock-line.png",
      iconActive: "/assets/icons/service-sort/icon-clock-line-pink.png",
      label: "Horarios",
      sortFeild: "dep_time",
    },
    {
      icon: "/assets/icons/service-sort/icon-bus-line.png",
      iconActive: "/assets/icons/service-sort/icon-bus-line-pink.png",
      label: "Empresas",
      sortFeild: "operator_service_name",
    },
    {
      icon: "/assets/icons/service-sort/icon-piggybank-line.png",
      iconActive: "/assets/icons/service-sort/icon-piggybank-line-pink.png",
      label: "Precio",
      sortFeild: "fare_str",
    },
    {
      icon: "/assets/icons/service-sort/icon-star-line.png",
      iconActive: "/assets/icons/service-sort/icon-star-line-pink.png",
      label: "Ranking",
      sortFeild: "rating",
    },

    {
      icon: "/assets/icons/service-sort/icon-chronometer-line.png",
      iconActive: "/assets/icons/service-sort/icon-chronometer-line-pink.png",
      label: "Rápido",
      sortFeild: "duration",
    },
  ];

  constructor(props) {
    super(props);
    console.log("this.props.match.params const", this.props.match.params);
    this.state = {
      getServiceListPending: true,
      servicesLoaded: false,
      routes: null,
      soldRoutes: null,
      orderBy: "DESC",
      orderByField: "dep_time",
      animationMargin: 0,
      activeSortTab: 0,
    };
    this.retryTimeOut = null
    // CommonService.bindNav(this);

    setupConfig({
      swipeBackEnabled: false, // also prevent swiping back on either platform
      hardwareBackButton: false, // this is what you need (android only)
    });
  }

  navigationButtonPressed({ buttonId }) {
    CommonService.rightButonHome(
      buttonId,
      this.props.componentId,
      this.props.returnDateChange
    );
  }

  componentDidMount() {
    this.setState({ orderBy: "DESC" });
  }

  ionViewWillEnter() {
    this.setState({ orderBy: "DESC" });

    if (
      this.props.data &&
      this.props.data.selectedOrigin &&
      this.props.data.selectedDestination
    ) {
      this.servicesCalled = true;
      this.getServices(this.props.data && this.props.data.selectedOnwardDate);
      console.log(
        "Service List Screen Onward date",
        this.props.data.selectedOnwardDate
      );
    }
    this.props.serviceSelectedOnward({
      coachLayoutJson: null,
      originStageDetails: null,
      destinationStageDetails: null,
      boardingStage: null,
      droppingStage: null,
      route: null,
    });
    this.props.serviceSelectedReturn({
      coachLayoutJson: null,
      originStageDetails: null,
      destinationStageDetails: null,
      boardingStage: null,
      droppingStage: null,
      route: null,
    });
    this.props.selectSeatOnward({
      totalBookingAmount: 0,
      netBookingAmount: 0,
      discountAmount: 0,
      selectedSeatsToBook: null,
      selectedSeatsNumbers: null,
      selectedSeatTypes: null,
    });
    this.props.setPassengerDetailsOnward([]);
    CommonService.clearCoachLayoutSelectedSeats(
      this.props.data && this.props.data.selectedServiceOnward,
      this.props.serviceSelectedOnward
    );
    // alert('hell')
  }

  // componentDidUpdate() {
  //   const {
  //     origin,
  //     dest,
  //     dateOnward,
  //     dateReturn,
  //     step,
  //   } = this.props.match.params;
  //   if (!this.servicesCalled && this.props.data.citiesUnique) {
  //     let checkOriginCity = origin
  //       .split("--")
  //       .join("+")
  //       .split("-")
  //       .join(" ")
  //       .split("+")
  //       .join("-")
  //       .toLowerCase();
  //     let checkDestCity = dest
  //       .split("--")
  //       .join("+")
  //       .split("-")
  //       .join(" ")
  //       .split("+")
  //       .join("-")
  //       .toLowerCase();
  //     let filterCities = this.props.data.citiesUnique.filter((it) => {
  //       return (
  //         it[0].toLowerCase() == checkOriginCity ||
  //         it[0].toLowerCase() == checkDestCity
  //       );
  //     });
  //     if (
  //       filterCities &&
  //       !this.props.data.selectedOrigin &&
  //       !this.props.data.selectedDestination
  //     ) {
  //       this.setState({ orderBy: "DESC" });
  //       let originCity = filterCities.find((it) => {
  //         return it[0].toLowerCase() == checkOriginCity;
  //       });
  //       let selectedCityOrigin = {
  //         id: originCity ? originCity[1] : null,
  //         name: originCity ? originCity[0].split(",")[0] : null,
  //         country: originCity ? originCity[0].split(",")[1] : null,
  //         full_name: originCity ? originCity[0] : null,
  //       };
  //       this.props.selectOrigin(selectedCityOrigin);

  //       let destCity = filterCities.find((it) => {
  //         return it[0].toLowerCase() == checkDestCity;
  //       });
  //       let selectedCityDest = {
  //         id: destCity ? destCity[1] : null,
  //         name: destCity ? destCity[0].split(",")[0] : null,
  //         country: destCity ? destCity[0].split(",")[1] : null,
  //         full_name: destCity ? destCity[0] : null,
  //       };
  //       this.props.selectDestination(selectedCityDest);
  //       let onwardDateFormatted = DateSerivce.changeDateFormat(
  //         dateOnward,
  //         "dd-mm-yyyy",
  //         "yyyy-mm-dd"
  //       );
  //       this.props.onwardDateChange(dateOnward);
  //       this.props.returnDateChange(dateReturn);
  //       this.servicesCalled = true;
  //       this.getServices(dateOnward, selectedCityOrigin, selectedCityDest);
  //     } else {
  //     }
  //   }
  // }

  getServices = (
    newDate,
    origin = null,
    dest = null,
    event = null,
    hideLoader,
    retryCount = 0
  ) => {
    let travelId =
      this.props &&
      this.props.data &&
      this.props.data.operator &&
      this.props.data.operator.travel_ids;

    let path;

    this.routesFull = null;
    path =
      "origin_id=" + (origin ? origin.id : this.props.data.selectedOrigin.id);
    path +=
      "&destination_id=" +
      (dest ? dest.id : this.props.data.selectedDestination.id);
    path +=
      "&travel_date=" +
      DateSerivce.changeDateFormat(newDate, "dd-mm-yyyy", "yyyy-mm-dd");
    path +=
      "&r_travel_date=" +
      DateSerivce.changeDateFormat(
        this.props.data.selectedReturnDate,
        "dd-mm-yyyy",
        "yyyy-mm-dd"
      );
    path += `&show_only_available_services=false&show_injourney_services=true`;
    path += `&is_round_trip=${
      this.props.data.selectedReturnDate ? true : false
    }&is_roundtrip_return=false&retry_count=${retryCount}&travel_ids=${travelId}`;


    let is_mot = sessionStorage.getItem("is_mot");
    if (is_mot === "true") {
      is_mot = true;
      path += `&is_mot=${is_mot}`;
    }
    // CommonService.isTrain()
    //   ? (path =
    //       "origin_id=1841&destination_id=1986&travel_date=2020-04-30&show_only_available_services=false&show_injourney_services=true")
    //   : (path = path);

    const operator =
      JSON.parse(localStorage.getItem("op")) || this.props.data.operator;

    this.props.getServicesV3({
      path: path,
      callback: (result) => {
        if (result?.token) {
          let data = CommonService.decryptAndParse(result.token, "@kup" + "os@");
          result = data;
        }
        this.retryFlag = result.retryFlag;
        this.waitTime = result.waitTime;
        if (!this.serviceLoaded) {
          this.maxRetryCount = result.maxRetryCount;
          this.serviceLoaded = true;
        }
        if (
          Array.isArray(result.body && result.body.onward_schedules) &&
          result.body &&
          result.body.onward_schedules.length
        ) {
          this.maxRetryCount = 0;
        }
        if (this.retryFlag && this.maxRetryCount > 0) {
          this.maxRetryCount--;
          retryCount++;
          this.retryTimeOut = setTimeout(() => {
            this.getServices(
              newDate,
              origin,
              dest,
              event,
              hideLoader,
              retryCount
            );
          }, this.waitTime * 1000);
        } else if (
          result &&
          result.code === 400 &&
          result.message.includes("Servicios no encontrados")
        ) {
          this.setState({
            getServiceListPending: false,
            routes: null,
          });
          this.setServices(null);
          CommonService.fullServiceListV2 = null;
        } else {
          this.setState({
            getServiceListPending: false,
          });
          // const onwardService =
          //   result.body &&
          //   result.body.onward_schedules.find(
          //     (s) => s.travel_id === operator.operator_id
          //   );
          if (result.body && result.body.onward_schedules) {
            CommonService.fullServiceListV2 = result.body;
            this.setServices(result.body.onward_schedules, event);
          }
        }
      },
      hideLoader: hideLoader,
    });
  };

  setServices = (services, event = null) => {
    if (event) {
      event.detail.complete();
    }

    this.props.getServicesSuccess({ routes: services, v2: true });
    this.setRoutes();
    this.firstLoad = false;
    this.dateChanged = false;
  };

  setRoutes = () => {
    this.routesFull = this.props.data.serviceList
      ? this.props.data.serviceList.routes
      : null;
    if (this.routesFull) {
      this.saperateRoutesAndSoldRoutes();
    }
  };

  saperateRoutesAndSoldRoutes = () => {
    this.sortBy(this.state.activeSortTab);
  };

  dayChange = (type) => {
    clearTimeout(this.retryTimeOut);
    this.dayChangePressed = true;
    this.serviceLoaded = false;
    this.setState({
      routes: null,
    });
    if (type == 1) {
      if (DateService.getTodayString() == this.props.data.selectedOnwardDate) {
        return;
      }
      this.firstLoad = true;
      this.dateChanged = true;
      this.prevDate();
    } else if (type == 2) {
      this.firstLoad = true;
      this.dateChanged = true;
      this.nextDate();
    }
  };

  /**
   * go to the next date
   */
  nextDate = () => {
    let onwardDateFormatted = DateSerivce.changeDateFormat(
      this.props.data.selectedOnwardDate,
      "dd-mm-yyyy",
      "yyyy-mm-dd"
    );
    let newDate = DateSerivce.changeDateFormat(
      DateService.addDate(onwardDateFormatted, 1),
      "yyyy-mm-dd",
      "dd-mm-yyyy"
    );
    this.props.onwardDateChange(newDate);
    this.getServices(newDate);
  };

  /**
   * go to the previous date
   */
  prevDate = () => {
    let onwardDateFormatted = DateSerivce.changeDateFormat(
      this.props.data.selectedOnwardDate,
      "dd-mm-yyyy",
      "yyyy-mm-dd"
    );
    let newDate = DateSerivce.changeDateFormat(
      DateService.addDate(onwardDateFormatted, -1),
      "yyyy-mm-dd",
      "dd-mm-yyyy"
    );
    this.props.onwardDateChange(newDate);
    this.getServices(newDate);
  };

  sortBy = (i) => {
    let orderBy = i == 1 || i == 2 || i == 4 ? "DESC" : "ASC";
    let sortFeild = this.capsuleMenuIcons[i].sortFeild;
    if (this.dateChanged) {
      orderBy = this.state.orderBy;
    }
    if (this.state.orderByField == sortFeild && !this.dayChangePressed) {
      if (this.state.orderBy === "ASC") {
        orderBy = "DESC";
      } else if (
        this.state.orderBy === "DESC" &&
        (i == 1 || i == 2 || i == 4)
      ) {
        orderBy = "ASC";
      }
    }
    this.dayChangePressed = false;

    let routesFull = this.sortThis(this.routesFull, sortFeild, orderBy);
    let routes = routesFull.filter((it) => {
      return it.available_seats > 0;
    });
    let soldRoutes = routesFull.filter((it) => {
      return it.available_seats <= 0;
    });
    for (let route of soldRoutes) {
      route.soldRoute = true;
    }
    // routes.routes = routesFull;
    this.setState({
      orderByField: sortFeild,
      orderBy: orderBy,
      activeSortTab: i,
      serviceList: routes,
      routes: routes,
      soldRoutes: soldRoutes,
      routesFull: routesFull,
    });
  };

  /**
   * ascending descending method
   */

  isAsc = () => {
    if (this.state.orderBy === "ASC") {
      return true;
    } else {
      return false;
    }
  };

  /**
   * sort method
   */
  sortThis = (arr, orderByValue, orderType = "ASC") => {
    arr.sort((a, b) => {
      if (orderType === "ASC") {
        if (orderByValue == "operator_service_name") {
          if (a[orderByValue] < b[orderByValue]) return 1;
          if (a[orderByValue] > b[orderByValue]) return -1;
          return 0;
        } else if (orderByValue == "dep_time") {
          // return (Date.parse('1970/01/01 ' + a[orderByValue]) - Date.parse('1970/01/01 ' + b[orderByValue]))
          if (a[orderByValue] < b[orderByValue]) return -1;
          if (a[orderByValue] > b[orderByValue]) return 1;
          return 0;
        } else if (orderByValue == "rating") {
          if (a.operator_details[1] < b.operator_details[1]) return 1;
          if (a.operator_details[1] > b.operator_details[1]) return -1;
          return 0;
        } else if (orderByValue == "duration") {
          if (a[orderByValue] < b[orderByValue]) return 1;
          if (a[orderByValue] > b[orderByValue]) return -1;
          return 0;
        } else {
          if (a[orderByValue] && b[orderByValue]) {
            let aValue = a[orderByValue].split(":");
            let bValue = b[orderByValue].split(":");
            if (Number.parseInt(aValue[1]) < Number.parseInt(bValue[1]))
              return -1;
            if (Number.parseInt(aValue[1]) > Number.parseInt(bValue[1]))
              return 1;
          }
          return 0;
        }
      } else {
        if (orderByValue == "operator_service_name") {
          if (a[orderByValue] > b[orderByValue]) return 1;
          if (a[orderByValue] < b[orderByValue]) return -1;
          return 0;
        } else if (orderByValue == "dep_time") {
          // return -(Date.parse('1970/01/01 ' + a[orderByValue]) - Date.parse('1970/01/01 ' + b[orderByValue]))
          if (a[orderByValue] < b[orderByValue]) return 1;
          if (a[orderByValue] > b[orderByValue]) return -1;
          return 0;
        } else if (orderByValue == "rating") {
          if (a.operator_details[1] > b.operator_details[1]) return 1;
          if (a.operator_details[1] < b.operator_details[1]) return -1;
          return 0;
        } else if (orderByValue == "duration") {
          if (a[orderByValue] > b[orderByValue]) return 1;
          if (a[orderByValue] < b[orderByValue]) return -1;
          return 0;
        } else {
          if (a[orderByValue] && b[orderByValue]) {
            let aValue = a[orderByValue].split(":");
            let bValue = b[orderByValue].split(":");
            if (Number.parseInt(aValue[1]) > Number.parseInt(bValue[1]))
              return -1;
            if (Number.parseInt(aValue[1]) < Number.parseInt(bValue[1]))
              return 1;
          }
          return 0;
        }
      }
    });
    return arr;
  };

  checkMidnight = (service) => {
    let originOnward = this.props.data.selectedOrigin.full_name
      .toLowerCase()
      .replace(/-/g, "_")
      .replace(/ /g, "-")
      .replace(/_/g, "--");
    let destOnward = this.props.data.selectedDestination.full_name
      .toLowerCase()
      .replace(/-/g, "_")
      .replace(/ /g, "-")
      .replace(/_/g, "--");

    console.log("originDestination", destOnward);

    if (
      (originOnward.indexOf("argentina") > -1 &&
        destOnward.indexOf("argentina") < 0) ||
      (originOnward.indexOf("argentina") < 0 &&
        destOnward.indexOf("argentina") > -1)
    ) {
      this.setState({
        showInternationalModal: true,
        internationalModalTitle: "¡ Información importante !",
        showButton1: true,
        // buttonText1: this.props.t('RESULTS_PAGE.CHOOSE_ANOTHER_TRIP'),
        onButtonClick1: this.closeModal,
        showButton2: true,
        // buttonText2: this.props.t('HOME.OK_CONTINUE'),
        onButtonClick2: () => {
          this.closeModal();
          this.onServiceSelect(service);
        },
      });
      return;
    }

    if (service.dep_validation_text) {
      this.setState({
        showModal: true,
        modalTitle: "hhhh",
        modalBody: service.dep_validation_text,
        showButton1: true,
        buttonText1: "Escoger otro viaje",
        onButtonClick1: this.closeModal,
        showButton2: true,
        buttonText2: "CONTINUE",
        onButtonClick2: () => {
          this.closeModal();
          this.onServiceSelect(service);
        },
      });
      return;
    } else {
      this.onServiceSelect(service);
    }
  };

  onServiceSelect = (service) => {
    if (service.available_seats <= 0) {
      this.setState({
        showToast1: true,
        toastMessage: "Agotado - Este servicio ya no está disponible",
      });

      setTimeout(() => {
        this.setState({
          showToast1: false,
        });
      }, 3000);
      return false;
    }
    this.getServiceDetails(service.uuid, service, service.multi_stage_service_name);
  };

  getServiceDetails(scheduleId, route, multiStageServiceName) {
    let data = {
      returnTrip: this.props.data.selectedReturnDate ? true : false,
      returnTripSearch: false,
      scheduleId: scheduleId,
						multi_stage_service_name: multiStageServiceName
    };
    this.props.getServiceDetailsV3({
      callback: (success, message) => {
        console.log("response from getServiceDetailsV2----", success, message);
        if (success) {
          this.onServiceDetailsResponse(success, scheduleId, route);
        } else {
          this.setState({
            showToast1: true,
            toastMessage:
              message || "No disponible. Por favor selecciona otro servicio",
          });

          setTimeout(() => {
            this.setState({
              showToast1: false,
            });
          }, 3000);

          this.getServices(
            this.props.data && this.props.data.selectedOnwardDate,
            null,
            null,
            null,
            true
          );
        }
      },
      data: data,
    });
  }

  onServiceDetailsResponse = (success, scheduleId, route) => {
    if (success) {
      this.checkSeatAvailability(
        this.props.data.serviceDetails,
        scheduleId,
        route
      );
    }
  };

  checkSeatAvailability = (serviceDetails, scheduleId, route) => {
    if (serviceDetails) {
      if (serviceDetails.total_available_seats > 0) {
        this.props.serviceSelectedOnward(
          CommonService.setSeatLayout(serviceDetails, route)
        );
        // this.props.history.push(
        //   "/" +
        //     "bus/"+
        //     this.props.data.selectedOrigin.name +
        //     this.props.data.selectedDestination.name+
        //     "/details"
        // );
        // this.props.history.push("/es/pasajes-bus/servicedetails");
        let source = this.props.data.selectedOrigin.full_name
          .toLowerCase()
          .replace(/-/g, "_")
          .replace(/ /g, "-")
          .replace(/_/g, "--");
        let dest = this.props.data.selectedDestination.full_name
          .toLowerCase()
          .replace(/-/g, "_")
          .replace(/ /g, "-")
          .replace(/_/g, "--");
        let link;

        if (!this.props.data.selectedReturnDate) {
          link = `/${
            CommonService.isTrain() ? "es/pasajes-tren" : "es/pasajes-bus"
          }/select-seat/${scheduleId}/${source}/${dest}/${
            this.props.data.selectedOnwardDate
          }`;
        } else {
          link = `/${
            CommonService.isTrain() ? "es/pasajes-tren" : "es/pasajes-bus"
          }/select-seat/${scheduleId}/${source}/${dest}/${
            this.props.data.selectedOnwardDate
          }/${this.props.data.selectedReturnDate}/1`;
        }
        this.props.history.push(link);

        // CommonService.goToScreen(this.props.componentId,SERVICE_DETAILS_SCREEN,'Selecciona tu asiento')
      } else {
        this.setState({
          showToast1: true,
          toastMessage: "Agotado - Este servicio ya no está disponible",
        });

        setTimeout(() => {
          this.setState({
            showToast1: false,
          });
        }, 3000);
        let list = JSON.parse(JSON.stringify(this.props.data.serviceList));
        for (let i in list) {
          if (list[i].id == scheduleId) {
            list[i].available_seats = 0;
            this.props.getServicesSuccess({ routes: list, v2: true });
            break; //Stop this loop, we found it!
          }
        }
        this.setRoutes();
      }
    }
  };

  decideGoBackSituation = () => {
    // console.log("History is-------", this.props);

    if (this.props.location.state) {
      this.props.history.goBack();
    } else {
      this.props.history.push("/");

      setTimeout(() => {
        this.props.history.replace("", null);
        this.props.history.push("/es/pasajes-bus");
        // this.props.history.replace("", null);
      }, 1000);
    }
    // this.props.history.goBack();
  };

  closeModal = () => {
    this.setState({
      showModal: false,
      modalTitle: "",
      modalBody: "",
      onButtonClick1: null,
      showButton2: false,
      buttonText2: null,
      modalIcon: null,
      modalChild: null,
      showCloseIcon: false,
      showInternationalModal: false,
    });
  };

  render() {
    // if(!this.routesFull && this.firstLoad && this.props.data && this.props.data.serviceList && this.props.data.serviceList.routes){
    // this.setRoutes();
    // }/

    return this.props.data &&
      this.props.data.showBusLoader &&
      !this.state.getServiceListPending ? (
      <IonPage>
        <div className="service-list-header">
          <KuposHeader
            title="Selecciona "
            boldTitle=" tu servicio"
            handleBackSubmit={this.decideGoBackSituation}
												handleHomeSubmit={() => {
													// this.props.history.replace("", null);
													this.props.history.push("/");
											}}
          />

          <TravelDetailsHeader
            from={
              this.props.data &&
              this.props.data.selectedOrigin &&
              this.props.data.selectedOrigin.name
            }
            to={
              this.props.data &&
              this.props.data.selectedDestination &&
              this.props.data.selectedDestination.name
            }
            travelDate={DateSerivce.changeDateFormat(
              this.props.data.selectedOnwardDate,
              "dd-mm-yyyy",
              "yyyy-mm-dd"
            )}
            onDayChange={(type) => this.dayChange(type)}
          />
          <SortStrip
            onSort={(sortType) => this.sortBy(sortType)}
            sortType={this.state.orderBy}
            sortFeild={this.state.orderByField}
            capsuleMenuIcons={this.capsuleMenuIcons}
            activeTab={this.state.activeSortTab}
          />
        </div>
        <IonContent className="content-transparent">
          <div
            className="service-list-container"
            // style={styles.serviceListContainer}
          >
            <ServiceListItemContainer
              routes={this.state.routes}
              soldRoutes={this.state.soldRoutes}
              amenities={this.props.data.metaData.amenities}
              onServiceSelect={(service) => this.checkMidnight(service)}
              operator={this.props.data.operator}
            />
          </div>
          <IonRefresher
            slot="fixed"
            onIonRefresh={(event) =>
              this.getServices(
                this.props.data.selectedOnwardDate,
                null,
                null,
                event
              )
            }
          >
            <IonRefresherContent
              pullingText="Desliza para actualizar"
              refreshingSpinner="circles"
              refreshingText="Cargando..."
            ></IonRefresherContent>
          </IonRefresher>

          <IonToast
            isOpen={this.state.showToast1}
            // onDidDismiss={this.setState({ showToast1: false })}
            message={this.state.toastMessage}
            duration={2000}
          />
          <PBModal
            showModal={this.state.showModal}
            bodyText={this.state.modalBody}
            title={this.state.modalTitle}
            bodyText={this.state.modalBody}
            type="alert"
            showButton1={true}
            button1Text={this.state.buttonText1}
            button1Press={
              this.state.onButtonClick1
                ? this.state.onButtonClick1
                : this.closeModal
            }
            showButton2={this.state.showButton2}
            button2Text={this.state.buttonText2}
            button2Press={
              this.state.onButtonClick2
                ? this.state.onButtonClick2
                : this.closeModal
            }
            icon={this.state.modalIcon}
            buttonTextStyle={{ fontSize: 15 }}
            // showCloseIcon={this.state.showCloseIcon}
            // closeIconPress={this.closeModal}
            onHide={this.closeModal}
          />

          <PBModal
            showModal={this.state.showInternationalModal}
            bodyText={""}
            subtitle={"¡ Información importante !"}
            type="alert"
            showButton1={false}
            button1Text={"OK,CONTINUAR"}
            button1Press={
              this.state.onButtonClick1
                ? this.state.onButtonClick1
                : this.closeModal
            }
            showButton2={this.state.showButton2}
            button2Text={"OK,CONTINUAR"}
            button2Press={
              this.state.onButtonClick2
                ? this.state.onButtonClick2
                : this.closeModal
            }
            showCloseIcon={true}
            closeIconPress={this.closeModal}
            icon={"/assets/icons/circular/warning.png"}
            onHide={this.closeModal}
          >
            <div className="internation-modal-body font10">
              <div className="int-item-body">
                <span>
                  Recuerda verificar tu documento de identidad antes de
                  completar tu compra. Para poder viajar, tu documento de
                  identidad debe estar en buen estado y el chip del carnet debe
                  estar funcional. Sólo se puede viajar con los documentos de
                  identidad originales.
                </span>
              </div>
            </div>
          </PBModal>
        </IonContent>
      </IonPage>
    ) : (
      <IonPage>
        {this.props.data.selectedOrigin &&
        this.props.data.selectedDestination ? (
          <BusLoader
            from={
              this.props.data.selectedOrigin &&
              this.props.data.selectedOrigin.name
            }
            to={
              this.props.data.selectedDestination &&
              this.props.data.selectedDestination.name
            }
            travelDate={this.props.data.selectedOnwardDate}
          />
        ) : null}
      </IonPage>
    );
  }
}

const styles = {
  serviceListContainer: {
    flex: 1,
  },
};

export default connectData()(withIonLifeCycle(ServiceListScreenV2));
