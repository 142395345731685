import { IonContent, IonPage } from '@ionic/react';

import { CommonService } from '../../../services';
import HeroSection from '../../common/HeroSection/HeroSection';
import React from 'react';
import SantarosaFooter from './santarosafooter';
import ThemeTwoFooter from './ThemeTwoFooter';
// import ThemeThreeTitle from '../common/ThemeThreeTitle';
// import ThemeTwoFooter from './ThemeTwoFooter';
import ThemeTwoHeader from './ThemeTwoHeader';
import ThemeTwoTitle from './ThemeTwoTitle';
import { connectData } from '../../../redux';
import getIcons from '../../functions/getIcons/getIcons';

const ThemeTwoTnCScreen = (props) => {
	const operator = CommonService.getLocal('op') || props.data.operator;

	const createMarkup = (data) => {
		return { __html: data };
	};

	return (
		<IonPage>
			<IonContent>
				<ThemeTwoHeader />
				{operator && operator.operator_name === 'buseslafit' ? (null) : (<HeroSection

					heroImage={getIcons("Banner")}
					title='Términos y condiciones'
					height={'45vh'}
					description={"Home / Términos y condiciones "}
				/>)}
				{/* <HeroSection
					title='Términos y condiciones'
					height={'45vh'}
					description='Home / Términos y condiciones        '
					heroImage={getIcons('Banner', operator)}
				/> */}
				<div style={{ paddingLeft: 20 }}>
					<ThemeTwoTitle operator={operator} title='Términos y condiciones' />
				</div>
				<div className='new-container mb10 mt10'>
					<div
						dangerouslySetInnerHTML={createMarkup(
							operator.terms ? operator.terms : ''
						)}
						className='lh-1-3  justify-text'
					/>
				</div>
				{operator.operator_name === "santarosa" || operator.operator_name === "buseslafit"?
					
					 <SantarosaFooter operator={operator} />
					:<ThemeTwoFooter operator={operator} />}
			</IonContent>
		</IonPage>
	);
};

export default connectData()(ThemeTwoTnCScreen);
