import { IonContent, IonPage } from "@ionic/react";
import React, { useEffect, useState } from "react";

import Header from "../../common/header/Header";
import SantarosaContactForm from "./SantarosaContactForm";
import SantarosaFooter from "./santarosafooter";
import SantarosaServiceForm from "./SantarosaServiceForm";
import Tarbajaform from "./Tarbajaform";
import ThemeTwoHeader from "./ThemeTwoHeader";
import ThemeTwoTitle from "./ThemeTwoTitle";
import { connectData } from "../../../redux";
import { get } from "http";
import getIcons from "../../functions/getIcons/getIcons";

const LafitContactUsScreen = (props) => {
	const operator = JSON.parse(localStorage.getItem("op"));
    const [activeTab, setActiveTab] = useState("contact");
	


	

	return (
		<IonPage>
			<IonContent>
				<ThemeTwoHeader />
				<div style={{ padding: 20 }}>

					<ThemeTwoTitle title="Contáctanos" operator={operator} />

					<div style={styles.textBlock} >
					
							<div>
								<p>
                                Completa el formulario de contacto con tus datos y tu mensaje.
								</p>

								<div style={styles.infoBlock} className="no-margin-right">
									<img
										src={getIcons("Headphone")}
										alt="phone"
										style={styles.icon}
									/>
									<span><b>Teléfono:</b> {operator && operator.phone_number}</span>
								</div>

								<div style={styles.emailBlock} className="no-margin-right" >
									<img
										src={getIcons("Email")}
										alt="phone"
										style={styles.icon}
									/>
									<span><b>Email:</b> {operator && operator.email}</span>
								</div>
							</div>
						
						
					</div>

                    <div  style={{marginTop: "20px"}}>

					<SantarosaContactForm />

                    </div>



				</div>

				<SantarosaFooter operator={props.data.operator} />
			</IonContent>
		</IonPage>
	);
};

// Styles
const styles = {
	tabContainer: {
		display: "grid",
		gridTemplateColumns: "1fr 1fr 1fr",

	},
	tab: {
		flex: 1,
		textAlign: "center",
		cursor: "pointer",
		border: "none",
		background: "#CCCCCC",
		color: "#000",
	},
	firstTab: {
		borderTopLeftRadius: "5px",
	},
	lastTab: {
		borderTopRightRadius: "5px",
	},
	activeTab: {
		flex: 1,
		textAlign: "center",
		padding: "10px",
		cursor: "pointer",
		border: "none",
		background: "#b52c1f",

		fontWeight: "bold",
		color: "white",
	},
	activeFirstTab: {
		borderTopLeftRadius: "5px",
	},
	activeLastTab: {
		borderTopRightRadius: "5px",
	},
	contentContainer: {
		marginTop: "0px",
	},
	form: {
		display: "flex",
		flexDirection: "column",
		gap: "10px",
	},
	inputGroup: {
		display: "flex",
		gap: "10px",
	},
	input: {
		flex: 1,
		padding: "10px",
		borderRadius: "5px",
		border: "1px solid #ddd",
	},
	textarea: {
		width: "100%",
		padding: "10px",
		borderRadius: "5px",
		border: "1px solid #ddd",
		minHeight: "100px",
	},
	button: {
		backgroundColor: "red",
		color: "#fff",
		border: "none",
		padding: "10px",
		cursor: "pointer",
		fontWeight: "bold",
		borderRadius: "5px",
	},
	textBlock: {
		textAlign: "justify",
	},
	infoBlock: {
		display: "flex",
		alignItems: "center",
		gap: "10px",
		marginTop: "20px",
		marginRight: "20px",
	},
	emailBlock: {
		display: "flex",
		alignItems: "center",
		gap: "10px",

		marginTop: "10px",
		marginRight: "20px",
		marginBottom: "20px",
	},
	icon: {
		height: "30px",
		width: "30px",
	},
};

export default connectData()(LafitContactUsScreen);
