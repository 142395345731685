// import React from "react";

// function ThemeTwoGallery() {
//   const data = [
//     { img: "assets/tarapaca/Gallery/tarapaca-gallery-img-1.png" },
//     { img: "assets/tarapaca/Gallery/tarapaca-gallery-img-2.png" },
//     { img: "assets/tarapaca/Gallery/tarapaca-gallery-img-3.png" },
//     { img: "assets/tarapaca/Gallery/tarapaca-gallery-img-4.png" },
//   ];
//   return (
//     <div style={{ display: "grid", gridTemplateColumns: "1fr 1fr", gap:"10px" , marginTop:"40px"}}>
//       {data.map(item => {
//         return (
//           <div style={{ width: "150px" }}>
//             <img src={item.img} alt="tarapaca-gallery"/>
//           </div>
//         );
//       })}
//     </div>
//   );
// }

// export default ThemeTwoGallery;

import React from "react";

class ThemeTwoGallery extends React.Component {
  constructor(props) {
    super(props);
    const localOperator = JSON.parse(localStorage.getItem("op"));
    this.state = {
      operator: localOperator || props.operator,
    };
  }

  render() {
    const { operator } = this.state;

    if (!operator) {
      return <div>Error: Operator data is missing.</div>;
    }

    const tarapacaImages = [
      { img: "assets/tarapaca/Gallery/tarapaca-gallery-img-1.png" },
      { img: "assets/tarapaca/Gallery/tarapaca-gallery-img-2.png" },
      { img: "assets/tarapaca/Gallery/tarapaca-gallery-img-3.png" },
      { img: "assets/tarapaca/Gallery/tarapaca-gallery-img-4.png" },
    ];

    const biagginiImages = [
      { img: "assets/biaggini/gallery/nuestra-flota-1.webp" },
      { img: "assets/biaggini/gallery/nuestra-flota-2.webp" },
      { img: "assets/biaggini/gallery/nuestra-flota-3.webp" },
      { img: "assets/biaggini/gallery/nuestra-flota-4.webp" },
      { img: "assets/biaggini/gallery/nuestra-flota-5.webp" },
    ];

    const data =
      operator.operator_name === "tarapaca" ? tarapacaImages : biagginiImages;

    return (
      <div
        style={{
          display: "grid",
          gridTemplateColumns: "1fr 1fr",
          gap: "10px",
          marginTop: "40px",
        }}
      >
        {data.map((item, index) => (
          <div key={index} style={{ width: "150px" }}>
            <img src={item.img} alt={`${operator.operator_name}-gallery`} />
          </div>
        ))}
      </div>
    );
  }
}

export default ThemeTwoGallery;
