import "./ThemeTwoContactForm.css";

import {
	CarpoolSuccessFailureModal,
	PBInput,
	PButton,
} from "../../../components";
import React, { useState } from "react";

import { CommonService } from "../../../services";
import { connectData } from "../../../redux";
import getIcons from "../../functions/getIcons/getIcons";

const SantarosaContactForm = (props) => {
	const initialState = {
		name: null,
		phone: null,
		email: null,
		comment: null,
		nameError: false,
		phoneError: false,
		emailError: false,
		commentError: false,
	};
	const [state, setState] = useState(initialState);

	const operator =
		JSON.parse(localStorage.getItem("op")) || props.data.operator;

	const inpChange = (value, type) => {
		if (type == "phone") {
			value = CommonService.phoneNumberFormat(value);
			setState({
				...state,
				[type]: value,
				[type + "Error"]: false,
			});
		} else {
			setState({ ...state, [type]: value, [type + "Error"]: false });
		}
	};

	const validate = () => {
		let errorCount = 0;
		if (!state.name) {
			setState({
				...state,
				nameError: true,
				nameErrorMessage: "Ingresa tu nombre",
			});
			errorCount++;
		} else if (!state.phone) {
			setState({
				...state,
				phoneError: true,
				phoneErrorMessage: "Ingresa tu número de teléfono",
			});
			errorCount++;
		} else if (!state.email) {
			setState({
				...state,
				emailError: true,
				emailErrorMessage: "Ingrese un email valido",
			});
			errorCount++;
		} else if (state.email && !CommonService.isEMailValid(state.email)) {
			setState({
				...state,
				emailError: true,
				emailErrorMessage: "Ingrese un email valido",
			});
			errorCount++;
		} else if (!state.comment) {
			setState({
				...state,
				commentError: true,
				commentErrorMessage: "Inserta tu comentario",
			});
			errorCount++;
		}

		if (errorCount > 0) {
			return false;
		} else {
			return true;
		}
	};

	const onSubmit = () => {
		if (!validate()) {
			return;
		}

		let data = {
			name: state.name,
			email: state.email,
			phone_number: state.phone,
			comments: state.comment,
			operator_id: operator.operator_id,
			operator_name: operator.operator_name,
		};

		const operatorsRequiringLastName = []; // add operator name in Array for which you need last_name.

		if (operatorsRequiringLastName.includes(operator.operator_name) && state.last_name) {
			data.last_name = state.last_name;
		}
		if (operator && (operator.operator_name === "tarapaca" || operator.operator_name === "santamaria" || operator.operator_name === "kennybus" || operator.operator_name === "barriaghisoni" || operator.operator_name === "cormarbus" || operator.operator_name === "serenamar" || operator.operator_name === "jetsur" || operator.operator_name === "viatur" || operator.operator_name === "combarbala" || operator.operator_name === "expresosantacruz" || operator.operator_name === "biaggini" || operator.operator_name === "thaebus" || operator.operator_name === "lineaazul" || operator.operator_name === "santarosa"
			|| operator.operator_name === "buseslafit"
		)) {


			props.opsiteContactInfo({
				callback: (result) => onContactInfoResponse(result),
				data: data,
			});
		}
	};


	const onContactInfoResponse = (res) => {
		if (res && res.data && res.data.message) {
			setState({
				...state,
				showModal: true,
				responseMessage: res.data.message,
				status: res.data.status,
			});
		}
	};



	return (
		operator && (
			<div className="ThemeTwoContactForm bold-text" 
			style={{ marginTop:operator.operator_name === "buseslafit" ? 20:  -30, marginInline: -20 }}>
				<div >

					<div className="contact">
						<div
							className="contact-bottom"
							style={{
								backgroundColor: operator.operator_name === "buseslafit" ?operator.color_codes.button_color
									: operator.color_codes.primary_color ,
								borderRadius:operator.operator_name === "buseslafit" ? "10px":"",
							}}
						>
							<div className="align-contact-inputs">
								<div className="contact-input-item">
									<style>
										{`
                    .ThemeTwoContactForm .contact .contact-bottom .contact-input-label {
                        color: ${operator &&
												operator.color_codes &&
												operator.color_codes.secondary_text_color
												? operator.color_codes.secondary_text_color
												: ""};
                    }
                    `}
									</style>

									<div className="contact-input-label" style={{ fontSize: operator.operator_name === "biaggini" ? "14px" : "inherit" }}>
										Nombre
									</div>

									<PBInput
										placeholder={operator && operator.operator_name !== "biaggini" ? "Nombre" : ""}
										value={state.name}
										onChange={(text) => inpChange(text, "name")}
										error={state.nameError ? true : false}
										errorMessage={state.nameError ? state.nameErrorMessage : ""}
										containerStyle={{
											padding: "0",
											border: 0,
										}}
										inputClass={{
											padding: "5px",
										}}
									/>
								</div>


								<div style={{ display: "flex", flexDirection: "row",marginLeft:-10 }} className="no-flex">
									<div className="contact-input-item">
										<div className="contact-input-label">Email</div>
										<PBInput
											placeholder="Email"
											value={state.email}
											onChange={(text) => inpChange(text, "email")}
											error={state.emailError ? true : false}
											errorMessage={state.emailError ? state.emailErrorMessage : ""}
											containerStyle={{ padding: "0", border: 0 }}
											inputClass={{ padding: "5px" }}
										/>
									</div>
									<div className="contact-input-item">
										<div className="contact-input-label">Teléfono</div>
										<PBInput
											placeholder="Teléfono"
											value={state.phone}
											onChange={(text) => inpChange(text, "phone")}
											error={state.phoneError ? true : false}
											errorMessage={state.phoneError ? state.phoneErrorMessage : ""}
											containerStyle={{ padding: "0", border: 0 }}
											inputClass={{ padding: "5px" }}
										/>
									</div>

								</div>
							</div>


							<div className="contact-input-item" style={{ marginLeft: -10 }}>
								<div className="contact-input-label">Mensaje</div>
								{/* <textarea
										className="text-area font14"
										rows={1}
										placeholder="Mensaje"
										onChange={(event) => inpChange(event.target.value, "comment")}
									/> */}
								<PBInput
									placeholder="Mensaje"
									value={state.comment}
									onChange={(text) => inpChange(text, "comment")}
									error={state.commentError ? true : false}
									errorMessage={state.commentError ? state.commentErrorMessage : ""}
									containerStyle={{ padding: "0", border: 0 }}
									inputClass={{ padding: "5px" }}
								/>
							</div>


							<div
								className="contact-button"
								style={{
									marginLeft: -10,
									marginTop: 10,
									paddingTop: 20,
									color: "red"
								}}
							>

								<PButton
									style={{
										backgroundColor: "#fff",
									}}
									button1FontColor={operator && operator.color_codes && operator.color_codes.primary_color ? operator.color_codes.primary_color : ""}
									title={"ENVIAR"}
									onPress={onSubmit}
								/>


							</div>
						</div>
					</div>

				</div>
				<CarpoolSuccessFailureModal
					showModal={state.showModal}
					success={state.status ? true : false}
					smallIcon={true}
					bodyText={state.responseMessage}
					buttonText={"OK"}
					onButtonPress={() => {
						setState({
							showModal: false,
						});
						props.history.push("/");
					}}
				/>
			</div>
		)
	);
};

export default connectData()(SantarosaContactForm);