import React from 'react';

const ThemeTwoTitle = ({ title, operator }) => {
	return (
		<div>
			<div style={{ textAlign: operator.operator_name === 'santarosa' || operator.operator_name === "buseslafit" 
				? 'left' : 'center', fontSize: '20px' }}><br /><strong>{title}</strong></div>
			{/* {operator.operator_name === 'santarosa'?
    <img src="/assets/santarosa/icons/red-line.png" alt="red-line" style={{ width: '10%', height: '8px' }} />
				:null} */}
			{operator && operator.operator_name === "santarosa" ? (
				<img
					src="/assets/santarosa/icons/red-line.png"
					alt="red-line"
					style={{ width: "10%", height: "5px" }}
				/>
			) : operator && operator.operator_name === "buseslafit" ? (
				<img
					src="/assets/buseslafit/home/line.svg"
					alt="line"
					style={{ width: "10%", height: "5px" }}
				/>
			) : null}
		</div>
	)
}

export default ThemeTwoTitle;
