const LocationIcon = "/assets/tepual/general/LocationIcon.svg";
const PhoneIcon = "/assets/tepual/general/PhoneIcon.svg";
const HeaderLogo = "/assets/tepual/general/Header-BusesTepualLogo.svg";
const FooterLogo = "/assets/tepual/general/Footer-BusesTepualLogo.svg";
const ContactBg = "/assets/tepual/home/home-background.jpg";
// const PopupIcon = '/assets/tepual/gallery/gallery5.jpg';
const BookingActive = "/assets/tepual/home/BuyTicketIcon-Active.svg";
const BookingInActive = "/assets/tepual/home/BuyTicketIcon-Inactive.svg";
const CancelActive = "/assets/tepual/home/CancelTicketIcon-Active.svg";
const CancelInActive = "/assets/tepual/home/CancelTicketIcon-Inactive.svg";
const OriginIcon = "/assets/buseslafit/home/origin.svg";
const DestinationIcon = "/assets/buseslafit/home/destino.svg";
const DestinationIcon2 = "/assets/tepual/home/SearchBar-DestinationIcon.svg";
const CalendarIcon = "assets/buseslafit/home/calendar.svg";
const calendarIcon = "assets/buseslafit/home/calendar.svg";
const SearchIcon = "/assets/tepual/home/SearchBar-SearchIcon.svg";
const checkboxIcon = "/assets/tepual/checkbox.png";
const BottomFooterKuposLogo = "/assets/tepual/Footer-kuposLogo.svg";
const menuDot = '/assets/buseslafit/home/dots.png';
const animation =
	'/assets/santarosa/icons/animation.gif';

const BG = "/assets/buseslafit/home-banners/lafit.webp";

const facebook = "/assets/buseslafit/contact/fb.svg";
const instagram = "/assets/buseslafit/contact/ins.svg";

const pnr = "/assets/buseslafit/lafitpnr.svg";

const email = "/assets/buseslafit/contact/email-icon.svg";
const headphone = "/assets/buseslafit/contact/contact-mail.svg";


const aboutUsImage = "/assets/jeldres/about-us/about-image.webp";

const operatorPay = '/assets/jeldres/home/pay.png'
const footerMail = "/assets/jeldres/footer/footer-mail.svg";
const footerPhone = "/assets/jeldres/footer/footer-phone.svg";
// banners
const destinationBanner = "/assets/jeldres/destinations/destination-main.webp";
const galleryBanner = "/assets/tepual/gallery/gallery-main.jpg";
const contactUsBanner = "/assets/jeldres/contact/contact-us-banner.webp";
const aboutUsBanner =
	"/assets/jeldres/about-us/about-us-banner.webp";
const tncBanner = "/assets/jeldres/tnc/tnc-banner.webp";
const officeBanner = "/assets/jeldres/offices/offices-banner.webp";
// const operatorPay = "/assets/santamaria/SMPaypng.png"
const OfficePhoneIcon = "/assets/tepual/general/officePhone.svg";

// accounts section
const tarjetas = '/assets/santarosa/icons/card.png';;
const viajes = '/assets/santarosa/icons/mytrips.png';
const movimientos = '/assets/santarosa/icons/rechargewallet.png';
const wallet = '/assets/santarosa/icons/rechargewallet.png';
const shareMoney = '/assets/santarosa/icons/transfer-money.png';
const camera = "/assets/santarosa/icons/user-circular.png";
const checkmark = "/assets/orange/IllustratedIcons-CheckMark-Blue.png";
const tick = "/assets/orange/IllustratedIcons-Tick-Blue.png";
const downArrow = "/assets/orange/IllustratedIcons-DropDown-Blue.png";
const loginblue = "/assets/santarosa/icons/user-circular.png";
const recoverpswd = "../../assets/buseslafit/home/login.png";
// const success = '/assets/orange/MyAccount-IllustratedIcons-CheckMark-Orange.svg';
const success =
	"/assets/santarosa/icons/success.png";
const failure =
	"/assets/santarosa/icons/failure.png";
const warning =
	"/assets/santarosa/icons/important.png";
const userIcon = "/assets/santarosa/icons/user-circular.png";
const heartOn = "../assets/icons/heart.png";
const heartOff = "../assets/icons/heart-empty.png";
const registrationSuccess = "/assets/carpool/registration-done-circle.png";


const lafitIcons = [
	{ icon: PhoneIcon, name: "phone" },
	{ icon: LocationIcon, name: "location" },
	{ icon: HeaderLogo, name: "headerLogo" },
	{ icon: FooterLogo, name: "footerLogo" },
	{ icon: ContactBg, name: "ContactBg" },
	{ icon: BookingInActive, name: "BIA" },
	{ icon: BookingActive, name: "BA" },
	{ icon: CancelInActive, name: "CIA" },
	{ icon: CancelActive, name: "CA" },
	{ icon: OriginIcon, name: "Origin" },
	{ icon: DestinationIcon, name: "Destination" },
	{ icon: DestinationIcon2, name: "Destination2" },
	{ icon: CalendarIcon, name: "Calendar" },
	{ icon: calendarIcon, name: "blueCalendar" },
	{ icon: SearchIcon, name: "Search" },
	{ icon: operatorPay, name: 'operatorPay' },
	{ icon: checkboxIcon, name: "Checkbox" },
	{ icon: BG, name: "BG" },
	{ icon: animation, name: "animation" },
	{ icon: pnr, name: "pnr" },
	{ icon: BottomFooterKuposLogo, name: "BottomFooterKuposLogo" },

	{ icon: OfficePhoneIcon, name: "OfficePhoneIcon" },

	{ icon: email, name: "Email" },
	{ icon: headphone, name: "Headphone" },
	{ icon: footerMail, name: "footerMail" },
	{ icon: footerPhone, name: "footerPhone" },
	{ icon: destinationBanner, name: "destinationBanner" },
	{ icon: galleryBanner, name: "galleryBanner" },
	{ icon: contactUsBanner, name: "contactUsBanner" },
	{ icon: aboutUsImage, name: "aboutUsImage" },
	{ icon: aboutUsBanner, name: "aboutUsBanner" },
	{ icon: tncBanner, name: "tncBanner" },
	{ icon: officeBanner, name: "officeBanner" },

	// accounts section
	// { icon: pay, name: "pay" },
	{ icon: facebook, name: "facebook" },
	{ icon: instagram, name: "instagram" },
	// { icon: backimage, name: "backimage" },
	{ icon: tarjetas, name: "tarjetas" },
	{ icon: viajes, name: "viajes" },
	{ icon: movimientos, name: "movimientos" },
	{ icon: wallet, name: "wallet" },
	{ icon: shareMoney, name: "shareMoney" },
	{ icon: camera, name: "camera" },
	{ icon: checkmark, name: "checkmark" },
	{ icon: tick, name: "tick" },
	{ icon: downArrow, name: "downArrow" },
	{ icon: loginblue, name: "loginblue" },
	{ icon: menuDot, name: 'menuDot' },
	{ icon: recoverpswd, name: "recoverpswd" },
	{ icon: success, name: "success" },
	{ icon: failure, name: "failure" },
	{ icon: heartOn, name: "heartOn" },
	{ icon: heartOff, name: "heartOff" },
	{ icon: registrationSuccess, name: "registrationSuccess" },
	{ icon: userIcon, name: "userIcon" },
	{ icon: warning, name: "warning" },
	// { icon: operatorPay, name: 'operatorPay' },
];

export default lafitIcons;
