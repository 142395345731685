import { Helmet } from 'react-helmet';
import React from 'react';
import { connect } from 'react-redux';

const KuposHelmet = ({ originCityDecoded, destCityDecoded }) => {
	// op stands for operator_sites
	const operator = JSON.parse(localStorage.getItem('op'));

	// function getFavicon() {

	//   let hostname = window.location.hostname;

	//   console.log("hostname", hostname)

	//   if (hostname.indexOf('localhost') > -1) {
	//     // return '/favicons/liquine.png';
	//   } else if (hostname.indexOf('buses-tepual') > -1) {
	//     return '';
	//   } else if (hostname.indexOf('andimar') > -1) {
	//     return '';
	//   } else if (hostname.indexOf('buseserbuc') > -1) {
	//     return '';
	//   } else if (hostname.indexOf('bahiaazul') > -1) {
	//     return '';
	//   } else if (hostname.indexOf('ivergrama') > -1) {
	//     return '';
	//   } else if (hostname.indexOf('busessaopaulo') > -1) {
	//     return '';
	//   } else if (hostname.indexOf('pullmansanandres') > -1) {
	//     return '';
	//   } else if (hostname.indexOf('busesfernandez') > -1) {
	//     return '';
	//   } else if (hostname.indexOf('cormarbus') > -1) {
	//     return '';
	//   } else if (hostname.indexOf('kennybus') > -1) {
	//     return '';
	//   } else if (hostname.indexOf('busesserenamar') > -1) {
	//     return '/favicons/serenamar.png';
	//   } else if (hostname.indexOf('busesjetsur') > -1) {
	//     return '';
	//   } else if (hostname.indexOf('pasajesjm') > -1) {
	//     return '/favicons/pasajesjm.png';
	//   } else if (hostname.indexOf('viatur') > -1) {
	//     return '';
	//   } else if (hostname.indexOf('busesbarria') > -1) {
	//     return '/favicons/barriaghisoni.png';
	//   } else if (hostname.indexOf('expresonorte') > -1) {
	//     return '';
	//   } else if (hostname.indexOf('t-rrasschile') > -1) {
	//     return '/favicons/trrass.png';
	//   } else if (hostname.indexOf('expresosantacruz') > -1) {
	//     return '/favicons/expresosantacruz.png';
	//   } else if (hostname.indexOf('busessantacruz') > -1) {
	//     return '';
	//   } else if (hostname.indexOf('sanantonioea') > -1) {
	//     return '';
	//   } else if (hostname.indexOf('pullmandelsur') > -1) {
	//     return '/favicons/pullmandelsur.png';
	//   } else if (hostname.indexOf('travelturspa') > -1) {
	//     return 'traveltur.png';
	//   } else if (hostname.indexOf('busesliquine') > -1) {
	//     return '/favicons/liquine.png';
	//   } else if (hostname.indexOf('tacoha') > -1) {
	//     return '';
	//   } else if (hostname.indexOf('combarbala') > -1) {
	//     return '/favicons/combarbala.png';
	//   } else if (hostname.indexOf('centraltour') > -1) {
	//     return '/favicons/centraltour.png';
	//   } else if (hostname.indexOf('tarapaca') > -1) {
	//     return '';
	//   } else {
	//     return '';
	//   }
	//   return '';
	// }

	function getFavicon(hostname) {
		console.log("fetching favicon", hostname)
		if (hostname.indexOf('localhost') > -1) {
			return 'aeroquinta.png';
		} else if (hostname.indexOf('buses-tepual') > -1) {
			return 'tepual.png';
		} else if (hostname.indexOf('andimar') > -1) {
			return '';
		} else if (hostname.indexOf('buseserbuc') > -1) {
			return 'erbuc.png';
		} else if (hostname.indexOf('bahiaazul') > -1) {
			return 'bahiaazul.png';
		} else if (hostname.indexOf('ivergrama') > -1) {
			return 'ivergrama.png';
		} else if (hostname.indexOf('busessaopaulo') > -1) {
			return 'busessaopaulo.png';
		} else if (hostname.indexOf('pullmansanandres') > -1) {
			return 'pullmansanandres.png';
		} else if (hostname.indexOf('busesfernandez') > -1) {
			return 'busesfernandez.png';
		} else if (hostname.indexOf('cormarbus') > -1) {
			return 'cormar.png';
		} else if (hostname.indexOf('kennybus') > -1) {
			return 'kennybus';
		} else if (hostname.indexOf('busesserenamar') > -1) {
			return 'serenamar.png';
		} else if (hostname.indexOf('busesjetsur') > -1) {
			return 'jetsur.png';
		} else if (hostname.indexOf('pasajesjm') > -1) {
			return 'jm.ico';
		} else if (hostname.indexOf('viatur') > -1) {
			return 'viatur.png';
		} else if (hostname.indexOf('busesbarria') > -1) {
			return 'buses-barria.ico';
		} else if (hostname.indexOf('expresonorte') > -1) {
			return 'expresonorte.png';
		} else if (hostname.indexOf('t-rrasschile') > -1) {
			return 't-rrass.ico';
		} else if (hostname.indexOf('expresosantacruz') > -1) {
			return 'expresosantacruz.png';
		} else if (hostname.indexOf('busessantacruz') > -1) {
			return 'expresosantacruz.png';
		} else if (hostname.indexOf('sanantonioea') > -1) {
			return 'sanantonioea.png';
		} else if (hostname.indexOf('pullmandelsur') > -1) {
			return 'pullman-del-sur.ico';
		} else if (hostname.indexOf('travelturspa') > -1) {
			return 'traveltur.png';
		} else if (hostname.indexOf('traveltur') > -1) {
			return 'traveltur.png';
		} else if (hostname.indexOf('busesliquine') > -1) {
			return 'liquine.png';
		} else if (hostname.indexOf('tacoha') > -1) {
			return 'tacoha.ico';
		} else if (hostname.indexOf('combarbala') > -1) {
			return 'combarbala.ico';
		} else if (hostname.indexOf('centraltour') > -1) {
			return 'central-tour.ico';
		} else if (hostname.indexOf('tarapaca') > -1) {
			return 'tarapaca.ico';
		} else if (hostname.indexOf('aeroquinta') > -1) {
			return 'aeroquinta.png';
		} else if (hostname.indexOf('chilebus') > -1) {
			return 'chilebus.png';
		} else if (hostname.indexOf('santajuana') > -1) {
			return '	santajuana.ico';
		} else if (hostname.indexOf('lineaazul') > -1) {
			return '	lineaazul.ico';
		}  else if (hostname.indexOf('arzola') > -1) {
			return 'arzola.png';
		} else if (hostname.indexOf('santarosa') > -1) {
			return 'santarosa.png';
		}else if (hostname.indexOf('losnavegadores') > -1) {
			return 'losnavegadores.png';
		}
		else {
			return '';
		}
	}




	const renderHelmetOnOperatorBased = () => {
		if (operator) {
			return (
				<Helmet>
					<title>{operator.title}</title>
					<meta name='description' content={operator.description} />
					<meta name='keywords' content={operator.keywords} />
					{/* <link
            rel='icon'
            type='image/png'
            sizes='16x16'
            href={operator.favicon}
          /> */}
					{/* <link rel="icon" type="image/png" href={`/${getFavicon(window.location.hostname)}`} /> */}
				</Helmet>
			);
		}
		return null;
	};

	return (
		<div className='common-kupos-helmet'>{renderHelmetOnOperatorBased()}</div>
	);
};

function mapStateToProps(state) {
	return {
		home: state.home,
	};
}
export default connect(mapStateToProps, {})(KuposHelmet);
