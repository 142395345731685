import React, { PureComponent } from "react";

import { connectData } from "../../redux";
import { dimensions, commonStyles } from "../../theme";
import {
  PBCard,
  PBText,
  PBSafeView,
  CarpoolHeader,
  PBModal,
  View,
  NoData,
  CarpoolSuccessFailureModal,
} from "../../components";
import { CommonService } from "../../services";
import { IonPage, IonContent, IonToast } from "@ionic/react";
import getIcons from "../../themes/functions/getIcons/getIcons";
// import { ADD_CARD_PAYMENTS, MODIFY_CARD_PAYMENTS } from '../../../navigation';

class HomeMyCards extends PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      cardNumber: "",
      amount: "",
      myCards: [],
      cardTypes: this.props.data.metaData
        ? this.props.data.metaData.card_type
        : null,
    };

    this.getCustomerCards();
  }

  getCustomerCards = () => {
    const operator =
      JSON.parse(localStorage.getItem("op")) || this.props.data.operator;
    let queryParams = "";
    if (CommonService.shouldPassOperatorId(operator)) {
      queryParams = "operator_id=" + CommonService.getOperatorId(operator);
    }
    this.props.getCustomerCards({
      callback: result => this.onCardsLoad(result),
      operator_id: queryParams,
    });
  };

  onCardsLoad = result => {
    console.log("Cards are", result);
    if (result.data.status == 200) {
      this.setState({ myCards: result.data.card_details });
    } else {
      this.setState({ cardFetchingResult: result });
    }
  };

  componentDidMount() {}

  componentWillUnmount() {
    // BackHandler.removeEventListener('hardwareBackPress', this.handleBackPress);
  }

  // navigationButtonPressed({ buttonId }) {
  //   if (buttonId == "backPress") {
  //     this.handleBackPress();
  //   } else {
  //     CommonService.rightButonHome(
  //       buttonId,
  //       this.props.componentId,
  //       this.props.returnDateChange
  //     );
  //   }
  // }

  handleBackPress = () => {
    // CommonService.goToHome(this.props.componentId);
    return true;
  };

  gotoScreen = screen => {
    if (screen) {
      // CommonService.goToScreenHideTopBar(this.props.componentId, screen);
    }
  };

  renderMyCard = (val, key) => {
    console.log("Particular card is", val);
    const operator =
      JSON.parse(localStorage.getItem("op")) || this.props.data.operator;

    return (
      <div
        key={key}
        className={"my-cards-main-item kupos-card"}
        style={styles.txnItem}
      >
        <View className={"card-item"}>
          <img
            width={42}
            src={
              this.state.cardTypes[val[2]] == "americanexpress"
                ? "../../../assets/carpool/cards/americanexpress.png"
                : this.state.cardTypes[val[2]] == "diners"
                ? "../../../assets/carpool/cards/diners.png"
                : this.state.cardTypes[val[2]] == "magna"
                ? "../../../assets/carpool/cards/magna.png"
                : this.state.cardTypes[val[2]] == "mastercard"
                ? "../../../assets/carpool/cards/mastercard.png"
                : this.state.cardTypes[val[2]] == "redcompra"
                ? "../../../assets/carpool/cards/redcompra.png"
                : "../../../assets/carpool/cards/visa.png"
            }
            alt=""
          />
        </View>
        <View style={styles.txnName}>
          <PBText
            style={styles.listFontSize}
            fontStyle={
              operator && operator.operator_name === "busnorte"
                ? "new-bold-font"
                : "bold-font"
            }
          >
            {CommonService.capitalize(this.props.data.loginData.name)}{" "}
            {CommonService.capitalize(this.props.data.loginData.last_name)}
          </PBText>
          <PBText
            style={styles.listFontSize}
            fontStyle={
              operator && operator.operator_name === "busnorte"
                ? "new-bold-font"
                : ""
            }
          >
            {val[0].replace(/X/gi, "*").substring(0, 12) +
              val[0].substring(12, 16)}
          </PBText>
        </View>
        <View style={styles.actions}>
          {/* <img
            width={20}
            src={"../../assets/carpool/edit_profile.png"}
            onClick={() => this.goToModifyCard(key)}
          /> */}
          <img
            width={20}
            style={{ marginRigh: 20 }}
            src={"../../assets/carpool/delete_card.png"}
            onClick={() =>
              this.setState({ selectedCard: val, deleteModal: true })
            }
            alt=""
          />
        </View>
      </div>
    );
  };

  goToModifyCard = key => {
    console.log("Hello key is", key);

    this.props.history.push({
      pathname: `/my-account/modify-card`,
      cardToEdit: this.state.myCards[key],
    });

    // this.props.history.push("/my-account/add-card");
  };

  addCard = () => {
    this.setState({ infoModal: false });
    localStorage.setItem("appType", "add_card");
    const operator =
      JSON.parse(localStorage.getItem("op")) || this.props.data.operator;

    let queryParams = "";
    if (CommonService.shouldPassOperatorId(operator)) {
      queryParams = "operator_id=" + CommonService.getOperatorId(operator);
    }
    this.props.addMyCards({
      callback: result => this.onResponse(result),
      operator_id: queryParams,
      data: {
        base_url: window.location.protocol + "//" + window.location.host,
      },
    });
  };

  onResponse = result => {
    console.log("Result in response", result);
    // this.props.setBookingApiResponse(result.data);
    setTimeout(() => {
      document.write(result.data.data.html_str);
    }, 150);
  };

  deleteCard = val => {
    console.log("Delete card called", val);
    this.setState({ deleteModal: false });
    let body = {
      card_number: val[0],
      card_type: val[2],
      card_cat: val[1],
    };
    if (CommonService.shouldPassOperatorId(this.props.data.operator)) {
      body["operator_id"] = CommonService.getOperatorId(
        this.props.data.operator,
      );
    }
    this.props.deleteMyCards({
      data: body,
      callback: result => this.onDeleteCardResponse(result),
    });
  };

  onDeleteCardResponse = result => {
    const operator =
      JSON.parse(localStorage.getItem("op")) || this.props.data.operator;
    console.log("Delete card api response", result);
    if (result && result.success == true && result.data.data.status == 200) {
      this.getCustomerCards();
      this.setState({
        // toastMsg: "Card removed successfully!",
        // showToast: true,
        showResultModal: true,
        showResultModalStatus: true,
        showResultModalBodyText:
          "Tu tarjeta ha sido eliminada con éxito. Recuerda que siempre puedes agregar nuevamente tu tarjeta para facilitar tus compras.",
      });
    } else {
      // this.setState({
      //   toastMsg: "There was some issue in removing cards!",
      //   showToast: true,
      // });

      this.setState({
        showResultModal: true,
        showResultModalStatus: false,
        showResultModalBodyText:
          "Hubo un error al momento de eliminar tu tarjeta. Por favor intenta nuevamente.",
      });
    }
    let queryParams = "";
    if (CommonService.shouldPassOperatorId(operator)) {
      queryParams = "operator_id=" + CommonService.getOperatorId(operator);
    }
    this.props.getCustomerCards({
      callback: result => this.onProfileLoad(result),
      operator_id: queryParams,
    });
  };

  render() {
    const operator =
      JSON.parse(localStorage.getItem("op")) || this.props.data.operator;
    return (
      <IonPage style={{ height: "100vh", overflow: "hidden" }}>
        <IonContent>
          {/* <img
            src="/assets/carpool/bgs/bg-inner-bottom.png"
            className="main-bottom-image"
            style={{
              top: `calc(${CommonService.getWindowHeight()}px - 200px)`,
            }}
            alt=""
          /> */}
          <View>
            <View style={(styles.flex, commonStyles.whiteBG)}>
              <View>
                {/* <BGCarpoolInnerBottom /> */}

                {/* <CarpoolHeader
                  // componentId={this.props.componentId}
                  labelDark={true}
                  rightIcon={"../../assets/carpool/add_card.png"}
                  backIcon={true}
                  leftIconColor="#fff"
                  label="Mis"
                  history={this.props.history}
                  labelBold="tarjetas"
                  onRightIconPress={this.goToAddCard}
                /> */}

                <div className="new-home-header">
                  <div
                    className="header-back-button"
                    onClick={() => this.props.history.goBack()}
                  >
                    <img
                      className="qr-back-arrow"
                      src="/assets/carpool/icon_back_arrow.png"
                      alt=""
                    />
                  </div>

                  <div
                    className="my-account-header-label-black display-flex font19 black-font dark-font"
                    style={{ color: "#464647" }}
                  >
                    <span
                      className={
                        operator && operator.operator_name === "busnorte"
                          ? "side-menu-new-bold-font"
                          : ""
                      }
                    >
                      Mis&nbsp;
                    </span>
                    <span
                      className={
                        operator && operator.operator_name === "busnorte"
                          ? "new-bold-font"
                          : "bold-text"
                      }
                    >
                      tarjetas
                    </span>
                  </div>

                  <div onClick={() => this.setState({ infoModal: true })}>
                    <img
                      className="qr-back-arrow"
                      src="/assets/carpool/add_card.png"
                      alt=""
                    />
                  </div>
                </div>

                <View>
                  {this.state.myCards && this.state.myCards.length ? (
                    this.state.myCards.map((val, key) =>
                      this.renderMyCard(val, key),
                    )
                  ) : (
                    <View>
                      <NoData
                        icon={"/assets/carpool/no-transaction.png"}
                        title={
                          "¡Aún no has agregado ninguna\ntarjeta a tu cuenta!"
                        }
                        desc={
                          "Agrega tu tarjeta de débito o crédito y compra rápida y fácilmente."
                        }
                        buttonText={" ¡Agrega tu tarjeta aquí!"}
                        onPress={() => this.setState({ infoModal: true })}
                      />
                    </View>
                  )}
                </View>
              </View>
            </View>

            <PBModal
              showModal={this.state.deleteModal}
              titleText={"¿Estás seguro que deseas \n eliminar tu tarjeta?"}
              type="alert"
              showButton1={true}
              button1Text={"SÍ, ELIMINAR"}
              button1Press={() => this.deleteCard(this.state.selectedCard)}
              showButton2={true}
              button2Text={"NO, VOLVER"}
              button2Press={() => this.setState({ deleteModal: false })}
              onBackButtonPress={() => this.setState({ deleteModal: false })}
            >
              <View style={styles.deleteContentPopup}>
                <View style={styles.row}>
                  <PBText bold={true} style={{ marginRight: 5 }}
																		 fontStyle={
																				operator && operator.operator_name === "busnorte"
																						? "new-bold-font"
																						: "bold-text"
																		}
																		>
                    Nombre tarjeta:
                  </PBText>
                  <PBText 
																		 fontStyle={
																				operator && operator.operator_name === "busnorte"
																						? "side-menu-new-bold-font"
																						: ""
																		}
																		>
                    {this.state.selectedCard
                      ? CommonService.capitalize(
                          this.props.data.loginData.name,
                        ) +
                        " " +
                        CommonService.capitalize(
                          this.props.data.loginData.last_name,
                        )
                      : "Nombre"}
                  </PBText>
                </View>
                <View style={styles.row}>
                  <PBText bold={true} style={{ marginRight: 5, marginTop: 10 }}
																		 fontStyle={
																				operator && operator.operator_name === "busnorte"
																						? "new-bold-font"
																						: "bold-text"
																		}
																		>
                    Nº tarjeta:
                  </PBText>
                  <PBText style={{ marginTop: 10 }}
																		 fontStyle={
																				operator && operator.operator_name === "busnorte"
																						? "side-menu-new-bold-font"
																						: ""
																		}
																		>
                    {this.state.selectedCard
                      ? this.state.selectedCard[0]
                      : "XXXXXXX"}
                  </PBText>
                </View>
              </View>
            </PBModal>

            <PBModal
              showModal={this.state.infoModal}
              type="alert"
              modalHeight={dimensions.vh * 40}
              showButton1={true}
              button1Text={"OK, ACEPTAR"}
              button1Press={() => this.addCard()}
              showButton2={true}
              modalHeight={dimensions.vh * 54}
              button2Text={"NO, VOLVER"}
              button2Press={() => this.setState({ infoModal: false })}
              onBackButtonPress={() => this.setState({ infoModal: false })}
            >
              <View style={styles.deleteContentPopup}>
                <View style={{ display: "flex", justifyContent: "center" }}>
                  <img
                    style={{ marginRight: 20, width: "18vw" }}
                    // src={"/assets/carpool/warning.png"}
                    src={getIcons("warning")}
                    alt=""
                  />
                </View>

                <View
                  style={{
                    flexDirection: "row",
                    justifyContent: "center",
                    marginTop: 20,
                  }}
                >
                  <PBText center={true} style={{ fontSize: 15 }}
																		 fontStyle={
																				operator && operator.operator_name === "busnorte"
																						? "new-bold-font"
																						: "bold-text"
																		}
																		>
                    ¡Información importante!
                  </PBText>
                </View>

                <View
                  style={{
                    flexDirection: "row",
                    justifyContent: "center",
                    marginTop: 10,
                  }}
                >
                  <PBText center={true} light={true}  fontStyle={
																			operator && operator.operator_name === "busnorte"
																					? "side-menu-new-bold-font"
																					: ""
																	}>
                    Haremos un cobro por CLP$10 - CLP$70 pesos que luego serán
                    devueltos al validar tu tarjeta. Recuerda que puedes editar
                    o eliminar tu tarjeta cuando desees.
                  </PBText>
                </View>
              </View>
            </PBModal>

            <IonToast
              isOpen={this.state.showToast}
              onDidDismiss={() => this.setState({ showToast: false })}
              message={this.state.toastMsg}
              duration={2000}
            />

            <CarpoolSuccessFailureModal
              smallIcon={true}
              showModal={this.state.showResultModal}
              success={this.state.showResultModalStatus}
              bodyText={this.state.showResultModalBodyText}
              // onBackButtonPress={() => {
              //   this.setState({
              //     showResultModal: false,
              //     showResultModalStatus: null,
              //     showResultModalBodyText: null,
              //     recipient: null,
              //     optionalMessage: null,
              //   });
              // }}
              onButtonPress={() => {
                this.setState({
                  showResultModal: false,
                  showResultModalStatus: null,
                  showResultModalBodyText: null,
                });
              }}
            />
          </View>
        </IonContent>
      </IonPage>
    );
  }
}

const styles = {
  flex: {
    flex: 1,
  },
  whiteText: {
    color: "#fff",
  },
  header: {
    display: "flex",
    height: 50,
    alignItems: "center",
    justifyContent: "center",
    paddingTop: 16,
    width: "100%",
    paddingHorizontal: 20,
    marginBottom: 6,
    marginTop: 6,
  },
  headerLabel: {
    display: "flex",
    alignItems: "flex-end",
    justifyContent: "center",
  },
  addCard: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    marginTop: 5,
    marginBottom: 25,
  },
  inputContainer: {
    margin: 0,
    marginTop: 10,
    marginBottom: 5,
  },
  twoItemsContainer: {
    display: "flex",
    alignItems: "center",
    flex: 1,
    // marginBottom: 60,
    marginTop: 10,
  },
  box1: {
    width: "auto",
    // marginLeft: 15,
    marginRight: 5,
    flex: 1,
  },
  box2: {
    width: "auto",
    flex: 1,
    // marginRight: 15,
    marginLeft: 5,
  },
  bottomNoteContainer: {
    marginTop: 20,
    paddingHorizontal: 20,
    justifyContent: "center",
    alignItems: "center",
  },
  row: {
    display: "flex",
  },
  cvvContentPopup: {
    paddingHorizontal: 30,
    paddingBottom: 0,
    paddingTop: 15,
  },
  txnItem: {
    // paddingHorizontal: 5,
    // paddingVertical: 18,
    // borderBottomWidth: 1,
    // borderBottomColor: "#999",
    // display: "flex",
    paddingTop: 10,
    paddingBottom: 12,
    marginBottom: 15,
    marginTop: 5,
    paddingLeft: 10,
    paddingRight: 10,
  },
  amount: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    width: dimensions.vw * 26,
  },
  txnName: {
    flex: 1,
    justifyContent: "center",
    paddingLeft: 10,
    marginTop: 5,
  },
  actions: {
    width: dimensions.vw * 18,
    display: "flex",
    justifyContent: "space-around",
    alignItems: "center",
  },
  listFontSize: {
    fontSize: 12,
  },
  margin10: {
    marginTop: 10,
  },
  deleteContentPopup: {
    marginTop: 15,
  },
};

export default connectData()(HomeMyCards);
