import React, { PureComponent } from "react";

import { connectData } from "../../redux";
import { commonStyles, colors } from "../../theme";
import {
  PBCard,
  PBPrimaryBGView,
  PBText,
  PBInput,
  PButton,
  Loader,
  PBRadioGroup,
  PBModal,
  PBSelect,
  PBContactModal,
  KuposCheckbox,
} from "../../components";
import { CommonService, storageService, AppData } from "../../services";
import {
  IonPage,
  IonContent,
  IonCheckbox,
  IonToast,
  withIonLifeCycle,
} from "@ionic/react";
import { Link } from "react-router-dom";
import IonicDropdown from "../../components/IonicDropdown";
import FacebookLogin from "react-facebook-login";
import getIcons from "../../themes/functions/getIcons/getIcons";

class RegisterSecreen extends PureComponent {
  genders = [
    {
      id: "female",
      name: "Femenino",
    },
    {
      id: "male",
      name: "Masculino",
    },
  ];
  idCardTypes = [];

  constructor(props) {
    super(props);
    for (let c in this.props.data.metaData.id_card_types) {
      this.idCardTypes.push({
        value: this.props.data.metaData.id_card_types[c],
        id: this.props.data.metaData.id_card_types[c],
        label: c,
        name: c,
        selected: this.props.data.metaData.id_card_types[c] == 7,
      });
    }
    this.state = {
      name: this.oAuthData ? this.oAuthData.oAuthName : null,
      lastName: this.oAuthData ? this.oAuthData.oAuthLastName : null,
      email: this.oAuthData ? this.oAuthData.oAuthEmail : null,
      disableEmail: this.oAuthData && this.oAuthData.oAuthEmail ? true : false,
      password: null,
      confirmPassword: null,
      mobile: null,
      idCardType: this.idCardTypes[0],
      idCardNumber: null,
      nameError: null,
      lastNameError: null,
      emailError: null,
      passwordError: null,
      confirmPasswordError: null,
      mobileError: this.props.data.registerUserPhoneDataForOtp
        ? this.props.data.registerUserPhoneDataForOtp.mobile
        : null,
      cardNumberError: null,
      sex: ["male", "female"],
      selectedSex: "male",
      showContactModal: false,
      countryCode: "+56",
      tncChecked: false,
    };

    // CommonService.bindNav(this);
  }

  // navigationButtonPressed({ buttonId }) {
  // CommonService.rightButonHome(buttonId,this.props.componentId,this.props.returnDateChange);
  // }

  componentDidMount() {}

  ionViewWillEnter() {
    if (CommonService.isLoggedIn(this.props.data.loginData)) {
      this.props.history.push("/");
    }
    try {
      let data = sessionStorage.getItem("oAuthData");
      if (data) {
        this.oAuthData = JSON.parse(data);
      }
      this.setState({
        name: this.oAuthData ? this.oAuthData.oAuthName : null,
        lastName: this.oAuthData ? this.oAuthData.oAuthLastName : null,
        email: this.oAuthData ? this.oAuthData.oAuthEmail : null,
        disableEmail:
          this.oAuthData && this.oAuthData.oAuthEmail ? true : false,
        password: null,
        confirmPassword: null,
        mobile: this.props.data.registerUserPhoneDataForOtp
          ? this.props.data.registerUserPhoneDataForOtp.mobile
          : null,
        idCardType: this.idCardTypes[0],
        idCardNumber: null,
        nameError: null,
        lastNameError: null,
        emailError: null,
        passwordError: null,
        confirmPasswordError: null,
        mobileError: null,
        cardNumberError: null,
        sex: ["male", "female"],
        selectedSex: "male",
        showContactModal: false,
        countryCode: "+56",
        tncChecked: false,
      });
    } catch (e) {
      console.log(e);
    }
  }

  nameChange = (text) => {
    this.setState({
      nameError: null,
      name: text,
    });
  };

  lastNameChange = (text) => {
    this.setState({
      lastNameError: null,
      lastName: text,
    });
  };

  emailChange = (text) => {
    this.setState({
      emailError: null,
      email: text.trim(),
    });
  };

  passwordChange = (text) => {
    this.setState({
      passwordError: null,
      password: text,
    });
  };

  confirmPasswordChange = (text) => {
    this.setState({
      confirmPasswordError: null,
      confirmPassword: text,
    });
  };

  mobileChange = (text) => {
    if (text) {
      text = CommonService.phoneNumberFormat(text);
    }
    this.setState({
      mobileError: null,
      mobile: text,
    });
  };

  onCountryCodeChange = (text) => {
    // console.log("Mobile Number Received", this.state.mobile)
    if (text) {
      text = CommonService.phoneNumberFormat(text, 4);
    }
    this.setState({
      countryCodeError: null,
      countryCode: text,
    });
  };

  idCardChange = (val) => {
    console.log("type of doc", val);
    this.setState({
      cardNumberError: null,
      idCardNumber: null,
      idCardType: val,
    });
  };

  idCardNumberChange = (text) => {
    this.setState({
      cardNumberError: null,
    });
    if (text) {
      // let cardNumber = CommonService.isRut(this.state.idCardType,this.idCardTypes) ? CommonService.formatRut(text) : text;
      this.setState({
        idCardNumber: text,
      });
    } else {
      this.setState({
        idCardNumber: text,
      });
    }
  };

  referCodeChange = (text) => {
    this.setState({
      referCode: text,
    });
  };

  genderChange = (val) => {
    // console.log(val)
    this.setState({
      gender: val,
      genderError: null,
    });
  };

  onBlur = (text, inp) => {
    console.log(this.state.idCardType);
    if (!text) {
      if (inp == "name") {
        this.setState({
          nameError: {
            message: "Ingresa tu nombre",
          },
        });
      } else if (inp == "lastName") {
        this.setState({
          lastNameError: {
            message: "Ingresa tu apellido",
          },
        });
      } else if (inp == "email") {
        this.setState({
          emailError: {
            message: "Ingresa tu correo electrónico",
          },
        });
      } else if (inp == "password") {
        this.setState({
          passwordError: {
            message: "Ingresa tu contraseña",
          },
        });
      } else if (inp == "confirm_password") {
        this.setState({
          confirmPasswordError: {
            message: "Confirma tu contraseña",
          },
        });
      } else if (inp == "mobile") {
        this.setState({
          mobileError: {
            message: "Ingresa tu número de teléfono",
          },
        });
      } else if (inp == "card_number") {
        this.setState({
          cardNumberError: {
            message: "Ingresa tu número de documento",
          },
        });
      }
    } else if (inp == "email") {
      if (!CommonService.isEMailValid(text)) {
        this.setState({
          emailError: {
            message: "Ingresa un correo electrónico válido",
          },
        });
      }
    } else if (inp == "password") {
      if (this.state.password.length < 4) {
        this.setState({
          passwordError: {
            message: "La contraseña debe contener mínimo 4 dígitos",
          },
        });
      }
    } else if (inp == "confirm_password") {
      if (this.state.password != this.state.confirmPassword) {
        this.setState({
          confirmPasswordError: {
            message: "Las contraseñas deben coincidir",
          },
        });
      }
    } else if (inp == "mobile") {
      if (this.state.mobile.toString().length != 9) {
        this.setState({
          mobileError: {
            message: "El teléfono debe contener mínimo 9 dígitos",
          },
        });
      }
    } else if (inp == "card_number") {
      let cardNumber =
        this.state.idCardType && this.state.idCardType.value == 7
          ? CommonService.formatRut(text)
          : text;
      this.setState({
        idCardNumber: cardNumber,
      });
      if (
        !CommonService.isRutValid(this.state.idCardNumber) &&
        this.state.idCardType &&
        this.state.idCardType.value == 7
      ) {
        this.setState({
          cardNumberError: {
            message: "Ingresa un RUT válido",
          },
        });
      }
    }
  };

  isValid = () => {
    let errorCount = 0;
    if (!this.state.name) {
      this.setState({
        nameError: {
          message: "Ingresa tu nombre",
        },
      });
      errorCount++;
      // } else if (this.state.name.length < 4) {
      //   this.setState({
      //     nameError: {
      //       message: "El nombre debe contener mínimo 4 carácteres",
      //     },
      //   });
      //   errorCount++;
    }
    if (!this.state.lastName) {
      this.setState({
        lastNameError: {
          message: "Ingresa tu apellido",
        },
      });
      errorCount++;
    }
    if (!this.state.email) {
      this.setState({
        emailError: {
          message: "Ingresa tu correo electrónico",
        },
      });
      errorCount++;
    } else if (!CommonService.isEMailValid(this.state.email)) {
      this.setState({
        emailError: {
          message: "Ingresa un correo electrónico válido",
        },
      });
      errorCount++;
    }
    if (!this.state.password) {
      this.setState({
        passwordError: {
          message: "Ingresa tu contraseña",
        },
      });
      errorCount++;
    }
    // else if (this.state.password.length < 4) {
    //   this.setState({
    //     passwordError: {
    //       message: "La contraseña debe contener mínimo 4 dígitos",
    //     },
    //   });
    //   errorCount++;
    // }
    if (this.state.password != this.state.confirmPassword) {
      this.setState({
        confirmPasswordError: {
          message: "Las contraseñas deben coincidir",
        },
      });
      errorCount++;
    }
    if (!this.state.mobile) {
      this.setState({
        mobileError: {
          message: "Ingresa tu número de teléfono",
        },
      });
      errorCount++;
    } else if (this.state.mobile.toString().length != 9) {
      this.setState({
        mobileError: {
          message: "El teléfono debe contener mínimo 9 dígitos",
        },
      });
      errorCount++;
    }
    // if (!this.state.gender) {
    //   this.setState({
    //     genderError: {
    //       message: "Selecciona tu género",
    //     },
    //   });
    //   errorCount++;
    // }
    if (!this.state.idCardNumber) {
      this.setState({
        cardNumberError: {
          message: "Ingresa tu número de documento",
        },
      });
      errorCount++;
    } else if (
      !CommonService.isRutValid(this.state.idCardNumber) &&
      this.state.idCardType.value == 7
    ) {
      this.setState({
        cardNumberError: {
          message: "Por favor ingrese RUT válido.",
        },
      });
      errorCount++;
    }
    if (!this.state.tncChecked) {
      this.setState({
        showToast: true,
        toastMessage: "Acepta los términos y condiciones para continuar",
      });
      errorCount++;
    }
    if (errorCount <= 0) {
      return true;
    }
    return false;
  };

  onRegister = () => {
    console.log("Register button clicked");
    if (!this.isValid()) {
      return;
    }
    console.log("Register All Valid");
    // Keyboard.dismiss();
    let data = {
      name: this.state.name,
      last_name: this.state.lastName,
      email: this.state.email,
      password: this.state.password,
      phone:
        CommonService.loginType == "phone"
          ? this.props.data.registerUserPhoneDataForOtp.mobile
          : this.state.mobile,
      country_code: this.state.countryCode,
      identity_type: CommonService.isRutValid(this.state.idCardNumber) ? 7 : 3,
      identity_val: this.state.idCardNumber,
      referrer_code: this.state.referCode||'',
      // gender: this.state.gender.id,

      device_details: this.props.data.deviceInfo,
      is_mobile_app: false,
      image_url: this.props.oAuthPic,
      is_kupos: true,
    };

    if (CommonService.shouldPassOperatorId(this.props.data.operator)) {
      data['operator_id'] = CommonService.getOperatorId(this.props.data.operator)
    }
    if (this.oAuthData && this.oAuthData.oAuthLogin) {
      data.oauth_account = this.oAuthData.oauth_account;
      data.oauth_token = this.oAuthData.oauth_token;
      data.oauth_type = this.oAuthData.oAuthType;
      data.auth_info = { email: this.oAuthData.oAuthEmail };
    }

    this.props.validateCustomer({
      callback: (result) => this.onValidateResponse(result, data),
      data: data,
    });

    console.log("Registration data is", data);
  };

  onValidateResponse = (result, data) => {
    console.log(result);
    if (
      result.success &&
      ((result.data && result.data.error) || result.error)
    ) {
      if (result.data.error) {
        this.setValidateResponseError(result.data.error);
      }
      return;
    } else if (result.data) {
      let registerData = {
        registerData: data,
        oAuthLogin: this.oAuthData ? this.oAuthData.oAuthLogin : false,
        oAuthPic: this.oAuthData ? this.oAuthData.oAuthPic : null,
      };
      sessionStorage.setItem("registerData", JSON.stringify(registerData));
      this.props.history.push("/upload-pic");
    }
  };

  setValidateResponseError = (error) => {
    if (error.phone) {
      this.setState({
        mobileError: {
          message: error.phone,
        },
      });
    }
    if (error.name) {
      this.setState({
        nameError: {
          message: error.name,
        },
      });
    }
    if (error.email) {
      this.setState({
        emailError: {
          message: error.email,
        },
      });
    }
    if (error.password) {
      this.setState({
        passwordError: {
          message: error.password,
        },
      });
    }
    if (error.identity_val) {
      this.setState({
        cardNumberError: {
          message: error.identity_val,
        },
      });
    }
    if (error.oauth) {
      this.setState({
        showSuccessFailureModal: true,
        showSuccessFailureModalStatus: false,
        showSuccessFailureModalBodyText:
          "Something went wrong please try again later",
      });
    }
  };

  onResponse = (result) => {
    console.log(result);
    if (
      result.success &&
      ((result.data && result.data.error) || result.error)
    ) {
      // this.refs.toast.show(result.data.error);

      // this.props.history.push("/register/status/failuire");
      this.setState({
        showModal: true,
        modalTitle: "¡Lo sentimos!",
        modalBody: result.error ? result.error : result.data.error,
        button1Text: "OK, REINTENTAR",
        // modalIcon: "../../assets/carpool/booking-failure-circle.png",
        modalIcon: getIcons("failure"),
        button2Text: "RECUPERAR CONTRASEÑA",
      });
      return;
    } else if (result.data) {
      sessionStorage.removeItem("oAuthData");
      // this.props.history.push("/register/status/success");
      this.props.history.push("/upload-pic");

      if (result.data.auth_token && this.props.facebookData) {
        CommonService.storeLoginDetails(result.data, this.props.loginSuccess);
      }
      // this.setState({
      //   showModal: true,
      //   modalTitle: "¡Listo!",
      //   button1Text: "VOLVER",
      //   modalIcon: "../../assets/icons/mail-sent.png",
      //   showSuccessBody: true
      // });
    }
  };

  storeLoginDetails = (loginData) => {
    storageService.setItem("loginData", JSON.stringify(loginData));
  };

  goBack = () => {
    let modalTitle = this.state.modalTitle;
    this.setState({ showModal: false, modalTitle: "", modalBody: "" });
    // if(modalTitle == "¡Listo!")
    // Navigation.pop(this.props.componentId);
  };

  responseFacebook = (res) => {
    if (res.name) {
      this.setState({
        name: res.name.split(" ")[0],
        nameError: null,
      });
      if (res.name.split(" ")[1]) {
        this.setState({
          lastName: res.name.split(" ")[1],
          lastNameError: null,
        });
      }
    }

    if (res.email) {
      this.setState({
        email: res.email,
        emailError: null,
        disableEmail: true,
      });
    }

    this.oauth_account = res.userID;
    this.oauth_token = res.accessToken;
    this.facebookData = res;
  };

  render() {
    return (
      <IonPage>
        <IonContent>
          <div className="register-screen-container">
            <div style={{ paddingBottom: 0 }}>
              <div className="my-account-header">
                <div
                  className="header-back-button"
                  onClick={() => {
                    sessionStorage.removeItem("oAuthData");
                    this.props.history.goBack();
                  }}
                >
                  <img
                    className="qr-back-arrow"
                    src="../../assets/carpool/icon_back_arrow.png"
                  />
                </div>
                <div className="my-account-header-label display-flex">
                  <PBText fontSize={"font19"} fontColor={"dark-font"}>
                    Crea
                  </PBText>
                  <div className="white-space"></div>
                  <PBText
                    fontSize={"font19"}
                    fontColor={"dark-font"}
                    fontStyle={"bold-font"}
                  >
                    tu cuenta
                  </PBText>
                </div>
                <div>&nbsp;</div>
              </div>
              <div className="kupos-page-scroll full-page">
                <div style={{ paddingBottom: 15, height: "100%" }}>
                  <div className="kupos-card register-input-body-container">
                    {/* <div>
                  <PBPrimaryBGView style={styles.headerBG}>
                    <div style={styles.headerTextContainer}>
                      <PBText style={styles.headerText}>
                        Ingresa tus datos
                      </PBText>
                    </div>
                  </PBPrimaryBGView>
                </div> */}
                    <div style={styles.body}>
                      <div style={{ marginBottom: 10 }}>
                        <PBInput
                          placeholder="Nombre"
                          value={this.state.name}
                          onChange={(text) => this.nameChange(text)}
                          onBlur={(text) => this.onBlur(text, "name")}
                          error={this.state.nameError ? true : false}
                          errorMessage={
                            this.state.nameError
                              ? this.state.nameError.message
                              : ""
                          }
                          // iconLeft={"../../assets/icons/person-inp.png"}
                        ></PBInput>
                      </div>

                      <div style={{ marginBottom: 10 }}>
                        <PBInput
                          placeholder="Apellido"
                          value={this.state.lastName}
                          onChange={(text) => this.lastNameChange(text)}
                          onBlur={(text) => this.onBlur(text, "lastName")}
                          error={this.state.lastNameError ? true : false}
                          errorMessage={
                            this.state.lastNameError
                              ? this.state.lastNameError.message
                              : ""
                          }
                          // iconLeft={"../../assets/icons/person-inp.png"}
                        ></PBInput>
                      </div>
                      <div
                        className="input-outer-div"
                        style={{ marginBottom: 10 }}
                      >
                        <div className="input-wrapper">
                          <IonicDropdown
                            data={this.idCardTypes}
                            dropdownClass={"ion-dropdown"}
                            selectClass={"select-dropdown"}
                            value={this.state.idCardType}
                            onSelectChange={(val) => this.idCardChange(val)}
                          />
                        </div>
                      </div>

                      {/* <div>
                      <PBRadioGroup
                        radioButtons={this.idCardTypes}
                        size={18}
                        color={colors.primaryBG}
                        onPress={label => this.idCardChange(label)}
                        flexDirection={"row"}
                      />
                    </div> */}

                      <div style={{ marginBottom: 10 }}>
                        <PBInput
                          placeholder="RUT / DNI / Pasaporte"
                          value={this.state.idCardNumber}
                          onChange={(text) => this.idCardNumberChange(text)}
                          onBlur={(text) => this.onBlur(text, "card_number")}
                          error={this.state.cardNumberError ? true : false}
                          errorMessage={
                            this.state.cardNumberError
                              ? this.state.cardNumberError.message
                              : ""
                          }
                          // iconLeft={"../../assets/icons/rut-icon.png"}
                        ></PBInput>
                      </div>

                      <div style={{ marginBottom: 10 }}>
                        <PBInput
                          type="email-address"
                          placeholder="Correo electrónico"
                          value={this.state.email}
                          onChange={(text) => this.emailChange(text)}
                          onBlur={(text) => this.onBlur(text, "email")}
                          error={this.state.emailError ? true : false}
                          errorMessage={
                            this.state.emailError
                              ? this.state.emailError.message
                              : ""
                          }
                          disable={this.state.disableEmail}
                          // iconLeft={"../../assets/icons/mail.png"}
                        ></PBInput>
                      </div>

                      <div style={{ marginBottom: 10 }}>
                        <PBInput
                          disable={
                            CommonService.loginType == "phone" ? true : false
                          }
                          type="tel"
                          placeholder="Teléfono"
                          value={this.state.mobile}
                          onChange={(text) => this.mobileChange(text)}
                          onBlur={(text) => this.onBlur(text, "mobile")}
                          error={this.state.mobileError ? true : false}
                          errorMessage={
                            this.state.mobileError
                              ? this.state.mobileError.message
                              : ""
                          }
                          // iconLeft={"../../assets/icons/call.png"}
                          codeandphone={true}
                          countryCode={this.state.countryCode}
                          onCountryCodeChange={(text) =>
                            this.onCountryCodeChange(text)
                          }
                        ></PBInput>
                      </div>
                      {/* <div style={{ marginBottom: 10 }}>
                      <div className="input-wrapper">
                        <IonicDropdown
                        placeholder="Género"
                          data={this.genders}
                          dropdownClass={"ion-dropdown"}
                          selectClass={"select-dropdown"}
                          value={this.state.gender}
                          onSelectChange={(val) => this.genderChange(val)}
                        />
                      </div>
                    </div> */}

                      <div style={{ marginBottom: 10 }}>
                        <PBInput
                          password={true}
                          placeholder="Contraseña"
                          value={this.state.password}
                          onChange={(text) => this.passwordChange(text)}
                          onBlur={(text) => this.onBlur(text, "password")}
                          error={this.state.passwordError ? true : false}
                          errorMessage={
                            this.state.passwordError
                              ? this.state.passwordError.message
                              : ""
                          }
                          // iconLeft={"../../assets/icons/lock.png"}
                        ></PBInput>
                      </div>

                      <div style={{ marginBottom: 10 }}>
                        <PBInput
                          password={true}
                          placeholder="Confirma tu contraseña"
                          value={this.state.confirmPassword}
                          onChange={(text) => this.confirmPasswordChange(text)}
                          onBlur={(text) =>
                            this.onBlur(text, "confirm_password")
                          }
                          error={this.state.confirmPasswordError ? true : false}
                          errorMessage={
                            this.state.confirmPasswordError
                              ? this.state.confirmPasswordError.message
                              : ""
                          }
                          // iconLeft={"../../assets/icons/lock.png"}
                        ></PBInput>
                      </div>

                      {/* <div style={styles.inputContainer}>
                    <PBInput
                      placeholder="N°de documento"
                      value={this.state.idCardNumber}
                      onChange={text => this.idCardNumberChange(text)}
                      onBlur={text => this.onBlur(text, "card_number")}
                      error={this.state.cardNumberError ? true : false}
                      errorMessage={
                        this.state.cardNumberError
                          ? this.state.cardNumberError.message
                          : ""
                      }
                      // iconLeft={"../../assets/icons/rut-icon.png"}
                    
                    ></PBInput>
                  </div>

                  <div className="input-wrapper">
                    <IonicDropdown
                      data={this.state.sex}
                      dropdownClass={"ion-dropdown"}
                      selectClass={"select-dropdown"}
                      value={"male"}
                      onSelectChange={ev => console.log(ev)}
                    />
                  </div> 

                  <div style={styles.inputContainer}>
                    <PBInput
                      placeholder="Código de referido"
                      value={this.state.referCode}
                      onChange={text => this.referCodeChange(text)}
                      onBlur={text => this.onBlur(text, "refer_code")}
                      // iconLeft={"../../assets/icons/referral.png"}
                    
                    ></PBInput>
                  </div>*/}

                      <div className="checkbox-passenger-return margin-top-10">
                        <KuposCheckbox
                          className="passenger-return-checkbox-new"
                          checked={this.state.tncChecked}
                          onChange={() =>
                            this.setState({
                              tncChecked: !this.state.tncChecked,
                            })
                          }
                        />

                        <div style={{ marginTop: 4 }}>
                        <a href="/terms" className="terms-link">
                          <PBText fontColor={"dark-font"} fontSize={"font13"}>
                            Acepto los términos y condiciones
                          </PBText></a>
                        </div>
                      </div>
                      <div style={{ marginTop: 20 }}>
                        <PButton
                          title={"REGÍSTRATE"}
                          onPress={this.onRegister}
                          buttonTextStyle={{ fontSize: 16 }}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <PBModal
                showModal={this.state.showModal}
                titleText={this.state.modalTitle}
                bodyText={this.state.modalBody}
                type="alert"
                showButton1={true}
                button1Text={this.state.button1Text}
                button1Press={this.goBack}
                icon={this.state.modalIcon}
                showButton2={true}
                button2Text={this.state.button2Text}
                button2Press={() =>
                  this.setState({ showModal: false, showContactModal: true })
                }
              >
                {this.state.showSuccessBody && (
                  <div style={styles.registerSuccess}>
                    <div style={styles.text1Container}>
                      <PBText>
                        Hemos enviado un mensaje a tu correo electronico con las
                        instrucciones para activar tu cuenta
                      </PBText>
                    </div>
                    <div style={styles.text2Container}>
                      <PBText>No olvides revisar tu bandeja de spam.</PBText>
                    </div>
                  </div>
                )}
              </PBModal>

              <PBContactModal
                showContactModal={this.state.showContactModal}
                onBackButtonPress={() => {
                  this.setState({ showContactModal: false });
                }}
                onBackdropPress={() => {
                  this.setState({ showContactModal: false });
                }}
              />
              {/* <Toast ref="toast"/> */}
              <IonToast
                isOpen={this.state.showToast}
                onDidDismiss={() => this.setState({ showToast: false })}
                message={this.state.toastMessage}
                duration={2000}
              />
            </div>
          </div>
        </IonContent>
      </IonPage>
    );
  }
}

const styles = {
  flex: {
    flex: 1,
    alignItems: "center",
    justifyContent: "center",
  },
  header: {},
  headerBG: {
    borderTopLeftRadius: 8,
    borderTopRightRadius: 8,
    alignItems: "center",
    justifyContent: "center",
    paddingVertical: 15,
  },
  headerText: {
    color: "#fff",
    fontSize: 20,
  },
  body: {
    paddingVertical: 15,
    paddingHorizontal: 10,
  },
  inputContainer: {
    paddingVertical: 15,
    paddingHorizontal: 15,
  },
  registerSuccess: {
    marginHorizontal: 15,
  },
  text1Container: {
    marginBottom: 15,
  },
  fontSize: {
    fontSize: 16,
  },
};

export default connectData()(withIonLifeCycle(RegisterSecreen));
