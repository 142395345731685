import React, { PureComponent } from "react";
import { connectData } from "../../redux";
import { dimensions, commonStyles } from "../../theme";
import { CommonService, DateSerivce } from "../../services";
import {
  BGCarpoolInnerBottom,
  CarpoolHeader,
  PBCard,
  PBText,
  PBModal,
  PBTouchable,
  PButton,
  NoData,
  View,
  PoolModal,
} from "../../components";
import { Link } from "react-router-dom";
import {
  IonContent,
  IonPage,
  IonRefresher,
  IonRefresherContent,
  withIonLifeCycle,
} from "@ionic/react";
// import { CARPOOL_TRIP_DETAILS_SCREEN, CARPOOL_TRIP_MAP_SCREEN } from '../../navigation';

class CarpoolMyTripsScreen extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      showConfirmModal: false,
      showList: 1,
      pastTrips: [],
      onGoingTrips: [],
      reloaded: false,
    };

    // window.location.reload();
    // this.loadMyTickets();
  }

  ionViewWillEnter() {
    const operator =
      JSON.parse(localStorage.getItem("op")) || this.props.data.operator;
    let query;
    if (CommonService.shouldPassOperatorId(operator)) {
      query = "operator_id=" + CommonService.getOperatorId(operator);
    }
    this.props.getCustomerProfile({
      callback: result => this.onProfileLoad(result),
      operator_id: query,
    });
    // if (!this.state.reloaded) {
    //   window.location.reload();
    //   this.setState({ reloaded: true });
    // }

    this.loadMyTickets();
  }

  onProfileLoad = result => {
    if (result.statusCode == 200) {
      this.loadMyTickets();
      CommonService.storeLoginDetails(result.data, this.props.loginSuccess);
    } else if (result.statusCode == 401 && result.data.error) {
      this.setState({
        showModal: true,
        modalTitle: result.data.error,
        modalIcon: null,
        modalButtonText: "Iniciar Sesión",
        authFailed: true,
        modalButtonTap: this.goToLogin,
        modalBackButtonPress: this.goToHome,
      });
    } else if (result.data && result.data.error) {
      this.setState({
        showModal: true,
        modalTitle: "¡Lo Sentimos!",
        modalBody: result.data.error,
        modalIcon: "../../../assets/icons/cross-grey.png",
        modalButtonText: "OK",
        modalButtonTap: this.closeModal,
        modalBackButtonPress: this.closeModal,
      });
    }
  };

  goToLogin = () => {
    let authFailed = this.state.authFailed;
    this.closeModal();
    if (authFailed) {
      CommonService.storeLoginDetails(null, this.props.loginSuccess);
      // CommonService.setRoot(MAIN_HOME_SCREEN);
      this.props.history.push("/");
      setTimeout(() => {
        this.props.history.push("/login-options");
        // CommonService.goToScreen(global.homeComponentId,NEW_LOGIN_SCREEN,'Ingresa a tu cuenta');
      }, 150);
    }
  };

  closeModal = () => {
    this.setState({ showModal: false });
  };

  getCarpoolTrips = () => {
    this.props.carpoolGetTrips({
      callback: result => {
        console.log(result);
        if (result.data.status == "success" && result.data.result) {
          this.props.carpoolSetTrips(result.data.result);
          let pastTrips = result.data.result.filter(it => {
            return (
              (it.status != "accepted" && it.status != "pending") ||
              new Date(
                (it.arrival_time || it.departure_time) * 1000 + 60 * 60 * 1000,
              ) < new Date()
            );
          });
          let onGoingTrips = result.data.result.filter(it => {
            return (
              (it.status == "accepted" || it.status == "pending") &&
              new Date((it.arrival_time || it.departure_time) * 1000) >
                new Date()
            );
          });
          this.setState({
            pastTrips: pastTrips,
            onGoingTrips: onGoingTrips,
          });
        } else {
        }
      },
      data: {},
    });
  };

  showList = i => {
    this.setState({
      showList: i,
    });
    switch (i) {
      case 1:
        this.loadMyTickets();
        break;
      case 2:
        this.getCarpoolTrips();
        break;
      case 3:
        this.loadATTickets();
        break;
      case 4:
        this.loadTrainTickets();
        break;
      case 5:
        this.loadKuposGoTickets();
        break;
      default:
        break;
    }
  };

  loadMyTickets = () => {
    this.props.getMyTickets({
      callback: result => this.onLoadTickets(result),
      operator_id:
        (this.props &&
          this.props.data &&
          this.props.data.operator &&
          this.props.data.operator.operator_id) ||
        null,
    });
  };

  onLoadTickets = result => {
    console.log("Trips on load result", result);

    if (
      (!result.success || !result.data.success) &&
      result.data &&
      result.data.body.message
    ) {
      this.setState({
        errorMsg: result.data.body.message,
      });
    }
  };

  loadATTickets = () => {
    this.props.getATTickets({
      callback: result => this.onLoadATTickets(result),
      userId: this.props.data.loginData ? this.props.data.loginData.id : null,
    });
  };

  onLoadATTickets = result => {
    console.log("AT Ticket Results", result);
    if (
      (!result.success || !result.data.success) &&
      result.data &&
      result.data.message
    ) {
      this.setState({
        errorMsg: result.data.message,
      });
    }
  };

  loadTrainTickets = () => {
    this.props.getTrainTickets({
      callback: result => this.onLoadTrainTickets(result),
    });
  };

  onLoadTrainTickets = result => {
    console.log("Train Ticket Results", result);
    if (
      (!result.success || !result.data.success) &&
      result.data &&
      result.data.body.message
    ) {
      this.setState({
        errorMsg: result.data.body.message,
      });
    }
  };

  loadKuposGoTickets = () => {
    this.props.getKuposGoTickets({
      callback: result => this.onLoadKuposGoTickets(result),
    });
  };

  onLoadKuposGoTickets = result => {
    console.log("Kupos Go Ticket Results", result);
    if (
      (!result.success || !result.data.success) &&
      result.data &&
      result.data.body.message
    ) {
      this.setState({
        errorMsg: result.data.body.message,
      });
    }
  };

  copyText = val => {
    // Clipboard.setString(val)
    // this.refs.toast.show('copiado');
  };

  // renderTicketItem = (ticket,key) => {
  //   return (
  //     <div className="my-trips-ticket-item-container" key={key}>
  //       {this.renderItemRow(
  //         "Número de Reserva",
  //         ticket.pnr_number,
  //         true,
  //         false,
  //         true
  //       )}
  //       {this.renderItemRow("Salida", ticket.origin_name, false, true)}
  //       {this.renderItemRow("Llegada", ticket.destination_name, false, true)}
  //       {this.renderItemRow(
  //         "Fecha de viaje",
  //         DateSerivce.changeDateFormatMonth(ticket.travel_date, "yyyy-mm-dd"),
  //         false,
  //         false
  //       )}
  //       {this.renderItemRow(
  //         "Fecha de Compra",
  //         DateSerivce.changeDateFormatMonth(ticket.travel_date, "yyyy-mm-dd"),
  //         false,
  //         false
  //       )}
  //       {this.renderItemRow(
  //         "Estatus",
  //         CommonService.getStatusName(
  //           ticket.status
  //             ? ticket.status
  //                 .toLowerCase()
  //                 .trim()
  //                 .replace(" ", "_")
  //             : "booked"
  //         ),
  //         true,
  //         false
  //       )}
  //       {/* {this.renderButtons(ticket)} */}
  //     </div>
  //   );
  // }

  goToScreenDetails = (screen, ticket) => {
    this.props.history.push({
      pathname: `/my-account/my-trips/${screen}`,
      selectedTicket: ticket,
    });
  };

  notIspast(date) {
    if (date < DateSerivce.getTodayString("yyyy-mm-dd")) {
      return false;
    }
    return true;
  }

  renderTicketItem = (ticket, key, type) => {
    const operator =
      JSON.parse(localStorage.getItem("op")) || this.props.data.operator;
    // console.log("Ticket is", ticket)
    return (
      <div
        className="kupos-card ticket-item-conatiner dark-font single-trip-item"
        key={key}
        onClick={
          type == "at"
            ? () => this.goToScreenDetails("at-trip-details", ticket)
            : type == "kuposgo"
            ? () => this.goToScreenDetails("kuposgo-trip-details", ticket)
            : type == "train"
            ? () => this.goToScreenDetails("train-trip-details", ticket)
            : () => this.getTicketDetails(ticket)
        }
      >
        <div>
          <div className="display-flex">
            <PBText
              fontStyle={
                operator && operator.operator_name === "busnorte"
                  ? "new-bold-font"
                  : "bold-font"
              }
              fontSize={"font14"}
              fontColor={"dark-font"}
            >
              {type == "at"
                ? CommonService.normalizeAddress(ticket.pickup_address) +
                  " - " +
                  CommonService.normalizeAddress(ticket.drop_address)
                : null}
              {type == "kuposgo"
                ? CommonService.normalizeAddress(ticket.pickup_address) +
                  " - " +
                  CommonService.normalizeAddress(ticket.drop_address)
                : null}

              {type !== "at" && type !== "kuposgo"
                ? CommonService.normalizeAddress(ticket.origin_name) +
                  " - " +
                  CommonService.normalizeAddress(ticket.destination_name)
                : null}
            </PBText>
            {/* <div className="white-space"></div>
            <PBText
              fontStyle={"bold-text"}
              fontSize={"font14"}
              fontColor={"light-grey-font"}
            >
              -
            </PBText>
            <div className="white-space"></div>
            <PBText
              fontStyle={"bold-text"}
              fontSize={"font14"}
              fontColor={"light-grey-font"}
            >
              {type == "at" || type == "kuposgo"
                ? CommonService.normalizeAddress(ticket.drop_address)
                : CommonService.normalizeAddress(ticket.destination_name)}
            </PBText> */}
          </div>
          <div className="margin-top-5">
            <PBText
              fontSize={"font13"}
              fontStyle={
                operator && operator.operator_name === "busnorte"
                  ? "side-menu-new-bold-font"
                  : ""
              }
            >
              {DateSerivce.changeDateFormat(
                ticket.travel_date || ticket.trip_ref_date,
                "yyyy-mm-dd",
                "dd/mm/yyyy",
              )}
            </PBText>
          </div>

          <div className="display-flex margin-top-5">
            <PBText
              fontSize={"font13"}
              fontStyle={
                operator && operator.operator_name === "busnorte"
                  ? "side-menu-new-bold-font"
                  : ""
              }
            >
              Estatus:
            </PBText>
            <div className="white-space"></div>
            <PBText
              fontSize={"font13"}
              fontStyle={
                operator && operator.operator_name === "busnorte"
                  ? "side-menu-new-bold-font"
                  : ""
              }
            >
              {ticket.status_label
                ? this.notIspast(ticket.travel_date || ticket.trip_ref_date)
                  ? type == "kuposgo"
                    ? "Solicitado"
                    : ticket.status_label
                  : type == "kuposgo"
                  ? "Expirado"
                  : ticket.status_label
                : CommonService.getStatusName(ticket.status) || "Publicado"}
              {/* {ticket.status_label
                ? ticket.status_label
                : type == "kuposgo"
                ? "Solicitado"
                : CommonService.getStatusName(ticket.status) || "Publicado"} */}
            </PBText>
          </div>
        </div>
        {ticket.status ? (
          <div style={{ display: "flex", justifyContent: "flex-end" }}>
            <View
              style={
                (styles.amtChip,
                ((ticket.status &&
                  ticket.status.toLowerCase() == "confirmed") ||
                  (ticket.status &&
                    ticket.status.toLowerCase().indexOf("partial") > -1)) &&
                this.notIspast(ticket.travel_date || ticket.trip_ref_date)
                  ? {
                      backgroundColor:operator.color_codes.secondary_color|| "#FF5C60",
                      paddingHorizontal: 20,
                      borderRadius: 20,
                      height: 20,
                      width: 50,
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      paddingTop: 2,
                    }
                  : {
                      backgroundColor: "#eee",
                      paddingHorizontal: 20,
                      borderRadius: 20,
                      height: 20,
                      width: 50,
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      paddingTop: 2,
                    })
              }
            >
              <PBText
                style={
                  (ticket.status.toLowerCase() == "confirmed" ||
                    ticket.status.toLowerCase().indexOf("partial") > -1) &&
                  this.notIspast(ticket.travel_date || ticket.trip_ref_date)
                    ? { fontSize: 11, color: "#fff" }
                    : { fontSize: 11, color: "#464647" }
                }
																fontStyle={
																	operator && operator.operator_name === "busnorte"
																			? "side-menu-new-bold-font"
																			: ""
															}
              >
                Ver
              </PBText>
            </View>
          </div>
        ) : (
          <View
            style={
              (styles.amtChip,
              {
                backgroundColor: "#FF5C60",
                paddingHorizontal: 20,
                borderRadius: 20,
                height: 20,
                width: 50,
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                paddingTop: 2,
              })
            }
          >
            <PBText style={{ fontSize: 11, color: "#fff" }}>Ver</PBText>
          </View>
        )}
        {/* <div>{this.renderButtons(ticket)}</div> */}
      </div>
    );
  };

  renderItemRow = (label, value, primary, bold, copy = false) => {
    return (
      <div className="my-trips-ticket-item-inner-row">
        <div className="full-flex">
          <PBText fontSize={"font12"} bold={true}>
            {label}
          </PBText>
        </div>
        {copy ? (
          <div className="my-trips-row-value">
            <div onLongPress={() => this.copyText(value)}>
              <PBText
                fontSize={"font12"}
                fontColor={"primary-font"}
                fontStyle={"bold-font"}
              >
                {value}
              </PBText>
            </div>
          </div>
        ) : (
          <div className="my-trips-row-value">
            <PBText fontSize={"font12"} primary={primary} bold={bold}>
              {value}
            </PBText>
          </div>
        )}
      </div>
    );
  };

  getTicketDetails = ticket => {
    this.props.history.push(`/my-account/my-trips/${ticket.pnr_number}`);
  };

  renderButtons = ticket => {
    // console.log("Ticket received", ticket);
    return (
      <div className="my-trips-ticket-item-inner-row">
        {/* <div className="full-flex"></div> */}
        <div className="my-trips-row-price-container">
          {((ticket.status &&
            ticket.status.toUpperCase().indexOf("CANCEL") < 0) ||
            (ticket.status &&
              ticket.status.toUpperCase().indexOf("PARTIAL") > -1)) &&
          !this.isPastDate(ticket.travel_date) ? (
            <div>
              {/* <div onClick={() => this.getTicketDetails(ticket, 2)}> */}
              <PBText>Anular</PBText>
            </div>
          ) : (
            <div>
              <PBText
                style={
                  (ticket.status.toLowerCase() == "confirmed" ||
                    ticket.status.toLowerCase().indexOf("partial") > -1) &&
                  this.notIspast(ticket.travel_date)
                    ? { fontSize: 11, color: "#fff" }
                    : { fontSize: 11, color: "#464647" }
                }
              >
                Ver
              </PBText>
            </div>
          )}

          {/* <PButton
            title="Ver"
            style={styles.buttonStyle}
            onPress={() => this.getTicketDetails(ticket, 1)}
          /> */}
        </div>
      </div>
    );
  };

  isPastDate(date) {
    let d = new Date(date + "T00:00:00");
    let d1 = new Date();
    if (d - d1 < 0 && d.getDate() != d1.getDate()) {
      return true;
    }
    return false;
  }

  renderIcons = () => {
    return (
      <div className="my-trips-icons-container">
        {this.renderIcon(
          this.state.showList == 1
            ? "/assets/carpool/bus-circle.png"
            : "/assets/carpool/bus-grey-circle.png",
          () => this.showList(1),
        )}
        {this.renderIcon(
          this.state.showList == 2
            ? "/assets/carpool/carpool-circle.png"
            : "/assets/carpool/carpool-grey-circle.png",
          () => this.showList(2),
        )}
        {this.renderIcon(
          this.state.showList == 3
            ? "/assets/carpool/airport-transfer-circle.png"
            : "/assets/carpool/airport-transfer-grey-circle.png",
          () => this.showList(3),
        )}
        {this.renderIcon(
          this.state.showList == 4
            ? "/assets/carpool/train-circle.png"
            : "/assets/carpool/train-grey-circle.png",
          () => this.showList(4),
        )}
        {this.renderIcon(
          this.state.showList == 5
            ? "/assets/carpool/icon_kupos-go.png"
            : "/assets/carpool/icon_kupos-go-grey.png",
          () => this.showList(5),
        )}
      </div>
    );
  };

  renderIcon = (src, onPress) => {
    return (
      <div className="my-trips-icon-container">
        <PBTouchable onPress={onPress}>
          <div className="full-flex">
            <img className="my-trips-icon" src={src} />
          </div>
        </PBTouchable>
      </div>
    );
  };

  renderTitle = () => {
    let title = "";
    switch (this.state.showList) {
      case 1:
        title = "PasajeBus.com";
        break;
      case 2:
        title = "Viajes compartidos";
        break;
      case 3:
        title = "Transfer aeropuerto";
        break;
      case 4:
        title = "EFE Trenes de Chile";
        break;
      case 5:
        title = "Servicios especiales";
        break;
      default:
        title = "";
        break;
    }
    return (
      <div
        style={{
          alignItems: "center",
          // marginVertical: dimensions.vh * 4,
          // marginTop: dimensions.vh * 2,
          marginTop: 10,
          marginBottom: 10,
        }}
      >
        <PBText
          bold={true}
          dark={true}
          style={{ fontSize: 15, textAlign: "center" }}
        >
          {title}
        </PBText>
      </div>
    );
  };

  renderUpcomingTrips = () => {
    return this.state.onGoingTrips
      ? this.state.onGoingTrips.map((val, key) => {
          let date = DateSerivce.getFormattedDate(
            new Date(val.departure_time * 1000),
            "dd/mm/yyyy",
            true,
          );
          return (
            <div style={styles.upcomingContainer} key={key}>
              {this.renderTripCard(
                val.pickup_address,
                val.destination_address,
                date,
                "Próximo",
                val.final_cost,
                val.code,
                1,
                key,
              )}
            </div>
          );
        })
      : null;
  };

  renderPastTrips = () => {
    return (
      <div style={styles.pastContainer}>
        {this.state.onGoingTrips
          ? this.state.onGoingTrips.map((val, key) => {
              let date = DateSerivce.getFormattedDate(
                new Date(val.departure_time * 1000),
                "dd/mm/yyyy",
                true,
              );
              return this.renderTripCard(
                val.pickup_address,
                val.destination_address,
                date,
                "Finalizado",
                val.final_cost,
                val.code,
                2,
                key,
              );
            })
          : null}
      </div>
    );
  };

  renderTripCard = (origin, dest, date, status, amount, code, type, key) => {
    return (
      <div className="my-trips-trip-card" key={key}>
        <div>
          <PBTouchable onPress={() => this.openTrip(code, type)}>
            <div className="my-trips-inner-card">
              <div className="my-trips-full-flex">
                <div className="full-flex">
                  <PBText bold={true} fontColor={"dark-font"}>
                    {CommonService.normalizeAddress(origin) +
                      " - " +
                      CommonService.normalizeAddress(dest)}
                  </PBText>
                </div>
                <div
                  style={{
                    backgroundColor: type == 1 ? "#FF5A5E" : "#eee",
                    padding: "2px 5px",
                    borderRadius: 5,
                  }}
                >
                  <PBText
                    style={{
                      fontSize: 11,
                      color: type == 1 ? "#fff" : "#666",
                    }}
                  >
                    CLP${amount}
                  </PBText>
                </div>
              </div>
              <div style={{ marginVertical: 7 }}>
                <PBText fontSize={"font11"}>
                  {DateSerivce.getDayNameFromDate(date)},{" "}
                  {DateSerivce.getDateFromDate(date)} de{" "}
                  {DateSerivce.getMonthNameFromDate(date, "dd/mm/yyyy", "full")}{" "}
                  de {DateSerivce.getYearFromDate(date)}
                </PBText>
              </div>
              <div>
                <PBText style={{ fontSize: 13 }}>
                  {console.log("Status is", status)}
                  Estatus: {status}
                </PBText>
              </div>
            </div>
          </PBTouchable>
        </div>
      </div>
    );
  };

  openTrip = (code, type) => {
    this.getOrderDetails(code, type);
  };

  getOrderDetails = (orderCode, type) => {
    this.props.carpoolGetOrderDetails({
      callback: result => {
        if (result.data.status == "success" && result.data.result) {
          this.props.carpoolSetOrderDetails(result.data.result);
          this.props.carpoolSelectOngoingTrip(result.data.result);
          if (type == 1) {
            // CommonService.goToScreenHideTopBar(this.props.componentId,CARPOOL_TRIP_MAP_SCREEN);
          } else {
            // CommonService.goToScreenHideTopBar(this.props.componentId,CARPOOL_TRIP_DETAILS_SCREEN);
          }
        } else {
        }
      },
      orderCode: orderCode,
    });
  };

  renderTripScreen = () => {
    switch (this.state.showList) {
      case 1:
        return this.renderBusTripsScreen();
      // case 2:
      //   return this.renderCarpoolTripScreen();
      // case 3:
      //   return this.renderAirportTripScreen();
      // case 4:
      //   return this.renderTrainTripScreen();
      // case 5:
      //   return this.renderKuposGoScreen();
      default:
        return this.renderNoDataScreen({
          icon: "/assets/carpool/bus-grey-circle.png",
        });
    }
  };

  renderBusTripsScreen = () => {
    console.log("Bus tickets are", this.props.data.myTickets);
    return (
      <div>
        {this.props.data.myTickets && !this.props.data.myTickets.message
          ? this.props.data.myTickets.map((ticket, key) =>
              this.renderTicketItem(ticket, key),
            )
          : this.renderNoDataScreen({
              icon: "/assets/carpool/bus-grey-circle.png",
              onPress: () => {
                CommonService.appSelectedType = "bus";
                this.props.history.push("/es/pasajes-bus");
              },
            })}
      </div>
    );
  };

  renderCarpoolTripScreen = () => {
    return (
      <div>
        {(!this.state.onGoingTrips || !this.state.onGoingTrips.length) &&
        (!this.state.pastTrips || !this.state.pastTrips.length) ? (
          this.renderNoDataScreen({
            icon: "/assets/carpool/carpool-grey-circle.png",
            onPress: () => {
              this.setState({ showPoolModal: true });
            },
          })
        ) : (
          <div
            style={{
              overflow: "auto",
              height: "calc(100vh - 150px)",
              marginTop: 5,
            }}
          >
            {this.state.onGoingTrips && this.state.onGoingTrips.length
              ? this.renderUpcomingTrips()
              : null}
            {this.state.pastTrips && this.state.pastTrips.length ? (
              <div style={{ marginTop: 0 }}>
                {/* <PBText>Viajes anteriores</PBText> */}
                {this.renderPastTrips()}
              </div>
            ) : null}
          </div>
        )}
      </div>
    );
  };

  renderAirportTripScreen = () => {
    return (
      <div>
        {this.props.data.atTickets && !this.props.data.atTickets.message
          ? this.props.data.atTickets.map((ticket, key) =>
              this.renderTicketItem(ticket, key, "at"),
            )
          : this.renderNoDataScreen({
              icon: "/assets/carpool/airport-transfer-grey-circle.png",
              onPress: () => {
                CommonService.appSelectedType = "transfer";
                this.props.history.push("es/traslados");
              },
            })}
      </div>
    );
  };

  renderTrainTripScreen = () => {
    return (
      <div>
        {this.props.data.trainTickets && !this.props.data.trainTickets.message
          ? this.props.data.trainTickets.map((ticket, key) =>
              this.renderTicketItem(ticket, key, "train"),
            )
          : this.renderNoDataScreen({
              icon: "/assets/carpool/train-grey-circle.png",
              onPress: () => {
                CommonService.appSelectedType = "train";
                this.props.history.push("/es/pasajes-tren");
              },
            })}
      </div>
    );
  };

  renderKuposGoScreen = () => {
    return (
      <div>
        {this.props.data.kuposGoTickets &&
        !this.props.data.kuposGoTickets.message
          ? this.props.data.kuposGoTickets.map((ticket, key) =>
              this.renderTicketItem(ticket, key, "kuposgo"),
            )
          : this.renderNoDataScreen({
              icon: "/assets/carpool/icon_kupos-go-grey.png",
              onPress: () => {
                CommonService.appSelectedType = "kupos-go";
                this.props.history.push("/kupos-go");
              },
            })}
      </div>
    );
  };

  renderNoDataScreen = ({ icon, title, desc, buttonText, onPress }) => {
    return (
      <NoData
        icon={icon}
        title={title || "¡Todavía no has hecho ningún viaje con nosotros!"}
        desc={
          desc ||
          "Anímate a planificar el próximo y viaja donde y cuando quieras."
        }
        buttonText={buttonText || "¡Busca tu próximo destino aquí!"}
        onPress={onPress}
      />
    );
  };

  render() {
    return (
      <IonPage>
        {/* <BGCarpoolInnerBottom /> */}
        <CarpoolHeader
          showHomeIcon={true}
          history={this.props.history}
          backIcon={true}
          componentId={this.props.componentId}
          label="Mis"
          labelBold="viajes"
          labelDark={true}
        />
        {/* {this.renderIcons()} */}
        {/* {this.renderTitle()} */}

        <IonContent className="content-transparent">
          <div className="full-flex my-trips-root-container">
            <div className="trip-body">
              <div key={this.state.showList}>
                <div style={{ paddingBottom: 20 }}>
                  <div
                    style={{
                      overflow: "auto",
                      marginTop: 5,
                      position: "relative",
                    }}
                  >
                    {this.renderTripScreen()}
                  </div>
                </div>
              </div>
            </div>

            <PBModal
              showModal={this.state.showModal}
              titleText={this.state.modalTitle}
              bodyText={this.state.modalBody}
              type="alert"
              showButton1={true}
              button1Text={this.state.modalButtonText}
              button1Press={
                this.state.modalButtonTap
                  ? this.state.modalButtonTap
                  : this.closeModal
              }
              icon={this.state.modalIcon}
              onBackButtonPress={
                this.state.modalBackButtonPress
                  ? this.state.modalBackButtonPress
                  : this.goBack
              }
            ></PBModal>
          </div>
          <IonRefresher
            slot="fixed"
            onIonRefresh={event => {
              this.showList(this.state.showList);
              event.detail.complete();
            }}
          >
            <IonRefresherContent
              pullingText="Desliza para actualizar"
              refreshingSpinner="circles"
              refreshingText="Cargando..."
            ></IonRefresherContent>
          </IonRefresher>

          <PoolModal
            onBackButtonPress={() => this.setState({ showPoolModal: false })}
            showPoolModal={this.state.showPoolModal}
          />
        </IonContent>
      </IonPage>
    );
  }
}

const styles = {
  flex: {
    flex: 1,
  },
  bodyContainer: {
    flex: 1,
  },
  iconsContainer: {
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
    marginHorizontal: 30,
    paddingBottom: 10,
  },
  iconContainer: {
    width: dimensions.vw * 14,
    height: dimensions.vw * 14,
    borderRadius: dimensions.vw * 7,
    overflow: "hidden",
  },
  icon: {
    width: dimensions.vw * 14,
    height: dimensions.vw * 14,
    resizeMode: "stretch",
  },

  headText: {
    marginHorizontal: 30,
  },
  cardInner: {
    padding: 25,
  },
  amtChip: {
    backgroundColor: "#FF5A5E",
    borderRadius: 8,
    paddingHorizontal: 10,
    height: 22,
    justifyContent: "center",
  },
  cancelBtn: {
    overflow: "hidden",
    marginTop: -15,
    zIndex: -1,
    borderRadius: 10,
  },
  cancelBtnInner: {
    borderRadius: 10,
    padding: 10,
    paddingTop: 25,
    backgroundColor: "$primaryBG",
    alignItems: "center",
    justifyContent: "center",
  },
  ticketItemContainer: {
    padding: 10,
    width: dimensions.vw * 96,
    marginHorizontal: dimensions.vw * 2,
    marginTop: 8,
    borderRadius: 5,
    backgroundColor: "#fff",
    elevation: 3,
    shadowColor: "#000",
    shadowOpacity: 0.77,
    shadowOffset: { width: 3, height: 3 },
    shadowRadius: 3,
  },
  ticketItemInnerRow: {
    flexDirection: "row",
    width: "100%",
  },
  rowLabel: {
    flex: 1,
  },

  noTicketsFoundContainer: {
    flex: 1,
    alignItems: "center",
    justifyContent: "center",
    padding: 15,
  },
  rowValue: {
    width: "50%",
    flexDirection: "row",
  },
  buttonStyle: {
    height: 40,
    width: "50%",
    marginVertical: 0,
    marginHorizontal: 2,
  },
};

export default connectData()(withIonLifeCycle(CarpoolMyTripsScreen));
