import { IonContent, IonPage } from '@ionic/react';

import { CommonService } from '../../../services';
import Header from '../../common/header/Header';
import HeroSection from '../../common/HeroSection/HeroSection';
import React from 'react';
import ThemeFourContactForm from '../common/ThemeFourContactForm';
import ThemeFourFooter from '../common/ThemeFourFooter';
import ThemeFourOfficeSection from '../common/ThemeFourOfficeSection';
import ThemeFourTitle from '../common/ThemeFourTitle';
import { connectData } from '../../../redux';
import getIcons from '../../functions/getIcons/getIcons';

const ThemeFourContactUsScreen = (props) => {
	const operator = CommonService.getLocal('op') || props.data.operator;

  return (
    <IonPage>
      <IonContent>
        <Header />
        <HeroSection
          heroImage={getIcons('contactUsBanner', operator)}
          title={'Contacto'}
          {...operator.operator_name !== "losnavegadores" ? { description: 'Home / Contacto' } : {}}
        />
        <ThemeFourTitle title='¡Comunícate con nosotros!' />

				<ThemeFourContactForm contactForm />

				<ThemeFourOfficeSection />

				<ThemeFourFooter />
			</IonContent>
		</IonPage>
	);
};

export default connectData()(ThemeFourContactUsScreen);
