import React from "react";
// import { View, StyleSheet, Image } from 'react-native';
import { dimensions } from "../theme";

const BGCarpoolInnerBottom = (props) => {
  return (
    // <img
    //   src={"/assets/carpool/bgs/bg-inner-bottom.png"}
    //   className="main-bottom-image"
    //   style={{ zIndex: props.zIndex }}
    // />
    <div></div>
  );
};

const styles = {
  mainBgImg: {
    width: dimensions.vw * 100,
    height: dimensions.vh * 50,
    resizeMode: "stretch",
    position: "absolute",
    top: dimensions.vh * 65,
    left: 0,
  },
};

export default BGCarpoolInnerBottom;
