const Banner = '/assets/losnavegadores/banner.webp';
const HeaderLogo = '/assets/kennybus/general/kennybus-header-logo.png';
const FooterLogo = '/assets/kennybus/general/kennybus-footer-logo-white.png';
const BookingInActive = '/assets/kennybus/general/booking_white.svg';
const BookingActive = '/assets/kennybus/general/booking.svg';
const CancelInActive = '/assets/kennybus/general/cancel_white.svg';
const CancelActive = '/assets/kennybus/general/cancel.svg';

const Whatsapp = '/assets/kennybus/icons/whatsapp.svg';
const Email = '/assets/losnavegadores/icons/email.svg';
const HeadPhone = '/assets/losnavegadores/icons/contact.svg';
const YellowLine = '/assets/losnavegadores/icons/red-line.png';

const OriginIcon = '/assets/losnavegadores/icons/origin.svg';
const DestinationIcon = '/assets/losnavegadores/icons/destination.svg';
const CalendarIcon = '/assets/kennybus/icons/calendar.svg';

const pnr = '/assets/losnavegadores/losnavegadores-pnr.png';
const PopupIcon = '/assets/kennybus/general/Pop-up.jpg';


const animation =
	'/assets/losnavegadores/icons/animation.gif';
const BottomFooterKuposLogo = '/assets/tepual/Footer-kuposLogo.svg';

const BG = '/assets/losnavegadores/background-image.webp';
const checkboxIcon = '/assets/tepual/checkbox.png';
const close = '/assets/kennybus/icons/close.svg';



const fb = '/assets/losnavegadores/icons/facebook.png';
const tw = '/assets/losnavegadores/icons/twitter-icon.svg';
const insta = '/assets/losnavegadores/icons/instagram.png';
const contactfb = '/assets/losnavegadores/icons/contact-facebook.png';
const contactinsta = '/assets/losnavegadores/icons/contact-instagram.png';



const contactEmail = '/assets/losnavegadores/icons/contact-email.svg';
const  contactphone = '/assets/losnavegadores/icons/contacto.png';


// accounts section
const pay = '/assets/blue/Andipay.png';
const backimage = '/assets/blue/Andimar-BackgroundMobile.png';

const tarjetas = '/assets/losnavegadores/icons/card.png';;
const viajes = '/assets/losnavegadores/icons/mytrips.png';
const movimientos = '/assets/losnavegadores/icons/rechargewallet.png';
const wallet = '/assets/losnavegadores/icons/rechargewallet.png';
const shareMoney = '/assets/losnavegadores/icons/transfer-money.png';
const camera =  '/assets/losnavegadores/icons/user-circular.png';
const checkmark = '/assets/orange/IllustratedIcons-CheckMark-Blue.png';
const tick = '/assets/orange/IllustratedIcons-Tick-Blue.png';
const downArrow = '/assets/orange/IllustratedIcons-DropDown-Blue.png';
const loginblue = '/assets/losnavegadores/icons/user-circular.png';
const recoverpswd = '/assets/losnavegadores/icons/login.png';
const success = '/assets/losnavegadores/icons/success.png';;
const failure = '/assets/losnavegadores/icons/failure.png';
const warning = '/assets/losnavegadores/icons/important.png';
const userIcon = '/assets/losnavegadores/icons/user-circular.png';
const heartOn = '../assets/icons/heart.png';
const heartOff = '../assets/icons/heart-empty.png';
const registrationSuccess = '/assets/losnavegadores/icons/registration.png';
const operatorPay = '/assets/losnavegadores/icons/pay.png'

const  losnavegadoresIcons = [
	{ icon: operatorPay, name: 'operatorPay' },
	{ icon: Banner, name: 'Banner' },
	{ icon: HeaderLogo, name: 'headerLogo' },
	{ icon: FooterLogo, name: 'footerLogo' },
	{ icon: OriginIcon, name: 'Origin' },
	{ icon: OriginIcon, name: 'originoIcon' },
	{ icon: DestinationIcon, name: 'Destination' },
	{ icon: DestinationIcon, name: 'destinoIcon' },
	{ icon: CalendarIcon, name: 'Calendar' },
	{ icon: BookingInActive, name: 'BIA' },
	{ icon: BookingActive, name: 'BA' },
	{ icon: CancelInActive, name: 'CIA' },
	{ icon: CancelActive, name: 'CA' },
	{ icon: BottomFooterKuposLogo, name: 'BottomFooterKuposLogo' },
	{ icon: PopupIcon, name: 'Popup' },
	{ icon: Whatsapp, name: 'Whatsapp' },
	{ icon: Email, name: 'Email' },
	{ icon: HeadPhone, name: 'Headphone' },
	{ icon: YellowLine, name: 'Line' },
	{ icon: BG, name: 'BG' },

	//   common assets
	{ icon: checkboxIcon, name: 'Checkbox' },
	{ icon: animation, name: 'animation' },
	{ icon: pnr, name: 'pnr' },
	{ icon: close, name: 'close' },


	// social media
	{ icon: fb, name: 'fb' },
	{ icon: tw, name: 'twitter' },
	{ icon: insta, name: 'insta' },
	{ icon: contactfb, name: 'contactFb' },
	{ icon: contactinsta, name: 'contactInsta' },
	{ icon: contactEmail, name: 'contactMail' },
	{ icon: contactphone, name: 'contactAddress' },

	// accounts section
	{ icon: pay, name: 'pay' },
	{ icon: backimage, name: 'backimage' },
	{ icon: tarjetas, name: 'tarjetas' },
	{ icon: viajes, name: 'viajes' },
	{ icon: movimientos, name: 'movimientos' },
	{ icon: wallet, name: 'wallet' },
	{ icon: shareMoney, name: 'shareMoney' },
	{ icon: camera, name: 'camera' },
	{ icon: checkmark, name: 'checkmark' },
	{ icon: tick, name: 'tick' },
	{ icon: downArrow, name: 'downArrow' },
	{ icon: loginblue, name: 'loginblue' },
	{ icon: recoverpswd, name: 'recoverpswd' },
	{ icon: success, name: 'success' },
	{ icon: failure, name: 'failure' },
	{ icon: heartOn, name: 'heartOn' },
	{ icon: heartOff, name: 'heartOff' },
	{ icon: registrationSuccess, name: 'registrationSuccess' },
	{ icon: userIcon, name: 'userIcon' },
	{ icon: warning, name: 'warning' },
	{ icon: Banner, name: 'contactUsBanner' },
	{ icon: Banner, name: 'tncBanner' },
	{ icon: HeadPhone, name: 'contactHeadphone' }
];

export default losnavegadoresIcons;
